import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { onFormResetAction } from "../../Store/Actions";
import {
  GET_CLOCKIN_AND_LOCATIONS,
  GET_TIME_SHEET_FILTER_DATA,
  GET_TIME_SHEET_LIST,
} from "../../Graphql/query";

import Pagination from "../../Components/Pagination";
import { AddButton, ExportButton } from "../../Components/Buttons";
import { TimeSheetUpsertModal } from "../../Components/FlexibleModals";
import { BSelect, DateTimePickerForm, CheckboxBooleanForm } from "form-builder";
import WorkDayTemplatesContainer from "./WorkDayTemplatesContainer";
import moment from "moment";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { serializeFetchedClockin } from "../../Helpers/HelperFns";
import EditClockinsContainerModal from "../../Components/FlexibleModals/EditClockinsContainerModal";

const reducer = "flex";
const formName = "timeSheetUpsertModal";
const filtersFormName = "timeSheetListFilters";
const FiltersFormProps = { reducer, formName: filtersFormName };

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const getTotalSignInsFromWorkDayTemplatesContainer = (
  workDayTemplatesContainer
) => {
  let totalSignIns = 0;

  workDayTemplatesContainer?.details?.forEach((workDayTemplate) => {
    totalSignIns += workDayTemplate?.clockins?.length;
  });

  return totalSignIns;
};

const TimeSheetList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState(paginationInitState);
  const [clockinsContainerId, setClockinsContainerId] = useState(null);
  const [clockInId, setClockInId] = useState(null);
  const [containerStatus, setContainerStatus] = useState(null);

  const isEditContainerModalOpen = useSelector(
    (state) => state?.flex?.editClockinsContainerModal?.isOpen
  );

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);

  const { data: timeSheetFilterData, loading: filterOptionsLoading } = useQuery(
    GET_TIME_SHEET_FILTER_DATA
  );

  const [
    fetchTimeSheet,
    { data: timeSheetQueryData, refetch: refetchTimeSheet },
  ] = useLazyQuery(GET_TIME_SHEET_LIST, {
    onCompleted: (data) => {
      setPagination(data?.timeSheet?.paginatorInfo ?? {});
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [fetchClockinAndLocations, { data: clockInAndLocationsQueryData }] =
    useLazyQuery(GET_CLOCKIN_AND_LOCATIONS);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    return () => {
      dispatch(onFormResetAction(filtersFormName));
      setClockinsContainerId(null);
    };
  }, []);

  useEffect(() => {
    fetchTimeSheet({
      variables: {
        page: 1,
      },
    });
  }, []);

  const skipFirstRender = useDidUpdateEffect(() => {
    fetchTimeSheet({
      variables: {
        page: 1,
        input: {
          emp_id: filters?.employeeId,
          office_id: filters?.officeId,
          department_id: filters?.departmentId,
          date_range: {
            from: filters?.from,
            to: filters?.to,
          },
          has_no_out_time: !!filters?.onlyShowNoSignOutTime,
        },
      },
    });
  }, [filters]);

  /* ↓ Helpers ↓ */

  const handleExport = () => {};

  const handleOpenClockinModal = (
    containerId = null,
    clockInId = null,
    containerStatus = null
  ) => {
    fetchClockinAndLocations({
      variables: {
        clockInId: clockInId,
        includeClockin: !!clockInId,
      },
      onCompleted: (data) => {
        setClockinsContainerId(containerId);
        setContainerStatus(containerStatus);
        setClockInId(clockInId);
        setIsModalOpen(true);
        if (!!data?.clockin) {
          dispatch(
            onFormResetAction(formName, serializeFetchedClockin(data?.clockin))
          );
        }
      },
    });
  };

  const handleCloseModal = () => {
    setClockinsContainerId(null);
    setClockInId(null);
    setIsModalOpen(false);
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    fetchTimeSheet({
      variables: {
        page: page,
      },
    });
  };

  return (
    <>
      {/* Filters */}
      <div className="mt-2 d-flex justify-content-between gap-10">
        <BSelect
          {...FiltersFormProps}
          name="officeId"
          icon="office"
          placeholder="select office"
          rootStyle="flex-1"
          options={timeSheetFilterData?.offices?.data ?? []}
          isLoading={filterOptionsLoading}
          isClearable
        />
        <BSelect
          {...FiltersFormProps}
          name="departmentId"
          icon="department"
          placeholder="select department"
          rootStyle="flex-1"
          options={timeSheetFilterData?.departments?.data ?? []}
          isLoading={filterOptionsLoading}
          isClearable
        />
        <BSelect
          {...FiltersFormProps}
          name="employeeId"
          icon="employee"
          placeholder="select employee"
          rootStyle="flex-1"
          options={
            timeSheetFilterData?.employees?.map?.((emp) => emp?.user) ?? []
          }
          isLoading={filterOptionsLoading}
          isClearable
          isMulti
        />
      </div>
      <div className="d-flex gap-10">
        <DateTimePickerForm
          {...FiltersFormProps}
          name="from"
          label="from"
          datePickerContainer="w-100"
          rootStyle="flex-1"
          isClearable
        />
        <DateTimePickerForm
          {...FiltersFormProps}
          name="to"
          label="to"
          datePickerContainer="w-100"
          rootStyle="flex-1"
          isClearable
        />
      </div>
      <div className="d-flex align-items-center justify-content-between gap-10">
        <CheckboxBooleanForm
          {...FiltersFormProps}
          name="onlyShowNoSignOutTime"
          containerStyle="my-3"
          options={["Only show records with no sign-out time"]}
        />
        <div className="d-flex align-items-center justify-content-between gap-10">
          <ExportButton
            onClick={handleExport}
            disabled={!filters?.from || !filters?.to}
            disabledLabel="Please select a date range to export"
          >
            {t("export")}
          </ExportButton>
        </div>
      </div>

      {timeSheetQueryData?.timeSheet?.data?.map((workDayTemplatesContainer) => (
        <div className="mb-3 border rounded">
          <WorkDayTemplatesContainer
            key={Math.floor(Math.random())}
            workDayTemplatesContainer={workDayTemplatesContainer}
            handleOpenClockinModal={handleOpenClockinModal}
            refetch={refetchTimeSheet}
          />
        </div>
      ))}

      <Pagination
        styleWraper=""
        onPaginate={handlePaginate}
        customPaginator={pagination}
      />

      {/* ))} */}

      {/* Table */}
      {/* <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table"
        // progressPending={loading}
        progressComponent={<Loader inner />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      /> */}

      {isModalOpen ? (
        <TimeSheetUpsertModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          clockinsContainerId={clockinsContainerId}
          containerStatus={containerStatus}
          clockInId={clockInId}
          workPlaces={clockInAndLocationsQueryData?.locations_menu?.data ?? []}
          workdayTemplates={
            clockInAndLocationsQueryData?.FlexWorkDayTemplate?.data ?? []
          }
          refetch={refetchTimeSheet}
        />
      ) : null}

      {isEditContainerModalOpen ? (
        <EditClockinsContainerModal refetch={refetchTimeSheet} />
      ) : null}
    </>
  );
};

export default TimeSheetList;

import Constants from "../../Constants";
import { ADDITIONAL_SHIFT, CHANGE_SHIFT, DAY_OFF } from "../../Constants/Requests";
import HelperFns from "../HelperFns";

// DayOffExceptionConfigInput
export const serializeUpsertDayOffException = (data) => {
  let input = {
    allow_work_on_day_off: data?.allow_work_on_day_off,
  };

  if (data?.allow_work_on_day_off) {
    const apply_compensation = data?.treat_as_normal
      ? 0
      : data?.apply_compensation;

    input = {
      ...input,
      day_off_exception_config: {
        id: data?.day_off_exception_id || null,
        normal_work_timing_id: data?.normal_work_timing_id,
        first_half_work_timing_id: data?.first_half_work_timing_id,
        second_half_work_timing_id: data?.second_half_work_timing_id,
        apply_compensation,
        weight: data?.treat_as_normal ? parseFloat(data?.weight ?? 0) : 0,
        treat_as_normal: data?.treat_as_normal,
        allow_permission: data?.treat_as_normal
          ? false
          : data?.allow_permission,
        allow_permission: data?.treat_as_normal
          ? false
          : data?.allow_permission,
        allow_overtime: data?.treat_as_normal ? false : data?.allow_overtime,
        ...(apply_compensation && {
          compensation_type: data?.compensation_type,
          payment_factor_id:
            data?.compensation_type === "bonus"
              ? data?.payment_factor_id
              : null,
          compensation_quantity: parseFloat(data?.compensation_quantity),
        }),
        workplace_setting: data?.workplace_setting,
        workplace_setting_identical: data?.workplace_setting_identical,
        additional_work_places: data?.additional_work_places,
        workplace_setting_signout: data?.workplace_setting_identical
          ? data?.workplace_setting
          : data?.workplace_setting_signout,
        additional_work_places_signout: data?.workplace_setting_identical
          ? data?.additional_work_places
          : data?.additional_work_places_signout,
        cost_center_id: data?.dayoff_cost_center_id,
      },
    };
  }

  return input;
};
export const serializeFetchedDayOffException = ({
  id,
  offices,
  isFlex,
  ...data
}) => {
  isFlex = isFlex && false;
  return {
    ...data,
    offices_ids: offices,
    day_off_exception_id: id,

    ...(isFlex
      ? {
        normal_work_timing_id: data?.normalWorkTiming?.id,
      }
      : {
        allow_half_days: +Boolean(
          data?.firstHalfWorkTiming?.id || data?.secondHalfWorkTiming?.id
        ),
        normal_work_timing_id: data?.normalWorkTiming?.id,
        first_half_work_timing_id: data?.firstHalfWorkTiming?.id,
        second_half_work_timing_id: data?.secondHalfWorkTiming?.id,
        dayoff_cost_center_id: data?.cost_center?.id,
      }),
  };
};

// HolidayExceptionConfig
export const serializeUpsertHolidayException = (data) => {
  let input = {
    same_exception_config:
      data?.allow_work_on_day_off && data?.allow_work_on_holiday
        ? !!data?.same_config
        : false,

    allow_work_on_holiday: !!data?.allow_work_on_holiday,
  };

  if (
    data?.allow_work_on_holiday &&
    ((data?.allow_work_on_day_off && !data?.same_config) ||
      !data?.allow_work_on_day_off)
  ) {
    const apply_compensation = data?.holiday_treat_as_normal
      ? 0
      : data?.holiday_apply_compensation;
    input = {
      ...input,
      holiday_exception_config: {
        id: data?.holiday_exception_id || null,
        normal_work_timing_id: data?.holiday_normal_work_timing_id,
        ...(data?.holiday_allow_half_days && {
          first_half_work_timing_id: data?.holiday_first_half_work_timing_id,
          second_half_work_timing_id: data?.holiday_second_half_work_timing_id,
        }),
        apply_compensation,
        weight: data?.holiday_treat_as_normal
          ? parseFloat(data?.holiday_weight ?? 0)
          : 0,
        ...(apply_compensation && {
          compensation_type: data?.holiday_compensation_type,
          payment_factor_id:
            data?.holiday_compensation_type === "bonus"
              ? data?.holiday_payment_factor_id
              : null,
          compensation_quantity: parseFloat(
            data?.holiday_compensation_quantity
          ),
        }),
        treat_as_normal: Boolean(data?.holiday_treat_as_normal),
        allow_permission: data?.holiday_treat_as_normal
          ? false
          : Boolean(data?.holiday_allow_permission),
        allow_overtime: data?.holiday_treat_as_normal
          ? false
          : Boolean(data?.holiday_allow_overtime),

        workplace_setting: data?.holiday_workplace_setting,

        additional_work_places:
          data?.holiday_workplace_setting === "DEFAULT"
            ? data?.holiday_additional_work_places
            : [],

        workplace_setting_identical: data?.holiday_workplace_setting_identical,

        workplace_setting_signout: data?.holiday_workplace_setting_identical
          ? data?.holiday_workplace_setting
          : data?.holiday_workplace_setting_signout,

        additional_work_places_signout:
          data?.holiday_workplace_setting_identical
            ? data?.holiday_workplace_setting === "DEFAULT"
              ? data?.holiday_additional_work_places
              : []
            : data?.holiday_workplace_setting_signout === "DEFAULT"
              ? data?.holiday_additional_work_places_signout
              : [],
        cost_center_id: data?.holiday_cost_center_id,
      },
    };
  }

  return input;
};
export const serializeFetchedHolidayException = ({ isFlex, ...data }) => {
  isFlex = isFlex && false;
  return {
    same_config: data?.same_exception_config ? 1 : 0,
    allow_work_on_holiday: data?.allow_work_on_holiday ? 1 : 0,

    holiday_exception_id: data?.id,
    holiday_weight: data?.weight,
    holiday_apply_compensation: +data?.apply_compensation,
    holiday_compensation_type: data?.compensation_type,
    holiday_payment_factor_id: data?.payment_factor_id,
    holiday_compensation_quantity: data?.compensation_quantity,
    holiday_treat_as_normal: +data?.treat_as_normal,
    holiday_allow_permission: +data?.allow_permission,
    holiday_allow_overtime: +data?.allow_overtime,
    holiday_allow_half_days:
      data?.firstHalfWorkTiming?.id || data?.secondHalfWorkTiming?.id ? 1 : 0,
    holiday_normal_work_timing_id: data?.normalWorkTiming?.id,
    holiday_first_half_work_timing_id: data?.firstHalfWorkTiming?.id,
    holiday_second_half_work_timing_id: data?.secondHalfWorkTiming?.id,
    holiday_workplace_setting: data?.workplace_setting,
    holiday_additional_work_places: data?.additional_work_places,
    holiday_workplace_setting_signout: data?.workplace_setting_signout,
    holiday_workplace_setting_identical: data?.workplace_setting_identical,
    holiday_additional_work_places_signout:
      data?.additional_work_places_signout,

    ...(isFlex
      ? {
        flex_holiday_work_day_template: data?.normal_work_timing_id,
      }
      : { holiday_cost_center_id: data?.cost_center?.id }),
  };
};

// workRemotelySettings
export const serializeUpsertWorkRemotely = (data) => {
  const { maxRemoteDaysPerWeek, canWorkRemote, attendanceType } = data || {};
  const isOfficeBased =
    attendanceType === Constants.attendanceTypes.OFFICE_BASED;

  return {
    canWorkRemote: !!canWorkRemote,
    ...(canWorkRemote
      ? {
        employee_can_request: data?.employee_can_request,
        maxRemoteDaysPerWeek: +maxRemoteDaysPerWeek,
        flexibleRemote: isOfficeBased ? data?.flexibleRemote : canWorkRemote,
        canExchangeDays:
          isOfficeBased && !data?.flexibleRemote
            ? data?.canExchangeDays
            : false,
        remoteDays:
          isOfficeBased && !data?.flexibleRemote ? data?.remoteDays : [],
        allow_work_remotely_cutoff: data?.allow_work_remotely_cutoff,
        work_remotely_cutoff: data?.work_remotely_cutoff
          ? HelperFns.hoursToTime(data?.work_remotely_cutoff)
          : null,
      }
      : {
        employee_can_request: false,
        maxRemoteDaysPerWeek: 0,
        flexibleRemote: false,
        canExchangeDays: false,
        remoteDays: [],
      }),
  };
};
export const serializeFetchWorkRemotely = (input) => {
  return {
    canWorkRemote: input?.can_work_home,
    employee_can_request: input?.employee_can_request,
    maxRemoteDaysPerWeek: input?.max_homeDays_per_week,
    flexibleRemote: input?.flexible_home,
    canExchangeDays: input?.can_ex_days,
    remoteDays: input?.home_days?.split(","),
    allow_work_remotely_cutoff: input?.allow_work_remotely_cutoff,
    work_remotely_cutoff: input?.work_remotely_cutoff
      ? HelperFns.timeToHours(input?.work_remotely_cutoff)
      : null,
  };
};

// UpdateOrCreateAttendanceTypeConfigurationInput
export const serializeUpsertAttType = ({ isFlex, ...data }) => {
  const {
    att_type_id,
    attendanceType,
    user_id,
    start,
    end,
    attendanceProfileId,
    workGroupId,
    workTeamId,
    joiningFrom,
    allowMultipleCheckIns,
    check_in_form_id,
    check_out_form_id,
    weekStartDay,
    save_with,
    skip_work_timing_changed,
    allow_work_on_day_off,

    // Work Remotely
    canWorkRemote,
    employee_can_request,
    maxRemoteDaysPerWeek,
    flexibleRemote,
    canExchangeDays,
    remoteDays,

    // Day off exception
    day_off_exception_id,
    normal_work_timing_id,
    allow_half_days,
    first_half_work_timing_id,
    second_half_work_timing_id,
    weight,
    apply_compensation,
    compensation_type,
    payment_factor_id,
    compensation_quantity,
    treat_as_normal,
    allow_permission,
    allow_overtime,
    offices_ids,
    additional_work_places,
    additional_work_places_signout,
    workplace_setting,
    workplace_setting_signout,
    workplace_setting_identical,

    // Holiday exception
    same_config,
    allow_work_on_holiday,
    holiday_exception_id,
    holiday_normal_work_timing_id,
    holiday_allow_half_days,
    holiday_first_half_work_timing_id,
    holiday_second_half_work_timing_id,
    holiday_apply_compensation,
    holiday_weight,
    holiday_treat_as_normal,
    holiday_compensation_type,
    holiday_payment_factor_id,
    holiday_compensation_quantity,
    holiday_allow_permission,
    holiday_allow_overtime,
    holiday_workplace_setting,
    holiday_additional_work_places,
    holiday_workplace_setting_identical,
    holiday_workplace_setting_signout,
    holiday_additional_work_places_signout,

    dayoff_cost_center_id,
    holiday_cost_center_id,

    has_request_dayoff_limit,
    dayoff_request_limits,
    dayoff_period_type,

    has_request_change_shift_limit,
    change_shift_request_limits,
    change_shift_period_type,

    has_request_additional_shift_limit,
    additional_shift_request_limits,
    additional_shift_period_type,
  } = data;

  const request_configuration_settings = [
    has_request_dayoff_limit && {
      request_type_id: +DAY_OFF,
      request_limits: Number(dayoff_request_limits),
      period_type: dayoff_period_type,
    },
    has_request_change_shift_limit && {
      request_type_id: +CHANGE_SHIFT,
      request_limits: Number(
        change_shift_request_limits
      ),
      period_type: change_shift_period_type,
    },
    has_request_additional_shift_limit && {
      request_type_id: +ADDITIONAL_SHIFT,
      request_limits: Number(additional_shift_request_limits),
      period_type: additional_shift_period_type,
    },
  ].filter(Boolean);


  isFlex = isFlex && false;
  return {
    id: att_type_id,
    type: isFlex ? undefined : attendanceType,
    user_id,
    start,
    end,
    save_with,

    // Work Remotely
    workRemotelySettings: serializeUpsertWorkRemotely({
      attendanceType,
      canWorkRemote,
      employee_can_request,
      maxRemoteDaysPerWeek,
      remoteDays,
      flexibleRemote,
      canExchangeDays,
    }),

    ...(isFlex
      ? {
        joiningFrom,
        flex_work_team_id: workTeamId,
        flex_work_group_id: workGroupId,
        allowMultipleCheckIns: !!allowMultipleCheckIns,
        checkInFormId: check_in_form_id,
        checkOutFormId: check_out_form_id,
        weekStartDay: parseInt(weekStartDay), // Day off exception

        // Day off exception inputs
        ...serializeUpsertDayOffException({
          allow_work_on_day_off,
          day_off_exception_id,
          normal_work_timing_id,
          allow_half_days,
          first_half_work_timing_id,
          second_half_work_timing_id,
          weight,
          apply_compensation,
          compensation_type,
          payment_factor_id,
          compensation_quantity,
          treat_as_normal,
          allow_permission,
          allow_overtime,
          offices_ids,
          additional_work_places,
          additional_work_places_signout,
          workplace_setting,
          workplace_setting_signout,
          workplace_setting_identical,
        }),

        // holiday exception inputs
        ...serializeUpsertHolidayException({
          allow_work_on_day_off,
          allow_work_on_holiday,
          same_config,
          holiday_weight,
          holiday_exception_id,
          holiday_normal_work_timing_id,
          holiday_allow_half_days,
          holiday_first_half_work_timing_id,
          holiday_second_half_work_timing_id,
          holiday_apply_compensation,
          holiday_compensation_type,
          holiday_payment_factor_id,
          holiday_compensation_quantity,
          holiday_treat_as_normal,
          holiday_allow_permission,
          holiday_allow_overtime,
          holiday_workplace_setting,
          holiday_additional_work_places,
          holiday_workplace_setting_identical,
          holiday_workplace_setting_signout,
          holiday_additional_work_places_signout,
        }),
        // request_configuration_settings
      }
      : {
        ...(attendanceType === Constants.attendanceTypes.OFFICE_BASED
          ? {
            attendanceProfileId,
            request_configuration_settings,
          }
          : {
            workGroupId,
            workTeamId,
            joiningFrom,
            allowMultipleCheckIns: !!allowMultipleCheckIns,
            checkInFormId: check_in_form_id,
            checkOutFormId: check_out_form_id,
            weekStartDay: parseInt(weekStartDay), // Day off exception

            // Day off exception inputs
            ...serializeUpsertDayOffException({
              allow_work_on_day_off,
              day_off_exception_id,
              normal_work_timing_id,
              allow_half_days,
              first_half_work_timing_id,
              second_half_work_timing_id,
              weight,
              apply_compensation,
              compensation_type,
              payment_factor_id,
              compensation_quantity,
              treat_as_normal,
              allow_permission,
              allow_overtime,
              offices_ids,
              additional_work_places,
              additional_work_places_signout,
              workplace_setting,
              workplace_setting_signout,
              workplace_setting_identical,
              dayoff_cost_center_id,
            }),

            // holiday exception inputs
            ...serializeUpsertHolidayException({
              allow_work_on_day_off,
              allow_work_on_holiday,
              same_config,
              holiday_weight,
              holiday_exception_id,
              holiday_normal_work_timing_id,
              holiday_allow_half_days,
              holiday_first_half_work_timing_id,
              holiday_second_half_work_timing_id,
              holiday_apply_compensation,
              holiday_compensation_type,
              holiday_payment_factor_id,
              holiday_compensation_quantity,
              holiday_treat_as_normal,
              holiday_allow_permission,
              holiday_allow_overtime,
              holiday_workplace_setting,
              holiday_additional_work_places,
              holiday_workplace_setting_identical,
              holiday_workplace_setting_signout,
              holiday_additional_work_places_signout,
              holiday_cost_center_id,
            }),
            request_configuration_settings,
          }),
      }),

  };

};

import gql from "graphql-tag";
import { monthlyProfileFragment, workDayTemplatesFragment } from "../fragments";

export const FLEX_WORK_GROUP_MODAL_QUERY = gql`
  query flexWorkGroupModalQuery($id: ID!, $isEditing: Boolean!) {
    flexWorkGroup(id: $id) @include(if: $isEditing) {
      id
      name
      managers {
        id
        name
      }
      employees {
        user {
          id
          name
        }
      }
      workDayTemplates {
        id
        name
      }
      offices {
        id
        name
      }
    }

    managers: users_by_role(
      first: 9999
      input: { status: ["Active"], page_flag: null }
    ) {
      data {
        id
        name
      }
    }

    profile {
      company {
        businessPartners {
          user {
            id
            name
          }
        }
      }
    }

    employees: shiftBasedNotAttachedToAnyWorkGroupYet {
      user {
        id
        name
      }
    }

    offices: company_offices(first: 999) {
      data {
        id
        name
      }
    }

    workDayTemplates: FlexWorkDayTemplate(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_FLEX_WORK_TEAMS = gql`
  query getFlexWorkTeams(
    $first: Int
    $page: Int
    $name: String
    $workGroupId: ID
  ) {
    flexWorkTeams(
      first: $first
      page: $page
      input: { name: $name, workGroupId: $workGroupId }
    ) {
      data {
        id
        name
        flexWorkGroup {
          id
          name
        }
        employees: flexRelatedEmployees {
          user {
            id
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    flexWorkGroupsOptions: flexWorkGroups(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

export const FLEX_WORK_TEAM_MODAL_INFO = gql`
  query flexWorkTeamModalInfo {
    flex_work_groups_menu {
      id
      name
      employees {
        relevantFlexWorkTeam {
          id
          name
        }
        user {
          id
          name
        }
        flexRelevantAttendanceTypeConfiguration {
          join_from
          flexWorkTeam {
            name
          }
        }
      }
    }
  }
`;

export const GET_WORK_TEAM = gql`
  query getWorkTeam($id: ID!) {
    flexWorkTeam(id: $id) {
      id
      name
      canEdit
      canDelete
      flexWorkGroup {
        id
        name
      }
    }
  }
`;

export const GET_FLEX_WORK_TEAM_EMPLOYEES = gql`
  query getFlexWorkTeamEmployees(
    $id: ID!
    $name: String
    $first: Int
    $page: Int
  ) {
    flexWorkTeamEmployees(id: $id, name: $name, first: $first, page: $page) {
      data {
        user {
          id
          name
          profile_picture {
            path
          }
          user_type
          position {
            name
          }
          department {
            name
          }
        }
        flexWorkTeamEmployee(flexWorkTeamId: $id) {
          start
          end
          flexWorkTeam {
            id
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_FLEX_ATTENDANCE_TYPE_OPTIONS = gql`
  query getFlexAttendanceTypeOptions {
    PermissionLeavesBreakSettingsForMenu(first: 999) {
      data {
        id
        name
        applicable_type
      }
    }

    flex_work_groups_menu {
      id
      name
      flexWorkTeams {
        id
        name
      }
    }

    locations_menu(first: 999) {
      data {
        id
        name
      }
    }

    FlexWorkDayTemplate(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_MONTHLY_PROFILE = gql`
  query getMonthlyProfile($id: ID!) {
    flexMonthlyProfile(id: $id) {
      ...monthlyProfile
      treat_extra_overtime_hours_as
    }
  }
  ${monthlyProfileFragment}
`;

export const GET_FLEX_WORK_GROUPS = gql`
  query getFlexWorkGroups($first: Int, $page: Int, $name: String) {
    flexWorkGroups(first: $first, page: $page, input: { name: $name }) {
      data {
        canEdit
        canDelete
        id
        name
        managers {
          id
          name
        }
        employees {
          user {
            id
            name
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_WORK_DAY_TEMPLATES = gql`
  query getWorkDayTemplates(
    $page: Int
    $input: flexWorkDayTemplateFilterInput
  ) {
    FlexWorkDayTemplate(first: 10, page: $page, input: $input) {
      __typename
      data {
        ...workDayTemplate
      }

      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
  ${workDayTemplatesFragment}
`;

export const GET_WORK_DAY_TEMPLATE = gql`
  query getWorkDayTemplate($id: ID!) {
    flexWorkDayTemplate(id: $id) {
      ...workDayTemplate
      work_hours_per_leave_day
      require_facial_recognition_with_clock_ins
      require_facial_recognition_on_work_remotley
      treat_extra_overtime_hours_as
    }
  }
  ${workDayTemplatesFragment}
`;

export const GET_TIME_SHEET_LIST = gql`
  query getTimeSheetList($page: Int, $input: TimeSheetFilterInput) {
    timeSheet(first: 20, page: $page, input: $input) {
      data {
        __typename
        name
        date
        details {
          __typename
          id
          user {
            id
            name
          }
          status
          flexWorkDayTemplate {
            id
            name
          }
          flexPenalties {
            id
          }
          calculatedExtraDataPerContainer {
            totalClockIns
            totalHours
            actualTotalHours
            totalOverTime
            actualTotalOverTime
            totalLeave
          }
          clockins {
            id
            clock_in_time
            clock_out_time
            working_time
            checkIns {
              id
            }
            clockInOffice {
              name
            }
            clockOutOffice {
              name
            }
            leave_hours
            leave_type
            hasPendingEditAttendanceRequests
          }
        }
      }

      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_TIME_SHEET_FILTER_DATA = gql`
  query getTimeSheetFilterData {
    offices: company_offices(first: 9999) {
      data {
        id
        name
      }
    }
    departments: company_departments(first: 9999) {
      data {
        id
        name
      }
    }
    employees: getFlexEmployees {
      user {
        id
        name
      }
    }
  }
`;

export const GET_CLOCKIN_AND_LOCATIONS = gql`
  query getClockinAndLocations($clockInId: ID, $includeClockin: Boolean!) {
    clockin(id: $clockInId) @include(if: $includeClockin) {
      id
      status
      clock_in_time
      clockInOffice {
        id
      }
      clock_out_time
      clockOutOffice {
        id
      }
      leave_hours
      leave_type
    }

    locations_menu(first: 999) {
      data {
        id
        name
      }
    }

    FlexWorkDayTemplate(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_FLEX_EMPLOYEE_APPLICABLE_WORKDAY_TEMPLATES = gql`
  query getFlexEmployeeApplicableWorkdayTemplates(
    $employee_id: ID
    $date: String!
    $for: String!
  ) {
    employee(id: $employee_id) {
      ... on FlexEmployee {
        __typename
        applicableWorkTimings(date: $date, for: $for) {
          default {
            __typename
            id
            FlexScheduleDetail {
              WorkDayTemplate {
                name
                work_hours_per_leave_day
              }
            }
          }
          exceptions {
            __typename
            id
            flexWorkDayTemplate {
              name
              work_hours_per_leave_day
            }
          }
        }
      }
    }
  }
`;

export const GET_FLEX_WORK_DAY_TEMPLATES_MENU = gql`
  query getFlexWorkdayTemplatesQuqery {
    FlexWorkDayTemplate(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_FLEX_RELEVANT_ASSIGNMENTS = gql`
  query getFlexRelevantAssignment($input: RelevantFlexAssignmentsInput!) {
    relevantFlexAssignments(input: $input) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_TIMESHEET_SUMMARY = gql`
  query getTimesheetSummary($page: Int, $input: TimeSheetSummaryInput) {
    timeSheetSummary(first: 30, page: $page, input: $input) {
      data {
        id: user_id
        manager_id
        copied_managers_ids
        employee_name
        total_clockins
        day_off_count
        actual_total_leave_hours
        actual_total_work_hours
        approved_by_data
        approved
        pending
        rejected
      }

      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_TIMESHEET_SUMMARY_FILTERS_DATA = gql`
  query getTimesheetSummaryFiltersData($from: String, $to: String) {
    company_offices(first: 9999) {
      data {
        id
        name
      }
    }
    company_departments(first: 9999) {
      data {
        id
        name
      }
    }
    users_by_role(first: 9999, input: { status: ["Active"] }) {
      data {
        id
        name
      }
    }
    company_positions(first: 999) {
      data {
        id
        name
      }
    }

    weeks(from: $from, to: $to) {
      name
      from
      paidAt
    }
  }
`;

export const GET_TIMESHEET_SUMMARY_FOR_EMPLOYEE = gql`
  query getTimesheetSummaryPerEmployee($input: TimeSheetSummaryInput) {
    timeSheetSummaryPerEmployee(input: $input) {
      __typename
      name
      date
      details {
        __typename
        id
        status
        accepted_status
        flexWorkDayTemplate {
          id
          name
        }
        flexPenalties {
          id
        }
        calculatedExtraDataPerContainer {
          totalClockIns
          totalHours
          actualTotalHours
          totalOverTime
          actualTotalOverTime
          totalLeave
        }
        clockins {
          id
          order
          clock_in_time
          clock_out_time
          working_time
          checkIns {
            id
          }
          clockInOffice {
            name
          }
          clockOutOffice {
            name
          }
          leave_type
          leave_hours
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_WORK_DAY_TYPE = gql`
  query getEmployeeWorkdayType($id: ID) {
    user(id: $id) {
      work_day_type
    }
  }
`;

export const GET_FLEX_MONTHLY_CONFIGS = gql`
  query getFlexMonthlyConfigs($employeeId: ID) {
    getFlexEmployeeProfileTimeRangeSettings(flex_employee_id: $employeeId) {
      id
      start
      profile {
        id
        name
      }
    }
  }
`;

export const requestFlexCommentsQuery = gql`
  query fetchFlexRequestCommentsModalData($requestId: ID) {
    get_request: flex_request(id: $requestId) {
      id
      requestType {
        id
        name
      }
      add_info {
        __typename
        ... on AddInfo {
          data
        }
        ... on FlexOpenRequestInfo {
          data
        }
        ... on FlexDeleteRequestableInfo {
          number
          date
        }
        ... on FlexChangeShiftInfo {
          workplace_setting
          additional_work_places
          additional_work_places_signout
          workplace_setting_signout
          workplace_setting_identical
          cost_center_id
        }
      }
      req_day
      time_from: date_from
      time_to: date_to
      employee {
        user {
          name
        }
      }
      comments {
        id
        request_id: flex_request_id
        user_id
        user {
          name
          profile_picture {
            path
          }
        }
        comment
        created_at
      }
    }
  }
`;

export const GET_FLEX_SCHEDULE_SHIFTS = gql`
  query fetchFlexScheduleShifts($id: ID!) {
    flex_schedule_shifts(id: $id) {
      id
      start
      end
      day_off
      schedule_detail_id
      work_timing_id
      location_id: space_id
      user
      team
      resource
      calendar_date
      title
      user_count
      first_two_users
      work_timing_name
    }
  }
`;

export const GET_ASSIGNMENT_USERS_ACCORDING_TO_DATE = gql`
  query getAssignmentUsersAccordingToDate($date: String) {
    assignmentUsersList(input: { date: $date }) {
      id
      name
      work_day_type
      emp_code
      department {
        id
      }
      office {
        id
        name
      }
    }
  }
`;

export const GET_EDIT_CONTAINER_INPUTS_DATA = gql`
  query getEditContainerInputsData {
    locations_menu(first: 999) {
      data {
        id
        name
      }
    }

    FlexWorkDayTemplate(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

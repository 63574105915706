import moment from "moment";
import { assignmentTypesConstants } from "../../Constants";
import HelperFns from ".";

export const serializeUpsertingFlexAssignment = ({
  id,
  name,
  from,
  to,
  apply_on,
  employee_applicable_on_ids,
  department_applicable_on_ids,
  office_applicable_on_ids,
  department_employee_excludes,
  exclude_department_employees,
  exclude_office_employees,
  office_employee_excludes,
  penalise,
  no_compensation,
  // year,
  // month,
  payable_at,
  retain_default_work_timing,
  award_bonus_days_quantity,
  award_additional_leaves_quantity,
  compensation,
  allow_permission,
  allow_overtime,
  allow_home,
  workplace_setting,
  additional_work_places,
  workplace_setting_signout,
  additional_work_places_signout,
  workplace_setting_identical,
  apply_work_timing_location,
  weight,
  assignmentType,
  allow_employees_to_request_half_days,
  inLieuOfWorkdayDate,
  inLieuOfWorkdayRequestableObject,
  flex_work_day_template_id,
  payment_factor_id,
}) => {
  let compensationConfig = [
    compensation === "bonus"
      ? {
          type: "bonus",
          payment_factor_id,
          quantity: parseFloat(award_bonus_days_quantity),
          month_year_payroll: moment(payable_at).format("DD-MM-YYYY"),
        }
      : null,
    compensation === "leaves"
      ? {
          type: "leaves",
          quantity: parseFloat(award_additional_leaves_quantity),
          month_year_payroll: null,
        }
      : compensation === "request"
        ? {
            type: "request",
            request: {
              date: moment(inLieuOfWorkdayDate).format("DD-MM-YYYY"),
              clockable: {
                clockableId: inLieuOfWorkdayRequestableObject?.id,
                clockableType: inLieuOfWorkdayRequestableObject?.typename,
              },
            },
          }
        : null,
  ];
  let submitData = {
    id,
    name,
    // weight:
    //   assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY
    //     ? parseFloat(weight)
    //     : 0,
    weight: 0,
    from: from?.replaceAll("/", "-"),
    to: to?.replaceAll("/", "-"),
    apply_on: apply_on,
    applicable_on_ids:
      apply_on?.toLowerCase() === "employee"
        ? employee_applicable_on_ids?.map((applicable_id) => +applicable_id)
        : apply_on?.toLowerCase() === "department"
          ? department_applicable_on_ids?.map((applicable_id) => +applicable_id)
          : office_applicable_on_ids?.map((applicable_id) => +applicable_id),

    assignment_flex_employee_excludes: exclude_department_employees
      ? department_employee_excludes?.map(
          (excluded_employee) => +excluded_employee
        )
      : exclude_office_employees
        ? office_employee_excludes?.map(
            (excluded_employee) => +excluded_employee
          )
        : [],

    ...(assignmentType != assignmentTypesConstants.DAY_OFF &&
    assignmentType != assignmentTypesConstants.HOLIDAY
      ? {
          flex_work_day_template_id: +flex_work_day_template_id,

          retain_work_day_template:
            assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY
              ? retain_default_work_timing
              : 1, // if the assingment is an exception it should always retain

          treat_as_normal:
            assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY,
          workplace_setting_identical:
            apply_work_timing_location || workplace_setting_identical,
          workplace_setting: apply_work_timing_location
            ? "work_timing_location"
            : workplace_setting,
          additional_work_places,
          workplace_setting_sign_out: apply_work_timing_location
            ? "work_timing_location"
            : workplace_setting_identical
              ? workplace_setting
              : workplace_setting_signout,
          additional_work_places_sign_out: !workplace_setting_identical
            ? additional_work_places_signout
            : additional_work_places,

          ...(assignmentType == assignmentTypesConstants.EXCEPTIONAL_SHIFT
            ? {
                allow_overtime,
                allow_home,
                flex_assignment_compensation_configurations:
                  assignmentType ==
                    assignmentTypesConstants.EXCEPTIONAL_SHIFT && !!compensation
                    ? compensationConfig?.filter((conf) => conf != null)
                    : [],
                flex_assignment_absence_action: {
                  penalise: penalise ? true : false,
                  compensate: no_compensation ? false : true,
                },
              }
            : {}),
        }
      : {
          retain_work_day_template: 0,
          treat_as_normal: true,
          ...(assignmentType == assignmentTypesConstants.HOLIDAY
            ? {
                is_holiday: true,
              }
            : {}),
        }),
  };

  return submitData;
};

export const serializeFetchingFlexAssignment = ({
  id,
  name,
  from,
  to,
  apply_on,
  flexWorkDayTemplate,
  retain_work_day_template,
  flexAssignmentApplicants,
  employeeExcludes,
  offices,
  flexAssignmentCompensationConfigurations,
  canEditFrom,
  canEditTo,
  canEdit,
  canEditRetain,
  createdBy,
  created_at,
  ignoredEmployees,
  treat_as_normal,
  allow_overtime,
  allow_home,
  workplace_setting,
  additional_work_places,
  workplace_setting_signout,
  additional_work_places_signout,
  workplace_setting_identical,
  weight,
  is_holiday = false,
  penalise,
  compensate,
  change_shift,
  __typename,
}) => {
  let award_bonus_index = flexAssignmentCompensationConfigurations?.find(
    (compensation) => compensation?.type?.toLowerCase() === "bonus"
  );
  let additional_leaves_index = flexAssignmentCompensationConfigurations?.find(
    (compensation) => compensation?.type?.toLowerCase() === "leaves"
  );
  let in_lieu_of_workday = flexAssignmentCompensationConfigurations?.find(
    (compensation) => compensation?.type?.toLowerCase() === "request"
  );

  let fetchedData = {
    id,
    name,
    from,
    to,
    assignment_work_day_type: "flexible",
    apply_on,
    treat_as_normal,
    payment_factor_id: award_bonus_index?.payment_factor_id,
    flex_work_day_template_id: flexWorkDayTemplate?.id,
    penalise,
    no_compensation: compensate ? 0 : 1,
    retain_default_work_timing: +retain_work_day_template,
    exclude_department_employees:
      apply_on.toLowerCase() == "department" && employeeExcludes.length ? 1 : 0,
    exclude_office_employees:
      apply_on.toLowerCase() == "office" && employeeExcludes.length ? 1 : 0,
    department_employee_excludes:
      apply_on.toLowerCase() == "department"
        ? employeeExcludes?.map((emp) => emp?.user?.id)
        : [],
    office_employee_excludes:
      apply_on.toLowerCase() == "office"
        ? employeeExcludes?.map((emp) => emp?.user?.id)
        : [],
    employee_applicable_on_ids:
      apply_on == "employee"
        ? flexAssignmentApplicants.map(
            (applicant) => applicant?.applicable?.user?.id
          )
        : [],
    department_applicable_on_ids:
      apply_on == "department"
        ? flexAssignmentApplicants.map((applicant) => applicant?.applicable?.id)
        : [],
    office_applicable_on_ids:
      apply_on == "office"
        ? flexAssignmentApplicants.map((applicant) => applicant?.applicable?.id)
        : [],
    compensate_for_extra_time_worked:
      flexAssignmentCompensationConfigurations?.length ? 1 : 0,
    award_bonus_days_quantity: award_bonus_index
      ? award_bonus_index.quantity
      : null,
    compensation: additional_leaves_index
      ? "leaves"
      : award_bonus_index
        ? "bonus"
        : in_lieu_of_workday
          ? "request"
          : "",
    inLieuOfWorkdayDate:
      flexAssignmentCompensationConfigurations?.[0]?.request?.time_from,
    inLieuOfWorkdayRequestable:
      flexAssignmentCompensationConfigurations?.[0]?.request?.requestables?.[0]
        ?.id,
    award_additional_leaves_quantity: additional_leaves_index
      ? additional_leaves_index.quantity
      : null,

    payable_at: award_bonus_index
      ? moment(award_bonus_index.month_year_payroll, "YYYY-MM-DD").format(
          "YYYY/MM/DD"
        )
      : "",
    // month: award_bonus_index
    //   ? moment(award_bonus_index.month_year_payroll, "DD-MM-YYYY").format("YYYY/MM/DD")
    //   : new Date().getMonth() + 1,
    // year: award_bonus_index
    //   ? moment(award_bonus_index.month_year_payroll, "MM-YYYY").format("YYYY")
    //   : new Date().getFullYear(),
    canEditFrom,
    canEditTo,
    canEdit,
    canEditRetain,
    assignmentType: is_holiday
      ? assignmentTypesConstants.HOLIDAY
      : !!flexWorkDayTemplate?.id
        ? treat_as_normal
          ? assignmentTypesConstants.NORMAL_WORK_DAY
          : assignmentTypesConstants.EXCEPTIONAL_SHIFT
        : assignmentTypesConstants.DAY_OFF,
    applied_on_names:
      apply_on.toLowerCase() == "employee"
        ? flexAssignmentApplicants.map(
            (applicant) => applicant?.applicable?.user?.name
          )
        : flexAssignmentApplicants.map(
            (applicant) => applicant?.applicable?.name
          ),
    excluded_employees_names: employeeExcludes?.map((emp) => emp.user?.name),
    assignment_location_names: offices?.map((offices) => offices?.name),
    createdBy,
    created_at,
    ignoredEmployees,
    allow_overtime,
    allow_home,
    apply_work_timing_location: +(workplace_setting === "work_timing_location"),
    workplace_setting_identical,
    workplace_setting,
    workplace_setting_signout,
    additional_work_places,
    additional_work_places_signout,
    weight,
    change_shift,
    __typename,
    flexWorkDayTemplate,
  };
  return fetchedData;
};

export const getSumOfDurations = (
  durations = [] // example: ["05:00:00", "08:00:00"]
) => {
  if (durations?.length === 0) {
    return "00:00:00";
  }

  let totalMilliseconds = 0;

  durations.forEach((time) => {
    totalMilliseconds += moment.duration(time).asMilliseconds();
  });

  const totalSeconds = Math.floor(totalMilliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

export const serializeFetchedClockin = (data) => {
  return {
    status: data?.status,
    signInTime: data?.clock_in_time
      ? moment(data?.clock_in_time).format("HH:mm")
      : null,
    signOutTime: data?.clock_out_time
      ? moment(data?.clock_out_time).format("HH:mm")
      : null,
    signInWorkPlace: data?.clockInOffice?.id,
    signOutWorkPlace: data?.clockOutOffice?.id,
    leaveType: data?.leave_type,
    leaveHours: moment(data?.leave_hours, "hh:mm:ss").format("h"),
  };
};

export const normalizeUpsertFlexMonthlyProfile = ({
  id,
  // name,
  setRestrictions,
  setMinimumHrs,
  minimumHrs,
  penaltyFactor,
  deductFrom,
  calculateOvertime,
  hoursPerMonth,
  calculateOvertimeAfterWorkHoursBy,
  setOvertimeMonthLimit,
  overtimeMonthLimit,
  setMaxHrsPerMonth,
  maxHrsPerMonth,
  applyRestrictionsPer,
  treatExtraHoursAs,
}) => {
  return {
    id,
    // name,
    restrict_work_hours: !!setRestrictions,
    ...(setRestrictions
      ? {
          apply_restrict_per: applyRestrictionsPer,
          set_minimum_work_hours: !!setMinimumHrs,
          ...(setMinimumHrs
            ? {
                minimum_work_hours: HelperFns.hoursToTime(minimumHrs),
                penalty_factor: parseFloat(penaltyFactor),
                deduct_from: deductFrom,
              }
            : {}),
          allow_overtime: !!calculateOvertime,
          ...(calculateOvertime
            ? {
                total_hours: HelperFns.hoursToTime(hoursPerMonth),
                calculate_overtime_after: HelperFns.minutesToTime(
                  calculateOvertimeAfterWorkHoursBy
                ),
                allow_overtime_monthly_limit: !!setOvertimeMonthLimit,
                ...(setOvertimeMonthLimit
                  ? {
                      overtime_monthly_limit:
                        HelperFns.hoursToTime(overtimeMonthLimit),
                    }
                  : {}),
                allow_maximum_work_hours: !!setMaxHrsPerMonth,
                ...(setMaxHrsPerMonth
                  ? {
                      maximum_work_hours: HelperFns.hoursToTime(maxHrsPerMonth),
                    }
                  : {}),
                ...(calculateOvertime &&
                setOvertimeMonthLimit &&
                setMaxHrsPerMonth
                  ? {
                      treat_extra_overtime_hours_as: treatExtraHoursAs,
                    }
                  : {}),
              }
            : {}),
        }
      : {}),
  };
};

export const normalizeFetchedMonthlyProfile = ({
  id,
  restrict_work_hours,
  set_minimum_work_hours,
  minimum_work_hours,
  penalty_factor,
  deduct_from,
  allow_overtime,
  total_hours,
  calculate_overtime_after,
  allow_overtime_monthly_limit,
  overtime_monthly_limit,
  allow_maximum_work_hours,
  maximum_work_hours,
  apply_restrict_per,
  treat_extra_overtime_hours_as,
}) => {
  return {
    id,
    setRestrictions: restrict_work_hours ? 1 : 0,
    applyRestrictionsPer: apply_restrict_per,
    setMinimumHrs: set_minimum_work_hours ? 1 : 0,
    minimumHrs: HelperFns.timeToHours(minimum_work_hours),
    penaltyFactor: penalty_factor,
    deductFrom: deduct_from,
    calculateOvertime: allow_overtime ? 1 : 0,
    hoursPerMonth: HelperFns.timeToHours(total_hours),
    calculateOvertimeAfterWorkHoursBy: HelperFns.timeToMinutes(
      calculate_overtime_after
    ),
    setOvertimeMonthLimit: allow_overtime_monthly_limit ? 1 : 0,
    overtimeMonthLimit: HelperFns.timeToHours(overtime_monthly_limit),
    setMaxHrsPerMonth: allow_maximum_work_hours ? 1 : 0,
    maxHrsPerMonth: HelperFns.timeToHours(maximum_work_hours),
    treatExtraHoursAs: treat_extra_overtime_hours_as,
  };
};

export const normalizedFetchedContainerForEditing = (container) => {
  const status = !!container?.status
    ? container?.status?.toLowerCase() == "day off"
      ? "dayOff"
      : "holiday"
    : "default";

  return {
    containerId: container?.id,
    workdayTemplateId: container?.flexWorkDayTemplate?.id,
    status,
    originalStatus: status,
  };
};

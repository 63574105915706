import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import clsx from "clsx";
import Constants from "../../Constants";
import {
  onFormResetAction,
  onInputResetAction,
  showErrorToast,
} from "../../Store/Actions";

import MainModal from "../MainModal";
import {
  RadioboxForm,
  BSelect,
  DateTimePickerForm,
  InputForm,
} from "form-builder";
import { UPDATE_CLOCKIN } from "../../Graphql/mutation";
import { NORMAL_LEAVES, SICK_LEAVES } from "../../Constants/Requests";
import moment from "moment";
import WorkOnHolidays from "../SharedFormSections/WorkOnHolidays";
import {
  Compensation,
  Requests,
  WorkOnDaysOff,
  WorkPlaces,
} from "../SharedFormSections/DayOffException";
import {
  serializeUpsertDayOffException,
  serializeUpsertHolidayException,
} from "../../Helpers/HelperFns";

const reducer = "flex";
const formName = "timeSheetUpsertModal";
const formNameValidation = "flexibleClientValidation";
const formServerValidation = "flexibleServerValidation";

const serializeupsert = (data) => {
  return {
    id: data?.id ?? null,
    status: data?.status,
    clockin_container_id: data?.clockinsContainerId,

    ...(data?.status === "attended"
      ? {
          attendedInput: {
            clock_in_time: !!data?.signInTime ? data?.signInTime : null,
            clock_out_time: !!data?.signOutTime ? data?.signOutTime : null,
            clock_in_office_id: !!data?.signInWorkPlace
              ? +data?.signInWorkPlace
              : null,
            clock_out_office_id: !!data?.signOutWorkPlace
              ? +data?.signOutWorkPlace
              : null,
          },

          ...(data?.originalStatus == "dayOff"
            ? {
                assignment_details_input: {
                  ...serializeUpsertDayOffException({
                    ...data,
                    allow_work_on_day_off: true,
                  })?.day_off_exception_config,
                  cost_center_id: null,
                },
              }
            : {}),

          ...(data?.originalStatus == "holiday"
            ? {
                assignment_details_input: {
                  ...serializeUpsertHolidayException({
                    ...data,
                    allow_work_on_day_off: true,
                  })?.holiday_exception_config,
                  cost_center_id: null,
                },
              }
            : {}),
        }
      : {}),
    ...(data?.status === "leave"
      ? {
          leaveInput: {
            leave_type: +data?.leaveType,
            leave_hours: data?.leaveHours,
          },
        }
      : {}),
  };
};

const TimeSheetUpsertModal = ({
  isOpen = false,
  onClose,
  clockinsContainerId = null,
  clockInId = null,
  workPlaces = [],
  workdayTemplates = [],
  refetch = () => {},
  containerStatus = null,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEdit = Boolean(clockInId);

  const originalStatus = !!containerStatus
    ? containerStatus?.toLowerCase() == "day off"
      ? "dayOff"
      : "holiday"
    : "default";

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);

  const FormProps = {
    reducer,
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  // Server State
  const [attemptUpdatOrCreateeClockin, { loading: updateClockinLoading }] =
    useMutation(UPDATE_CLOCKIN);

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    attemptUpdatOrCreateeClockin({
      variables: {
        input: serializeupsert({
          ...formData,
          id: !!clockInId ? clockInId : null,
          clockinsContainerId,
          originalStatus,
        }),
      },
      onCompleted: (data) => {
        if (data?.updateOrCreateClockIn?.status == "fail") {
          dispatch(
            showErrorToast(
              data?.updateOrCreateClockIn?.message ?? "Something went wrong"
            )
          );
          return;
        }

        handleCloseModal();
        refetch();
      },
      onError: (error) => {
        dispatch(
          showErrorToast(
            error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
          )
        );
      },
    });
  };

  return (
    <MainModal
      isOpen={isOpen}
      modalTitle={isEdit ? "Edit Attendance Entry" : "New Attendance Entry"}
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={updateClockinLoading}
      disableSubmitButton={updateClockinLoading}
    >
      {/* <div className="d-flex align-items-center gap-10">
        <BSelect
          {...FormProps}
          name="employee"
          icon="employee"
          label="Employee"
          placeholder="select employee"
          rootStyle="flex-1"
          options={options?.employees}
          // isLoading={!options?.employees?.length}
        />
        <DateTimePickerForm
          {...FormProps}
          name="date"
          label="Date"
          rootStyle="flex-1"
          containerStyle="container-style-default pb-0"
          datePickerContainer="w-100"
        />
      </div> */}

      <RadioboxForm
        {...FormProps}
        name="status"
        label="Status"
        labelStyle="my-2"
        options={[
          { label: "attended", value: "attended" },
          { label: "leave", value: "leave" },
        ]}
        optionItemStyle=" "
        optionInputStyle=" "
        optionsContainerStyle="d-flex gap-20"
      />

      {formData?.status == "attended" ? (
        <div>
          <div className="d-flex align-items-center gap-10 mb-3">
            <div className="flex-1">
              <strong>{t("clock in")}</strong>
              <DateTimePickerForm
                {...FormProps}
                name="signInTime"
                rootStyle="w-100 my-2 mb-3"
                containerStyle="container-style-default pb-0"
                datePickerContainer="w-100"
                timeOnly
              />

              <BSelect
                {...FormProps}
                name="signInWorkPlace"
                placeholder="select work place"
                rootStyle="w-100"
                options={workPlaces}
              />
            </div>
            <div className="flex-1">
              <strong>{t("clock out")}</strong>
              <DateTimePickerForm
                {...FormProps}
                name="signOutTime"
                rootStyle="w-100 my-2 mb-3"
                containerStyle="container-style-default pb-0"
                datePickerContainer="w-100"
                timeOnly
              />

              <BSelect
                {...FormProps}
                name="signOutWorkPlace"
                placeholder="select work place"
                rootStyle="w-100"
                options={workPlaces}
              />
            </div>
          </div>
          {originalStatus == "dayOff" ? (
            <>
              <WorkOnDaysOff
                formProps={FormProps}
                isFlex
                flexWorkDayTemplateOptions={workdayTemplates}
                isEditContainer
              />

              {formData?.allow_work_on_day_off ? (
                <>
                  <div>
                    {/* Work Places */}
                    <WorkPlaces
                      formProps={FormProps}
                      isFlex
                      locationsOptions={workPlaces}
                    />

                    {!formData?.treat_as_normal ? (
                      <>
                        {/* Compensation */}
                        <Compensation
                          formProps={FormProps}
                          compensation_type={formData?.compensation_type}
                          apply_compensation={formData?.apply_compensation}
                          isFlex
                        />

                        {/* Requests */}
                        <Requests formProps={FormProps} />
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ) : originalStatus == "holiday" ? (
            <WorkOnHolidays
              formProps={FormProps}
              isFlex
              flexWorkDayTemplateOptions={workdayTemplates}
            />
          ) : null}
        </div>
      ) : (
        <div className="d-flex align-items-start gap-10">
          <BSelect
            label="leave type"
            name="leaveType"
            placeholder="select leave type"
            rootStyle="w-100"
            containerStyle="w-100"
            options={[
              {
                id: NORMAL_LEAVES,
                name: "annual leave",
              },
              {
                id: SICK_LEAVES,
                name: "sick leave",
              },
            ]}
            validateBy="textRequired"
            {...FormProps}
          />

          <InputForm
            placeholder={t("leave hours")}
            type="number"
            name="leaveHours"
            label="leave hours"
            labelStyle="mb-2"
            rootStyle="w-100"
            containerStyle="w-100"
            validateBy="textRequired"
            {...FormProps}
          />
        </div>
      )}
    </MainModal>
  );
};

export default TimeSheetUpsertModal;

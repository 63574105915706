import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { syncFoodicsShiftsMutation } from "../../Graphql/mutation";
import { useTranslation } from "react-i18next";

import { showToast } from "../../Helpers/HelperFns";
import { paginatorFragment } from "../../Graphql/fragments";

import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { ColorButton } from "../../Components/Buttons";
import { Sync } from "@mui/icons-material";

const GET_SHIFTS = gql`
  query getShifts($first: Int, $page: Int) {
    shifts: foodicsShifts(first: $first, page: $page) {
      data {
        id
        business_date
        foodicsUser {
          name
        }
        foodicsBranch {
          name
        }
        clocked_in_at
        clocked_out_at
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${paginatorFragment}
`;
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const ShiftsList = () => {
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);

  // Server State
  const { loading, refetch } = useQuery(GET_SHIFTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination.perPage,
      page: pagination.currentPage,
    },
    onCompleted: ({ shifts }) => {
      setData(shifts?.data || []);
      setPagination(shifts?.paginatorInfo);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });
  
  // Constants
  const columns = [
    { 
      name: t("user"), 
      selector: ({ foodicsUser = {} }) => foodicsUser?.name
    },
    { 
      name: t("branch"),
      selector: ({ foodicsBranch = {} }) => foodicsBranch?.name
    },
    { name: t("date"), selector: "business_date" },
    { name: t("clocked in at"), selector: "clocked_in_at" },
    { name: t("clocked out at"), selector: "clocked_out_at" },
  ];

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  // Start of handle sync shifts mutation function
  const [syncFoodicsShifts, { loading: syncShiftsIsLoading }] = useMutation(
    syncFoodicsShiftsMutation,
    {
      onCompleted: (res) => {
        if (res?.syncFoodicsShifts?.length > 0) {
          // refetch data list
          refetch();

          // success toaster
          showToast("success");
        } else {
          // error toaster
          showToast(
            "error",
            res?.graphQLErrors[0]?.extensions?.reason ||
              res?.graphQLErrors[0]?.message ||
              res?.message
          );
        }
      },
      onError: (err) => {
        console.log({ err });
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  const handleSyncShifts = () => {
    syncFoodicsShifts();
  };
  // End of handle sync shifts mutation function

  return (
    <>
      {syncShiftsIsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      {/* sync btn */}
      <div className="d-flex justify-content-end my-4">
        <ColorButton
          color="#2764ac"
          onClick={handleSyncShifts}
          label={t("sync shifts")}
          icon={<Sync fontSize="small" />}
          disabled={loading || syncShiftsIsLoading}
        />
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table mt-4"
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />
    </>
  );
};

export default ShiftsList;

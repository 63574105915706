import React, { useState } from "react";
import MainModal from "../../Components/MainModal";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { requestChangeShiftsModalQuery } from "../../Graphql/query";
import { RadioboxForm, BSelect } from "form-builder";
import * as Yup from "yup";
import { showToast } from "../../Helpers/HelperFns";
import {
  acceptChangeShiftRequestMutation,
  ACCEPT_FLEX_CHANGE_SHIFT_REQUEST,
} from "../../Graphql/mutation";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import swal from "sweetalert";
import moment from "moment";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";

const validateInitState = {};

const RequestChangeShiftModal = (props) => {
  const { t } = useTranslation();

  const requestType = props?.req?.__typename; // fixed or flex
  const { allowCostCenter } = useAllowCostCenter();

  const initState = {
    workTimeId: props?.req?.workTimingId?.id,
    req_half_day: null,
    first_half_work_timing_id:
      props?.req?.add_info?.first_half_work_timing_id || null,
    second_half_work_timing_id:
      props?.req?.add_info?.second_half_work_timing_id || null,
    ApplyWorkTimingWorkPlaceSettings: true,
    workplace_setting_identical:
      props?.req?.add_info?.workplace_setting_identical || null,
    workplace_setting:
      props?.req?.add_info?.workplace_setting || "work_timing_location",
    workplace_setting_signout:
      props?.req?.add_info?.workplace_setting_signout || "work_timing_location",
    additional_work_places:
      props?.req?.add_info?.additional_work_places || null,
    additional_work_places_signout:
      props?.req?.add_info?.additional_work_places_signout || null,
    cost_center_id: props?.req?.add_info?.cost_center_id || null,
    workdayTemplateId: props?.req?.workDayTemplate?.id,
  };

  const [input, setInput] = useState(initState);
  const [otherValidator, setOtherValidator] = useState(false);

  console.log("inputs data", input);
  //  fetching form data
  const { data, loading, error } = useQuery(requestChangeShiftsModalQuery, {
    fetchPolicy: "network-only",
    variables: {
      workTimmingDate: moment(props?.req?.time_from).format("YYYY-MM-DD"),
    },
  });

  // submit mutation
  const [acceptChangeShiftRequest, { loading: submitLoading }] = useMutation(
    acceptChangeShiftRequestMutation,
    {
      onError: (err) => {
        if (err?.message === "swal") {
          swal({
            title: t("are you sure"),
            text: t("You have other requests on this day"),
            icon: "warning",
            className: "swal-warning-style",
            dangerMode: true,
            buttons: [t("Cancel"), t("confirm")],
          }).then((confirm) => {
            if (confirm) {
              acceptChangeShiftRequest({
                variables: {
                  input: {
                    id: props?.req?.id,
                    workTimeId: input?.workTimeId,
                    first_half_work_timing_id: input.first_half_work_timing_id
                      ? parseInt(input.first_half_work_timing_id)
                      : null,
                    second_half_work_timing_id: input.second_half_work_timing_id
                      ? parseInt(input.second_half_work_timing_id)
                      : null,
                    additional_work_places: input.additional_work_places
                      ? input.additional_work_places
                      : [],
                    additional_work_places_signout:
                      input.additional_work_places_signout
                        ? input.additional_work_places_signout
                        : [],
                    workplace_setting: input.workplace_setting,
                    workplace_setting_signout: input.workplace_setting_signout,
                    workplace_setting_identical:
                      input.workplace_setting_identical ? 1 : 0,
                    swalValue: true,
                  },
                },
              });
            } else {
              handleRejectRequests();
            }
          });
          return;
        }
        if (err?.graphQLErrors?.[0]?.extensions?.validation) {
          let validation = err?.graphQLErrors?.[0]?.extensions?.validation;
          Object.keys(validation).forEach((key) => {
            setValidator((prev) => ({
              ...prev,
              [key.slice(6)]: {
                isValid: false,
                msg: validation[key].toString(),
              },
            }));
          });
        } else {
          console.log({ err });
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason || err.message
          );
        }
      },
      onCompleted: (res) => {
        if (res?.accept_change_shift_request?.message) {
          setOtherValidator(res?.accept_change_shift_request?.message);
          return;
        }

        if (res?.accept_change_shift_request?.id) {
          props?.refetchQueries && props?.refetchQueries();
          setInput(initState);
          setValidator({});
          setOtherValidator(false);
          showToast(res?.accept_change_shift_request?.status, t("success"));
          props?.close();
          return;
        }

        showToast(
          res?.accept_change_shift_request?.status,
          res?.accept_change_shift_request.message
        );
      },
    }
  );

  const acceptFlexChangeShiftVariables = {
    id: props?.req?.id,
    WorkDayTemplateId: input?.workdayTemplateId,
    additional_work_places: input.additional_work_places
      ? input.additional_work_places
      : [],
    additional_work_places_signout: input.additional_work_places_signout
      ? input.additional_work_places_signout
      : [],
    workplace_setting: input.workplace_setting,
    workplace_setting_signout: input.workplace_setting_signout,
    workplace_setting_identical: input.workplace_setting_identical ? 1 : 0,
  };

  const [acceptFlexChangeShiftRequest, { loading: flexSubmitLoading }] =
    useMutation(ACCEPT_FLEX_CHANGE_SHIFT_REQUEST, {
      onError: (err) => {
        if (err?.message === "swal") {
          swal({
            title: t("are you sure"),
            text: t("You have other requests on this day"),
            icon: "warning",
            className: "swal-warning-style",
            dangerMode: true,
            buttons: [t("Cancel"), t("confirm")],
          }).then((confirm) => {
            if (confirm) {
              acceptFlexChangeShiftRequest({
                variables: {
                  input: {
                    ...acceptFlexChangeShiftVariables,
                    swalValue: true,
                  },
                },
              });
            } else {
              handleRejectRequests();
            }
          });
          return;
        }
        if (err?.graphQLErrors?.[0]?.extensions?.validation) {
          let validation = err?.graphQLErrors?.[0]?.extensions?.validation;
          Object.keys(validation).forEach((key) => {
            setValidator((prev) => ({
              ...prev,
              [key.slice(6)]: {
                isValid: false,
                msg: validation[key].toString(),
              },
            }));
          });
        } else {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason || err.message
          );
        }
      },
      onCompleted: (res) => {
        if (res?.accept_flex_change_shift_request?.message) {
          setOtherValidator(res?.accept_flex_change_shift_request?.message);
          return;
        }

        if (res?.accept_flex_change_shift_request?.id) {
          props?.refetchQueries && props?.refetchQueries();
          setInput(initState);
          setValidator({});
          setOtherValidator(false);
          showToast(
            res?.accept_flex_change_shift_request?.status,
            t("success")
          );
          props?.close();
          return;
        }

        showToast(
          res?.accept_flex_change_shift_request?.status,
          res?.accept_flex_change_shift_request.message
        );
      },
    });

  const handleSelect = (v, e) => {
    if (
      e.name === "additional_work_places" ||
      e.name === "additional_work_places_signout"
    ) {
      setInput((prev) => ({
        ...prev,
        [e.name]: v?.map((el) => el.id),
      }));
      if (input.workplace_setting_identical) {
        setInput((prev) => ({
          ...prev,
          additional_work_places_signout: v?.map((el) => el.id),
        }));
      }
      return;
    }
    setInput((prev) => ({ ...prev, [e?.name]: v?.id }));
  };

  const selectVal = (val, options, multi) => {
    if (multi) {
      return options.filter((opt) => val?.includes(opt?.id)) || [];
    }
    return options.find((opt) => opt?.id === val) || null;
  };

  const handleCheckBox = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleReqHalfDay = (e) => {
    setInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
      first_half_work_timing_id: initState.first_half_work_timing_id,
      second_half_work_timing_id: initState.second_half_work_timing_id,
    }));
  };

  const handleApplyWorkTimingWorkPlaceSetting = (e) => {
    setInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
      workplace_setting: !e.target.checked
        ? "DEFAULT"
        : initState.workplace_setting,
      workplace_setting_signout: !e.target.checked
        ? "DEFAULT"
        : initState.workplace_setting_signout,
      workplace_setting_identical: initState.workplace_setting_identical,
      additional_work_places: initState.additional_work_places,
      additional_work_places_signout: initState.additional_work_places_signout,
    }));
  };

  const applyWorkPlaceSettingIdentical = (e) => {
    setInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
      workplace_setting_signout: prev.workplace_setting,
      additional_work_places_signout: prev.additional_work_places,
    }));
  };

  const handleRadioChange = (e, val) => {
    let tmpInput = { ...input };
    if (input.workplace_setting_identical) {
      tmpInput.workplace_setting = val;
      tmpInput.workplace_setting_signout = val;
      tmpInput.additional_work_places = null;
      tmpInput.additional_work_places_signout = null;
      setInput(tmpInput);
      return;
    }

    tmpInput[e.target.name] = val;
    if (e.target.name === "workplace_setting")
      tmpInput.additional_work_places = null;
    else tmpInput.additional_work_places_signout = null;
    setInput(tmpInput);
  };

  const handleSave = () => {
    validate().then((v) => {
      if (v) {
        setValidator({});
        setOtherValidator(false);
        if (requestType == "Request") {
          acceptChangeShiftRequest({
            variables: {
              input: {
                id: props?.req?.id,
                workTimeId: input.workTimeId,
                first_half_work_timing_id: input.first_half_work_timing_id
                  ? parseInt(input.first_half_work_timing_id)
                  : null,
                second_half_work_timing_id: input.second_half_work_timing_id
                  ? parseInt(input.second_half_work_timing_id)
                  : null,
                additional_work_places: input.additional_work_places
                  ? input.additional_work_places
                  : [],
                additional_work_places_signout:
                  input.additional_work_places_signout
                    ? input.additional_work_places_signout
                    : [],
                workplace_setting: input.workplace_setting,
                workplace_setting_signout: input.workplace_setting_signout,
                workplace_setting_identical: input.workplace_setting_identical
                  ? 1
                  : 0,
                cost_center_id: input?.cost_center_id,
              },
            },
          });
        } else {
          acceptFlexChangeShiftRequest({
            variables: {
              input: acceptFlexChangeShiftVariables,
            },
          });
        }
      }
    });
  };

  const [validator, setValidator] = useState(validateInitState);
  const msg = "This Field Is Required.";
  const validationSchema = Yup.object().shape({
    workTimeId: Yup.number().when([], {
      is: () => requestType == "Request",
      then: Yup.number().required(msg),
      otherwise: Yup.number(),
    }),

    workdayTemplateId: Yup.number().when([], {
      is: () => requestType == "FlexRequest",
      then: Yup.number().required(msg),
      otherwise: Yup.number(),
    }),

    apply_compensation: Yup.boolean(),

    workplace_setting_signout: Yup.string().nullable(),

    additional_work_places: Yup.array().when("workplace_setting", {
      is: "DEFAULT",
      then: Yup.array().of(Yup.string()).required(msg),
      otherwise: Yup.array().nullable(),
    }),

    additional_work_places_signout: Yup.array()
      .nullable()
      .when("workplace_setting_signout", {
        is: "DEFAULT",
        then: Yup.array().of(Yup.string()).required(msg),
        otherwise: Yup.array(),
      }),

    first_half_work_timing_id: Yup.string().when("req_half_day", {
      is: true,
      then: Yup.string().required(msg),
      otherwise: Yup.string().nullable(),
    }),

    second_half_work_timing_id: Yup.string().when("req_half_day", {
      is: true,
      then: Yup.string().required(msg),
      otherwise: Yup.string().nullable(),
    }),
  });

  const validate = async () => {
    try {
      await validationSchema.validate(input, { abortEarly: false });
      return true; // Validation passed
    } catch (validationError) {
      const yupErrors = {};
      console.error(validationError);

      validationError.inner.forEach((error) => {
        yupErrors[error.path] = {
          isValid: false,
          msg: msg,
        };
      });

      setValidator(yupErrors);
      return false; // Validation failed
    }
  };

  const handleRejectRequests = () => {
    props?.handleRejectRequest(props?.req);
  };

  useDidUpdateEffect(() => {
    if (!props?.rejectLoading?.includes(props.req.id)) {
      props?.close();
    }
  }, [props?.rejectLoading]);

  return (
    <MainModal
      modalTitle={t(`${props?.req?.requestType?.name} Request`)}
      isOpen={props?.isOpen}
      toggle={props?.close}
      btnOnClick={handleSave}
      btnLabel={t("accept")}
      btnLabelCancel={props?.notification ? undefined : t("reject")}
      btnOnCancelClick={props?.notification ? undefined : handleRejectRequests}
      btnSubmitLoading={submitLoading}
      btnCancelLoading={props?.rejectLoading?.includes(props?.req?.id)}
    >
      <div className="d-flex flex-column flex-lg-row ">
        <div className="col-6">
          <strong>{t("employee")}</strong>
          <p>{props?.req?.employee?.user?.name}</p>
        </div>

        <div className="col-6">
          <strong>{t("date")}</strong>
          <p>{moment(props?.req?.time_from).format("DD/MM/YYYY")}</p>
        </div>
      </div>

      <strong className="d-block mb-2 blue-color">{t("work schedule")}</strong>

      {requestType == "Request" ? (
        <>
          <div
            className={
              "col-lg-12 " +
              (validator["workTimeId"] ? "invalid-container-style" : "")
            }
          >
            <strong className={"select-def-label mb-1 validity-label-style"}>
              {t("work timing")}
            </strong>
            <Select
              className={"select-def-input-containe b-select-style flex-grow-1"}
              classNamePrefix={"b-select-style"}
              value={selectVal(input.workTimeId, data?.work_timings_menu || [])}
              onChange={(v, e) => handleSelect(v, e)}
              isClearable={true}
              isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
              isSearchable
              placeholder={t("select option")}
              options={data?.work_timings_menu || []}
              getOptionLabel={(opt) => opt?.name}
              getOptionValue={(opt) => opt?.id}
              isLoading={loading}
              name={"workTimeId"}
            />
            <div className="validity-msg-style text-left">
              {t(validator["workTimeId"]?.msg)}
            </div>
          </div>

          <label className={"checkbox mt-3"}>
            <input
              type="checkbox"
              name={"req_half_day"}
              onChange={(e) => handleReqHalfDay(e)}
              checked={input.req_half_day}
              {...props}
            />
            <span></span>

            <div
              className={`booleanInputLabelStyle ${
                input.req_half_day ? "lightActiveColor" : ""
              }`}
            >
              {t("allow employees to request half-days")}
            </div>
          </label>

          {input.req_half_day && (
            <div className="d-flex flex-column flex-lg-row my-2 ">
              <div
                className={
                  "col-lg-6 " +
                  (validator["first_half_work_timing_id"]
                    ? "invalid-container-style"
                    : "")
                }
              >
                <label className={"select-def-label mb-1 validity-label-style"}>
                  {t("first half work timing")}
                </label>
                <Select
                  className={
                    "select-def-input-containe b-select-style flex-grow-1"
                  }
                  classNamePrefix={"b-select-style"}
                  value={selectVal(
                    input.first_half_work_timing_id,
                    data?.half_work_timings || []
                  )}
                  onChange={handleSelect}
                  name={"first_half_work_timing_id"}
                  isClearable={true}
                  isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                  isSearchable
                  placeholder={t("select work timing")}
                  options={data?.half_work_timings || []}
                  getOptionLabel={(opt) => opt?.name}
                  getOptionValue={(opt) => opt?.id}
                  isLoading={loading}
                />
                <div className="validity-msg-style text-left">
                  {t(validator["first_half_work_timing_id"]?.msg)}
                </div>
              </div>
              <div
                className={
                  "col-lg-6 " +
                  (validator["second_half_work_timing_id"]
                    ? "invalid-container-style"
                    : "")
                }
              >
                <label className={"select-def-label mb-1 validity-label-style"}>
                  {t("second half work timing")}
                </label>
                <Select
                  className={
                    "select-def-input-containe b-select-style flex-grow-1"
                  }
                  classNamePrefix={"b-select-style"}
                  value={selectVal(
                    input.second_half_work_timing_id,
                    data?.half_work_timings || []
                  )}
                  onChange={handleSelect}
                  name={"second_half_work_timing_id"}
                  isClearable={true}
                  isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                  isSearchable
                  placeholder={t("select work timing")}
                  options={data?.half_work_timings || []}
                  getOptionLabel={(opt) => opt?.name}
                  getOptionValue={(opt) => opt?.id}
                  isLoading={loading}
                />
                <div className="validity-msg-style text-left">
                  {t(validator["second_half_work_timing_id"]?.msg)}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className={
            "col-lg-12 " +
            (validator["workdayTemplateId"] ? "invalid-container-style" : "")
          }
        >
          <strong className={"select-def-label mb-1 validity-label-style"}>
            {t("workday template")}
          </strong>
          <Select
            className={"select-def-input-containe b-select-style flex-grow-1"}
            classNamePrefix={"b-select-style"}
            value={selectVal(
              input.workdayTemplateId,
              data?.FlexWorkDayTemplate?.data || []
            )}
            onChange={(v, e) => handleSelect(v, e)}
            isClearable={true}
            isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
            isSearchable
            placeholder={t("select option")}
            options={data?.FlexWorkDayTemplate?.data ?? []}
            getOptionLabel={(opt) => opt?.name}
            getOptionValue={(opt) => opt?.id}
            isLoading={loading}
            name={"workdayTemplateId"}
          />
          <div className="validity-msg-style text-left">
            {t(validator["workdayTemplateId"]?.msg)}
          </div>
        </div>
      )}

      <div className={"col-lg-12 mt-2"}>
        <strong className={"select-def-label validity-label-style"}>
          {t("work places")}
        </strong>

        <div className="mt-1">
          <label className={"checkbox "}>
            <input
              type="checkbox"
              name={"ApplyWorkTimingWorkPlaceSettings"}
              onChange={(e) => handleApplyWorkTimingWorkPlaceSetting(e)}
              checked={input.ApplyWorkTimingWorkPlaceSettings}
              {...props}
            />
            <span></span>

            <div
              className={`booleanInputLabelStyle ${
                input.ApplyWorkTimingWorkPlaceSettings ? "lightActiveColor" : ""
              }`}
            >
              {t("apply work timing work place settings")}
            </div>
          </label>
        </div>

        {/* ___________ work places settings start __________ */}

        {!input.ApplyWorkTimingWorkPlaceSettings && (
          <>
            <RadioboxForm
              labelStyle="label-style font-weight-bold"
              label="Employee can sign in From"
              name={"workplace_setting"}
              value={input.workplace_setting}
              interceptChange={handleRadioChange}
              validateContainerStyle={""}
              optionInputStyle="text-black-100"
              containerStyle="mb-0 mt-3"
              optionLabelStyle="optionLabelStyle"
              optionsContainerStyle="d-flex flex-column  gap-10"
              options={[
                {
                  label: t("Anywhere"),
                  value: "ANY_PLACE",
                },
                {
                  label: t("Any work place in the company"),
                  value: "ALL_COMPANY_WORKPLACES",
                },
                {
                  label: t("Specific work places"),
                  value: "DEFAULT",
                },
              ]}
            />

            {input?.workplace_setting === "DEFAULT" && (
              <div
                className={
                  "mt-3 " +
                  (validator["additional_work_places"]
                    ? "invalid-container-style"
                    : "")
                }
              >
                <label className={"select-def-label validity-label-style"}>
                  {t("Additional Workplaces")}
                </label>
                <Select
                  className={
                    "select-def-input-containe b-select-style flex-grow-1"
                  }
                  classNamePrefix={"b-select-style"}
                  value={selectVal(
                    input.additional_work_places,
                    data?.locations_menu.data || [],
                    true
                  )}
                  onChange={handleSelect}
                  name={"additional_work_places"}
                  isClearable={true}
                  isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                  isSearchable
                  placeholder={t("select option")}
                  options={data?.locations_menu?.data || []}
                  getOptionLabel={(opt) => opt?.name}
                  getOptionValue={(opt) => opt?.id}
                  isLoading={loading}
                  isMulti={true}
                />
                <div className="validity-msg-style text-left">
                  {t(validator["additional_work_places"]?.msg)}
                </div>
              </div>
            )}

            <div className="mt-3">
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"workplace_setting_identical"}
                  value={input.workplace_setting_identical}
                  onChange={(e) => applyWorkPlaceSettingIdentical(e)}
                  checked={input.workplace_setting_identical}
                  {...props}
                />
                <span></span>

                <div
                  className={`booleanInputLabelStyle ${
                    input.workplace_setting_identical ? "lightActiveColor" : ""
                  }`}
                >
                  {t("Apply the same settings when signing out")}
                </div>
              </label>
            </div>

            {!input?.workplace_setting_identical && (
              <>
                <RadioboxForm
                  label="Employee can sign out From"
                  labelStyle="label-style font-weight-bold"
                  value={input.workplace_setting_signout}
                  name={"workplace_setting_signout"}
                  interceptChange={handleRadioChange}
                  validateContainerStyle={""}
                  optionInputStyle="text-black-100"
                  containerStyle="mb-0 mt-3"
                  optionLabelStyle="optionLabelStyle"
                  optionsContainerStyle="d-flex flex-column  gap-10"
                  options={[
                    {
                      label: t("Anywhere"),
                      value: "ANY_PLACE",
                    },
                    {
                      label: t("Any work place in the company"),
                      value: "ALL_COMPANY_WORKPLACES",
                    },
                    {
                      label: t("Specific work places"),
                      value: "DEFAULT",
                    },
                  ]}
                />

                {input?.workplace_setting_signout === "DEFAULT" && (
                  <div
                    className={
                      "py-3 " +
                      (validator["additional_work_places_signout"]
                        ? "invalid-container-style"
                        : "")
                    }
                  >
                    <label className={"select-def-label validity-label-style"}>
                      {t("Additional Workplaces")}
                    </label>
                    <Select
                      className={
                        "select-def-input-containe b-select-style flex-grow-1"
                      }
                      classNamePrefix={"b-select-style"}
                      value={selectVal(
                        input.additional_work_places_signout,
                        data?.locations_menu?.data || [],
                        true
                      )}
                      onChange={handleSelect}
                      name={"additional_work_places_signout"}
                      isClearable={true}
                      isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                      isSearchable
                      isMulti={true}
                      placeholder={t("select option")}
                      options={data?.locations_menu.data}
                      getOptionLabel={(opt) => opt?.name}
                      getOptionValue={(opt) => opt?.id}
                      isLoading={loading}
                    />
                    <div className="validity-msg-style text-left">
                      {t(validator["additional_work_places_signout"]?.msg)}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {/* ___________ work places settings end __________ */}

      {allowCostCenter ? (
        <BSelect
          name="cost_center_id"
          validationName="input.cost_center_id"
          placeholder={t("select cost center")}
          options={data?.cost_centers?.data ?? []}
          label="Cost center"
          labelStyle="mt-3 mb-2"
          containerStyle="d-flex align-items-start flex-column"
          inputContainerStyle="w-100"
          icon="money"
          rootStyle="flex-1"
          value={data?.cost_centers?.data?.find(
            (costCenter) => costCenter?.id == input?.cost_center_id
          )}
          onChange={(v, e) => handleSelect(v, e)}
        />
      ) : null}

      <div
        className={"py-3 " + (otherValidator ? "invalid-container-style" : "")}
      >
        <div className="validity-msg-style text-left">{t(otherValidator)}</div>
      </div>
    </MainModal>
  );
};

export default RequestChangeShiftModal;

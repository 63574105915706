import React from "react";
import { useTranslation } from "react-i18next";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import moment from "moment";
import Constants from "../../Constants";
import Privilages from "../../Constants/Privilages";
import { onInputResetAction } from "../../Store/Actions";
import { checkCompanyPrivileges } from "../../Helpers/HelperFns";
import {
  GET_WORKING_TIMMING_OPTIONS_QUERY,
  attendanceTypeConfigOptionsQuery,
  attendanceTypeFlexConfigOptionsQuery,
  getAttendanceProfileQuery,
} from "../../Graphql/query";

import {
  Requests,
  WorkPlaces,
  Compensation,
  WorkOnDaysOff,
} from "./DayOffException";
import { BSelect, RadioboxForm, DateTimePickerForm } from "form-builder";
import MultipleCheckIn from "./MultipleCheckIn";
import CalendarToday from "@mui/icons-material/CalendarToday";
import WorkOnHolidays from "./WorkOnHolidays";
import _ from "lodash";

const shiftBased = Constants.attendanceTypes.SHIFT_BASED;
const officeBased = Constants.attendanceTypes.OFFICE_BASED;
const isCompanyHasShift = checkCompanyPrivileges({
  privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
});

/**
 *
 * Start of AttendanceTypeConfig
 *
 */

// Input name => UpdateOrCreateAttendanceTypeConfigurationInput
const AttendanceTypeConfig = ({
  FormProps,
  serverValidationPrefix = "",
  isFlex = false,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

   isFlex = isFlex && false;

  const start_date = useSelector(
    (state) => state.super.salaryConfigForm.start_at
  );

  // Local State
  const [options, setOptions] = React.useState({
    offices: [],
    workTeams: [],
    workGroups: [],
    cost_centers: [],
    workDayTemplates: [],
    workHourProfiles: [],
  });

  // Reducer State
  const inputData = useSelector(
    (state) => state?.[FormProps?.reducer || "super"]?.[FormProps?.formName]
  );

  // Server State
  const [getOptions, { loading }] = useLazyQuery(
    isFlex
      ? attendanceTypeFlexConfigOptionsQuery
      : attendanceTypeConfigOptionsQuery
  );

  const {
    data: getAttendanceProfileOptions,
    loading: getAttendanceProfileLoading,
  } = useQuery(getAttendanceProfileQuery, {
    variables: {
      archive: start_date,
    },
    skip: isFlex
      ? !Boolean(start_date)
      : !Boolean(start_date) || inputData?.attendanceType !== officeBased,
  });

  const { data: workTimingsOptions, loading: workTimingsLoading } = useQuery(
    GET_WORKING_TIMMING_OPTIONS_QUERY,
    {
      variables: {
        work_timming_date: moment(start_date, "YYYY/MM/DD").format(
          "YYYY-MM-DD"
        ),
      },
      skip: isFlex
        ? !Boolean(start_date)
        : !Boolean(start_date) || inputData?.attendanceType === officeBased,
    }
  );

  // Constants (isEmpForm)

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    getOptions({
      variables: { isOffice: inputData?.attendanceType === officeBased },
      onCompleted: ({
        offices,
        workTeams,
        workGroups,
        cost_centers = [],
        workHourProfiles,
        workDayTemplates,
      }) => {
        setOptions({
          offices: offices?.data || [],
          workGroups: workGroups || [],
          workTeams: workTeams?.data || [],
          cost_centers: cost_centers?.data || [],
          workHourProfiles: workHourProfiles?.data || [],
          workDayTemplates: workDayTemplates?.data || [],
        });
      },
    });
  }, [inputData?.attendanceType]);

  useDidUpdateEffect(() => {
    if (
      !inputData?.allow_work_on_day_off ||
      inputData?.attendanceType !== shiftBased
    ) {
      dispatch(onInputResetAction(FormProps.formName, "allow_overtime"));
      dispatch(onInputResetAction(FormProps.formName, "allow_permission"));
      dispatch(onInputResetAction(FormProps.formName, "apply_compensation"));
    }
  }, [inputData?.attendanceType, inputData?.allow_work_on_day_off]);

  /* ↓ Helpers ↓ */

  const handleInterceptAttendanceProfileChange = () => {
    dispatch(onInputResetAction(FormProps?.formName, "home_days"));
  };

  const onWorkGroupIdSelect = () => {
    dispatch(onInputResetAction(FormProps?.formName, "workTeamId"));
  };

  // start of handle work time options consist of worktime menu data and old selected work time data
  const serilazedNormalWorkTimingArray = [
    workTimingsOptions?.work_timings_menu ?? [],
    inputData?.normalWorkTiming ?? [],
  ].flat();

  const serilazedHalfWorkTimingArray = [
    workTimingsOptions?.half_work_timings_menu ?? [],
    [
      inputData?.firstHalfWorkTiming ?? {},
      inputData?.secondHalfWorkTiming ?? {},
    ] ?? [],
  ].flat();

  const normalWorkTimeOptions = _.uniqBy(serilazedNormalWorkTimingArray, "id");
  const halfWorkTimeOptions = _.uniqBy(serilazedHalfWorkTimingArray, "id");
  // End of handle work time options consist of worktime menu data and old selected work time data
  return isFlex ? (
    <FlexConfig
      options={options}
      loading={loading}
      FormProps={FormProps}
      inputData={inputData}
      startDate={props?.startDate}
      grantEmployeeSec={props?.grantEmployeeSec}
      serverValidationPrefix={serverValidationPrefix}
      flexWorkDayTemplateOptions={options.workDayTemplates}
    />
  ) : (
    <>
      <RadioboxForm
        {...FormProps}
        options={[
          {
            label: "Office Based Hours",
            value: "office based",
          },
          {
            label: "shift based work schedule",
            value: "shifts",
            optProps: {
              disabled: !isCompanyHasShift,
            },
          },
        ]}
        label="Employee Work Schedule"
        name="attendanceType"
        type="radio"
        containerStyle="my-3"
        labelStyle="label-style"
        optionsContainerStyle="optionsContainerStyle row"
        optionItemStyle="col-6 px-0"
        optionInputStyle=" "
        optionLabelStyle="mb-0 mr-3"
      />
      <BSelect
        {...FormProps}
        name="attendanceProfileId"
        label={t("attendance profile")}
        keepDefaultStyle
        placeholder={t("select option")}
        options={
          getAttendanceProfileOptions?.attendance_profiles?.data
            ? getAttendanceProfileOptions?.attendance_profiles?.data
            : []
        }
        validateBy="textRequired"
        validationName={`input.${serverValidationPrefix}att_profile_id`}
        skipLocalization
        icon="person"
        onInterceptInputOnChange={handleInterceptAttendanceProfileChange}
        isLoading={getAttendanceProfileLoading}
        dependOn={["attendanceType"]}
        dependancyType="equal"
        dependancyValue={[officeBased]}
      />

      <div className="row row-gap-5">
        <BSelect
          {...FormProps}
          name="workGroupId"
          label={t("work group")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={options?.workGroups}
          validationName={`input.${serverValidationPrefix}workGroupId`}
          skipLocalization
          icon="person"
          isClearable
          rootStyle="col-lg-6"
          isLoading={loading}
          dependOn={["attendanceType"]}
          dependancyType="equal"
          dependancyValue={[shiftBased]}
          onInterceptInputOnChange={onWorkGroupIdSelect}
        />
        <BSelect
          {...FormProps}
          name="workTeamId"
          label={t("work team")}
          keepDefaultStyle
          placeholder={t("select option")}
          isDisabled={!inputData?.workGroupId}
          options={options?.workTeams?.filter(
            (workteam) => workteam?.workGroup?.id === inputData?.workGroupId
          )}
          validationName={`input.${serverValidationPrefix}workTeamId`}
          skipLocalization
          icon="person"
          isClearable
          isLoading={loading}
          rootStyle="col-lg-6"
          dependOn={["attendanceType"]}
          dependancyType="equal"
          dependancyValue={[shiftBased]}
        />
        <DateTimePickerForm
          {...FormProps}
          label={t("join team from")}
          labelStyle="mb-2"
          name="joiningFrom" // workTeamStartDate
          containerStyle="flex-column"
          placeholder={t("select date")}
          validationName={`input.${serverValidationPrefix}joiningFrom`}
          dependOn={["attendanceType"]}
          dependancyType="equal"
          dependancyValue={[shiftBased]}
          rootStyle="col-lg-6"
        />
        <BSelect
          {...FormProps}
          label={t("first day of the week")}
          name="weekStartDay" // NOTE: first_day_of_the_week
          keepDefaultStyle
          placeholder={t("select option")}
          hideSelectedOptions
          optionLabel="label"
          optionValue="value"
          options={Constants.WeekDays}
          containerStyle="row justify-content-between align-items-center"
          labelStyle="col-12 mb-2"
          inputContainerStyle="col-12"
          validationName={`input.${serverValidationPrefix}weekStartDay`}
          validateBy={
            inputData?.attendanceType === shiftBased ? "textRequired" : false
          }
          icon={<CalendarToday />}
          isLoading={loading}
          dependOn={["attendanceType"]}
          dependancyType="equal"
          dependancyValue={[shiftBased]}
          rootStyle="col-lg-6"
        />
      </div>
      {inputData.attendanceType === shiftBased ? (
        <>
          <strong className="text-16 sec-color d-block mt-3 mb-2">
            {t("Check-ins")}
            <hr className="title-line" />
          </strong>
          <MultipleCheckIn
            formProps={FormProps}
            name="allowMultipleCheckIns"
            allow_customize_check_ins={inputData?.allow_customize_check_ins}
            customCheckFormIDs={{
              in: inputData?.check_in_form_id,
              out: inputData?.check_out_form_id,
            }}
          />
          {/* Work On Days Off */}
          <strong className="text-16 sec-color d-block mt-3 mb-2">
            {t("work on days off")}
            <hr className="title-line" />
          </strong>
          <WorkOnDaysOff
            hideName
            formProps={FormProps}
            serverValidationPrefix={serverValidationPrefix}
            halfWorkTimingOptions={halfWorkTimeOptions}
            normalWorkTimingOptions={normalWorkTimeOptions}
            costCentersOptions={options?.cost_centers ?? []}
            isFlex={isFlex}
          />

          <div className="ml-3">
            {inputData?.allow_work_on_day_off ? (
              <>
                {/* Work Places */}
                <WorkPlaces
                  formProps={FormProps}
                  locationsOptions={options?.offices}
                  serverValidationPrefix={serverValidationPrefix}
                  isFlex={isFlex}
                />

                {!inputData?.treat_as_normal ? (
                  <>
                    {/* Compensation */}
                    <Compensation
                      formProps={FormProps}
                      serverValidationPrefix={serverValidationPrefix}
                      compensation_type={inputData?.compensation_type}
                      apply_compensation={inputData?.apply_compensation}
                      isFlex={isFlex}
                    />

                    {/* Requests */}
                    <Requests formProps={FormProps} />
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          <WorkOnHolidays
            normalWorkTimingOptions={normalWorkTimeOptions}
            halfWorkTimingOptions={halfWorkTimeOptions}
            formProps={FormProps}
            locationsOptions={options?.offices}
            showWorkPlaceSection={true}
            serverValidationPrefix="input.attendanceTypeConfig"
            isActivationModal={true}
            costCentersOptions={options?.cost_centers ?? []}
            isFlex={isFlex}
          />
        </>
      ) : null}
    </>
  );
};

/**
 * Start of FlexConfig
 *
 */

const FlexConfig = ({
  options,
  loading,
  FormProps,
  inputData,
  startDate,
  grantEmployeeSec,
  serverValidationPrefix,
  flexWorkDayTemplateOptions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row row-gap-5">
        <BSelect
          {...FormProps}
          name="workGroupId"
          label={t("work group")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={options?.workGroups}
          validationName={`input.${serverValidationPrefix}workGroupId`}
          skipLocalization
          icon="person"
          isClearable
          rootStyle="col-lg-6"
          isLoading={loading}
        />
        <BSelect
          {...FormProps}
          name="workTeamId"
          label={t("work team")}
          keepDefaultStyle
          placeholder={t("select option")}
          isDisabled={!inputData?.workGroupId}
          options={options?.workTeams?.filter(
            (workteam) => workteam?.workGroup?.id === inputData?.workGroupId
          )}
          validationName={`input.${serverValidationPrefix}workTeamId`}
          skipLocalization
          icon="person"
          isClearable
          isLoading={loading}
          rootStyle="col-lg-6"
        />
        <DateTimePickerForm
          {...FormProps}
          label={t("join team from")}
          labelStyle="mb-2"
          name="joiningFrom" // workTeamStartDate
          containerStyle="flex-column"
          placeholder={t("select date")}
          validationName={`input.${serverValidationPrefix}joiningFrom`}
          rootStyle="col-lg-6"
        />

        <BSelect
          {...FormProps}
          label={t("first day of the week")}
          name="weekStartDay" // NOTE: first_day_of_the_week
          keepDefaultStyle
          placeholder={t("select option")}
          hideSelectedOptions
          optionLabel="label"
          optionValue="value"
          options={Constants.WeekDays}
          containerStyle="row justify-content-between align-items-center"
          labelStyle="col-12 mb-2"
          inputContainerStyle="col-12"
          validationName={`input.${serverValidationPrefix}weekStartDay`}
          validateBy="textRequired"
          icon={<CalendarToday />}
          isLoading={loading}
          rootStyle="col-lg-6"
        />

        {/* <BSelect
          {...FormProps}
          label={t("first day of the week")}
          name="weekStartDay" // NOTE: first_day_of_the_week
          keepDefaultStyle
          placeholder={t("select option")}
          hideSelectedOptions
          optionLabel="label"
          optionValue="value"
          options={Constants.WeekDays}
          containerStyle="row justify-content-between align-items-center"
          labelStyle="col-12 mb-2"
          inputContainerStyle="col-12"
          validationName={`input.${serverValidationPrefix}weekStartDay`}
          validateBy={
            inputData?.attendanceType === shiftBased ? "textRequired" : false
          }
          icon={<CalendarToday />}
          isLoading={loading}
          dependOn={["attendanceType"]}
          dependancyType="equal"
          dependancyValue={[shiftBased]}
          rootStyle="col-lg-6"
        />

        <BSelect
          {...FormProps}
          label="work day template"
          name="work_day_template"
          keepDefaultStyle
          placeholder={t("select option")}
          hideSelectedOptions
          optionLabel="name"
          optionValue="id"
          options={options.workDayTemplates}
          containerStyle="row justify-content-between align-items-center"
          labelStyle="col-12 mb-2"
          inputContainerStyle="col-12"
          validationName={`input.${serverValidationPrefix}flex_monthly_profile_id`}
          validateBy="textRequired"
          icon={<CalendarToday />}
          isLoading={loading}
          rootStyle="col-lg-6"
        /> */}
      </div>

      {/* Check-ins */}
      <strong class="text-16 sec-color d-block mt-3 mb-2">
        {t("Check-ins")}
        <hr className="title-line" />
      </strong>
      <MultipleCheckIn
        formProps={FormProps}
        name="allowMultipleCheckIns"
        allow_customize_check_ins={inputData?.allow_customize_check_ins}
        customCheckFormIDs={{
          in: inputData?.check_in_form_id,
          out: inputData?.check_out_form_id,
        }}
      />
      {/* Work On Days Off */}
      <strong className="text-16 sec-color d-block mt-3 mb-2">
        {t("work on days off")}
        <hr className="title-line" />
      </strong>
      <WorkOnDaysOff
        hideName
        isFlex
        formProps={FormProps}
        serverValidationPrefix={serverValidationPrefix}
        flexWorkDayTemplateOptions={flexWorkDayTemplateOptions}
        costCentersOptions={options?.cost_centers ?? []}
      />
      <div className="ml-3">
        {inputData?.allow_work_on_day_off ? (
          <>
            {/* Work Places */}
            <WorkPlaces
              isFlex
              formProps={FormProps}
              locationsOptions={options?.offices}
              serverValidationPrefix={serverValidationPrefix}
            />

            {!inputData?.treat_as_normal ? (
              <>
                {/* Compensation */}
                <Compensation
                  formProps={FormProps}
                  serverValidationPrefix={serverValidationPrefix}
                  compensation_type={inputData?.compensation_type}
                  isFlex={isFlex}
                  apply_compensation={inputData?.apply_compensation}
                />

                {/* Requests */}
                <Requests formProps={FormProps} />
              </>
            ) : null}
          </>
        ) : null}
      </div>
      <WorkOnHolidays
        isFlex
        flexWorkDayTemplateOptions={flexWorkDayTemplateOptions}
        formProps={FormProps}
        locationsOptions={options?.offices}
        showWorkPlaceSection={true}
        serverValidationPrefix="input.attendanceTypeConfig"
        isActivationModal={true}
        costCentersOptions={options?.cost_centers ?? []}
      />
    </>
  );
};

export default AttendanceTypeConfig;

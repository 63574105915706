import React from "react";
// import { connect } from "react-redux";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import { useSelector } from "react-redux";
import Constants from "../../Constants";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const FlexibleWorkScheduleTabs = (props) => {
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");
  const { classes } = props?.isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();
  const handleChange = (event, newValue) => {
    history.push(`/flexible-work-schedule?tab=${newValue}`);
  };

  const { t } = useTranslation();
  const isRtl =
    i18n.dir(useSelector((state) => state.user.userProfile.lng)) === "rtl"
      ? true
      : false;

  const possibleValues = [
    "timesheet",
    "timesheet-summary",
    "workday-template",
  ];

  return (
    <>
      <Tabs
        variant="scrollable"
        value={possibleValues.includes(activeValue) ? activeValue : false}
        onChange={handleChange}
        orientation={props?.isRouteSidebar ? "vertical" : "horizontal"}
        scrollButtons="auto"
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          scrollButtons: classes.scrollButtons,
        }}
      >

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_SCHEDULES],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="timesheet"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHandHoldingUsd"]}
                  className="mx-2 routeBar-icon"
                />
                {t("timesheet")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [
            Privilages.VIEW_EMPLOYEE_SCHEDULES],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="timesheet-summary"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHands"]}
                  className="mx-2 routeBar-icon"
                />
                {t("timesheet summary")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_WORK_TIMINGS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="workday-template"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faCheckDouble"]}
                  className="mx-2 routeBar-icon"
                />
                {t("workday template")}
              </div>
            }
          />
        )}
      </Tabs>
    </>
  );
};

export default FlexibleWorkScheduleTabs;

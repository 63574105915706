import { assignmentTypesConstants } from "../../Constants";
import HelperFns, { serializeFetchingFlexAssignment } from "../../Helpers/HelperFns";
import Types from "../Actions/types";
import moment from "moment";

export const initState = {
  upsertAssignmentModal: {
    isLoading: false,
    isVissible: false,
    modalMessage: "",
  },
  upsertAssignmentForm: {
    id: null,
    name: null,
    from: null,
    to: null,
    payment_factor_id: null,
    apply_on: "employee",
    assignment_work_day_type: "fixed",
    employee_applicable_on_ids: [],
    department_applicable_on_ids: [],
    office_applicable_on_ids: [],
    apply_work_timing_location: 1,
    exclude_department_employees: 0,
    exclude_office_employees: 0,
    department_employee_excludes: [],
    office_employee_excludes: [],
    normal_work_timing_id: null,
    first_half_work_timing_id: null,
    second_half_work_timing_id: null,
    compensate_for_extra_time_worked: false,
    compensation: "",
    award_bonus_days_quantity: null,
    payable_at: "",
    // year: new Date().getFullYear(),
    // month: new Date().getMonth() + 1,
    award_additional_leaves_quantity: null,
    penalise: false,
    no_compensation: false,
    allow_employees_to_request_half_days: 0,
    retain_default_work_timing: 0, // defualt to 0 means -> (replace)
    applied_on_names: [],
    excluded_employees_names: [],
    canEditFrom: null,
    canEditTo: null,
    canEdit: null,
    normalWorkTiming: null,
    firstHalfWorkTiming: null,
    secondHalfWorkTiming: null,
    assignment_location_names: [],
    createdBy: null,
    created_at: null,
    allow_permission: false,
    allow_overtime: false,
    allow_home: false,
    workplace_setting: "DEFAULT",
    workplace_setting_signout: "DEFAULT",
    additional_work_places: [],
    additional_work_places_signout: [],
    workplace_setting_identical: 1,
    weight: 1,
    assignmentType: assignmentTypesConstants.NORMAL_WORK_DAY,
    // treat_as_normal: true,
    // make_it_a_day_off: 0,
    cost_center_id: null,
  },
  upsertAssignmentFormValidation: [],
  upsertAssignmentFormServerValidation: {},
  assignmentFilters: {
    employees: [],
    locations: [],
    work_timings: [],
    work_day_templates: [],
    employee_search: null,
    from: moment().startOf("week").add(1, "days").format("DD-MM-YYYY"),
    type: "all",
  },
  assignmentFiltersData: {
    users_by_role: [],
    work_timings: [],
    locations: [],
  },
  assignmentFormInputsData: {
    users_by_role: [],
    departments: [],
    offices: [],
    work_timings: [],
    locations: [],
  },
  fetchAssignmentLoading: false,
  fetchAssignmentsLoading: false,
  assignments: [],
  deleteAssignmentLoading: false,
  assignmentPreview: {
    isOpen: false,
    isLoadig: false,
  },

  // Change Form
  changeAssignmentFormValidation: [],
  changeAssignmentFormServerValidation: {},
  changeAssignment: {
    date: "",
    weight: 1,
    normalWorkTiming: null,
    firstHalfWorkTiming: null,
    secondHalfWorkTiming: null,
    employee_applicable_on_ids: [],
    workplace_setting_identical: 1,
    workplace_setting: "DEFAULT",
    workplace_setting_signout: "DEFAULT",
    additional_work_places: [],
    additional_work_places_signout: [],
    retain_default_work_timing: 0, // defualt to 0 means -> (replace)
    apply_work_timing_location: 1,
    cost_center_id: null,
    isFlex: false,
  },
};

export const AssignemntsReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.TOGGLE_MODAL_DISPLAY:
      return {
        ...state,
        upsertAssignmentModal: {
          ...state.upsertAssignmentModal,
          isVissible: action.payload,
        },
      };

    case Types.FETCH_ASSIGNMENT_FORM_ATTEMPT:
      let { includeInputs = false } = action.payload;
      return {
        ...state,
        fetchAssignmentLoading: true,
        upsertAssignmentModal: {
          ...state.upsertAssignmentModal,
          isLoading: includeInputs ? true : false,
        },
        assignmentPreview: {
          ...state.assignmentPreview,
          isLoading: !includeInputs ? true : false,
        },
      };

    case Types.FETCH_ASSIGNMENT_FORM_SUCCESS:
      let { attemptPayload } = action;
      return {
        ...state,
        fetchAssignmentLoading: false,
        assignmentPreview: {
          ...state.assignmentPreview,
          isLoading: false,
          isOpen: !attemptPayload.includeInputs ? true : false,
        },
        assignmentFormInputsData: {
          ...state.assignmentFormInputsData,
          users_by_role: action.payload?.users_by_role ?? [],
          departments: action.payload?.departments ?? [],
          offices: action.payload?.offices ?? [],
          work_timings: action.payload?.work_timings ?? [],
          work_day_templates: action.payload?.FlexWorkDayTemplate?.data ?? [],
          locations: action.payload?.locations ?? [],
          cost_centers: action.payload?.cost_centers?.data ?? [],
        },
        upsertAssignmentModal: {
          ...state.upsertAssignmentModal,
          isLoading: false,
          isVissible: attemptPayload.includeInputs ? true : false,
        },

        upsertAssignmentForm: attemptPayload?.includeFlexAssignment
          ? action.payload?.flex_assignment
            ? serializeFetchingFlexAssignment(
                action.payload?.flex_assignment
              )
            : initState.upsertAssignmentForm
          : attemptPayload?.includeAssignment
            ? action.payload?.assignment
              ? HelperFns.serializeFetchingAssignment(
                  action.payload?.assignment
                )
              : initState.upsertAssignmentForm
            : initState.upsertAssignmentForm,
      };

    case Types.FETCH_ASSIGNMENT_FORM_FAILED:
      return {
        ...state,
        fetchAssignmentLoading: false,
        upsertAssignmentModal: {
          ...state.upsertAssignmentModal,
          isLoadig: false,
        },
      };

    case Types.ON_FORM_RESET:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: action?.payload?.value
            ? action?.payload?.value
            : initState[action.payload.formName],
        };
      }

    case Types.UPSERT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        upsertAssignmentModal: {
          ...state.upsertAssignmentModal,
          isVissible: false,
        },
      };

    case Types.UPSERT_ASSIGNMENT_FAILED:
      if (action.payload?.type == "generalexception") {
        return {
          ...state,

          upsertAssignmentFormServerValidation: {},
          upsertAssignmentModal: {
            ...state.upsertAssignmentModal,
            modalMessage: action.payload?.error,
          },
        };
      } else if (action.payload?.type == "errors") {
        return {
          ...state,
          [action.payload?.error?.serverRef]: action.payload?.error.errors,
        };
      } else {
        return {
          ...state,

          upsertAssignmentFormServerValidation: {},
        };
      }

    case Types.FETCH_ASSIGNMENTS_ATTEMPT:
      return {
        ...state,
        fetchAssignmentsLoading: true,
      };

    case Types.FETCH_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        fetchAssignmentsLoading: false,
        assignmentFiltersData: {
          ...state.assignmentFiltersData,
          users_by_role: action.payload?.inputs?.users_by_role ?? [],
          work_timings: action.payload?.inputs?.work_timings ?? [],
          work_day_templates: action.payload?.inputs?.work_day_templates ?? [],
          locations: action.payload?.inputs?.locations ?? [],
        },
        assignments: action.payload?.assignments.map((a) => ({
          ...a,
          assignments: a?.assignments?.filter((a) => a),
        })),
      };

    case Types.FETCH_ASSIGNMENTS_FAILED:
      return {
        ...state,
        fetchAssignmentsLoading: false,
      };

    case Types.CHANGE_ASSIGNMENT_DATE_ACTION:
      return {
        ...state,
        assignmentFilters: {
          ...state.assignmentFilters,
          from: action.payload,
        },
      };

    case Types.DELETE_ASSIGNMENT_ATTEMPT:
      return {
        ...state,
        deleteAssignmentLoading: true,
      };

    case Types.DELETE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        deleteAssignmentLoading: false,
        assignmentPreview: {
          ...state.assignmentPreview,
          isOpen: false,
        },
      };

    case Types.DELETE_ASSIGNMENT_FAILED:
      return {
        ...state,
        deleteAssignmentLoading: false,
      };

    case Types.TOGGLE_ASSIGNMENT_PREVIEW_MODAL:
      return {
        ...state,
        assignmentPreview: {
          ...state.assignmentPreview,
          isOpen: action.payload,
        },
      };

    case Types.FETCH_CHANGE_ASSIGNMENTS:
      return {
        ...state,
        changeAssignment: {
          ...state.changeAssignment,
          ...action.payload,
        },
      };

    case Types.RESET_ASSIGNMENT_MODAL_MESSAGE:
      return {
        ...state,
        upsertAssignmentModal: {
          ...state.upsertAssignmentModal,
          modalMessage: "",
        },
      };

    default:
      return state;
  }
};

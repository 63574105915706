import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useLazyQuery } from "@apollo/client";
import { fetchEmployeeLogsQuery } from "../../Graphql/query";
import clsx from "clsx";
import moment from "moment";
import Constants, { EmployeeTypes } from "../../Constants";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import WorkTeamDetails from "./WorkTeamDetails";
import { gql } from "@apollo/client";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Tooltip,
} from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import {
  showErrorToast,
  showSuccessToast,
  toggleLogsModal,
} from "../../Store/Actions";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid, validate as uuidValidate } from "uuid";
import { getRequestSettings } from "../../Helpers/HelperFns";

const DELETE_ATT_TYPE_CONFIG = gql`
  mutation deleteAttendanceTypeConfigurationMutation($id: ID!) {
    deleteAttendanceTypeConfiguration(id: $id) {
      ... on AttendanceTypeConfiguration {
        id
      }
      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

const workTeamInitState = {
  workTeam: null,
  start: null,
  end: null,
  id: uuid(),
};

const RowUnit = ({ isBool = false, label, txt = "", classes = "" }) => {
  const { t } = useTranslation();
  const isRender = isBool || (!isBool && Boolean(txt));

  return isRender ? (
    <div className={clsx("d-flex gap-10", classes)}>
      {label}
      <b
        dir="auto"
        style={{
          color: !isBool ? "#5d6979" : txt ? "green" : "red",
        }}
      >
        {!isBool ? txt : t(txt ? "Yes" : "no")}
      </b>
    </div>
  ) : null;
};

const Separator = ({ title }) => {
  const { t } = useTranslation();
  return (
    <>
      <Divider sx={{ gridColumn: "1/-1" }} />
      <strong style={{ gridColumn: "1/-1" }}>{t(title)}</strong>
    </>
  );
};

const AttTypeConfig = ({
  employeeType,
  config,
  refetch,
  loading,
  ...props
}) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  const showWorkGroupSection =
    config?.type.toLowerCase() === Constants.attendanceTypes.SHIFT_BASED ||
    config?.type.toLowerCase() === Constants.attendanceTypes.FLEXIBLE_HOURS;

  const workGroup =
    employeeType === EmployeeTypes.FIXED
      ? config?.workGroup
      : config?.flexWorkGroup;

  const workTeams =
    employeeType === EmployeeTypes.FIXED
      ? workGroup?.WorkTeams
      : workGroup?.flexWorkTeams;

  const workTeamEmployees =
    employeeType === EmployeeTypes.FIXED
      ? config?.workTeams
      : config?.flexWorkTeams;

  useEffect(() => {
    setConfigWorkTeams(workTeamEmployees);
    return () => {
      setConfigWorkTeams(workTeamEmployees);
    };
  }, [expanded, workTeamEmployees, props?.networkStatus, loading]);

  const [configWorkTeams, setConfigWorkTeams] = useState(
    workTeamEmployees || []
  );

  const [deleteAttTypeConfig, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_ATT_TYPE_CONFIG, {
      onCompleted(res) {
        if (res?.deleteAttendanceTypeConfiguration?.id) {
          refetch();
          dispatch(showSuccessToast());
          return;
        }
        if (
          res?.deleteAttendanceTypeConfiguration?.__typename ===
          "GeneralException"
        ) {
          dispatch(
            showErrorToast(res?.deleteAttendanceTypeConfiguration?.message)
          );
          return;
        }
      },
      onError(error) {
        dispatch(
          showErrorToast(
            error?.graphQLErrors[0]?.extensions?.reason ||
              error?.graphQLErrors[0]?.message ||
              error?.message
          )
        );
      },
    });

  // Start of fetch employee logs query function
  const handleShowLogs = (e) => {
    // handle on click accordion doesn`t open when btn is clicked
    e.stopPropagation();

    // handle fetch employee logs
    fetchEmployeeLogs({
      variables: {
        id: userId,
        employeeAttTypeId: +config?.id,
      },
    });
  };

  const [fetchEmployeeLogs, { loading: employeeLogsLoading }] = useLazyQuery(
    fetchEmployeeLogsQuery,
    {
      onError(err) {
        // error toaster
        dispatch(
          showErrorToast(
            err?.graphQLErrors[0]?.extensions?.reason ||
              err?.graphQLErrors[0]?.message ||
              err?.message
          )
        );
      },
      onCompleted: (data) => {
        // handle show logs modal
        dispatch(
          toggleLogsModal({
            isOpen: true,
            data: data?.employee?.relevantAttendanceTypeConfigurationById?.logs,
            name: data?.employee?.user?.name,
            date: config?.start,
            fromWhere: "employeeAttTypeConfigTab",
          })
        );
      },
    }
  );
  // End of fetch employee logs query function

  // Local State

  /* ↓ Helpers ↓ */

  const handleEditEmployeeSchedule = (config, event) => {
    const conf = {
      ...config,
      ...(employeeType === EmployeeTypes.FLEX && {
        workGroup: config?.flexWorkGroup,
        workTeam: config?.flexWorkTeam,
        work_hour_profile: config?.flexMonthlyProfile,
      }),
    };
    props.onEditAttTypeConfig(conf, event);
  };

  const handleDeleteEmployeeSchedule = (config, event) => {
    event.stopPropagation();
    if (deleteLoading) {
      return;
    }
    deleteAttTypeConfig({
      variables: {
        id: config?.id,
      },
    });
  };

  const handleAddWorkTeam = () => {
    setConfigWorkTeams((prevState) => [
      { ...workTeamInitState, id: uuid() },
      ...prevState,
    ]);
  };

  const handleDeleteNewWorkTeam = (id) => {
    setConfigWorkTeams((prevState) =>
      prevState?.filter((workTeam) => workTeam.id !== id)
    );
  };

  const requestLimitConfig = getRequestSettings(config?.requestSettings || []);

  return (
    <Accordion
      key={config?.id}
      TransitionProps={{ unmountOnExit: true }}
      onChange={(e, expanded) => setExpanded(expanded)}
      sx={{
        mb: "16px !important",
        "&::before": { display: "none" },
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
        "&.Mui-expanded": {
          px: "16px !important",
          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2) !important",
        },
      }}
    >
      {/* Accordion Summary */}
      <AccordionSummary
        disabled={config?.is_suspension}
        id={`panel-content-${config?.id}`}
        aria-controls={`panel-header-${config?.id}`}
      >
        <RowUnit
          txt
          classes="flex-1"
          label={`${
            config?.start
              ? moment(config?.start).format("Do MMM YYYY")
              : t("now")
          } - ${
            config?.end ? moment(config?.end).format("Do MMM YYYY") : "-----"
          }`}
        />
        {config?.is_suspension ? (
          <RowUnit classes="flex-1" txt={t("Suspended")} />
        ) : (
          <>
            <RowUnit
              classes="flex-1"
              txt={t(
                config?.type?.toLowerCase() ===
                  Constants.attendanceTypes.SHIFT_BASED
                  ? "shift based"
                  : config?.type
              )}
            />
            {showWorkGroupSection ? (
              <RowUnit
                classes="flex-1"
                label={t("work group")}
                txt={workGroup?.name ?? "--- ----- --"}
              />
            ) : (
              <RowUnit
                classes="flex-1"
                label={t("attendance profile")}
                txt={config?.attendanceProfile?.name ?? "--- ----- --"}
              />
            )}

            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_ATTENDANCE_TYPE_CONFIGURATION]}
              allowBP
            >
              <div
                style={{ pointerEvents: "all" }}
                className="align-items-center d-flex gap-10 user-select-all"
              >
                {/* employee attendance config logs icon */}
                {config?.logs && config?.logs?.length ? (
                  <div className="sign-in-logs-button-container">
                    {employeeLogsLoading ? (
                      <Spinner
                        style={{
                          width: "1rem",
                          height: "1rem",
                          color: "#333",
                        }}
                      />
                    ) : (
                      <Tooltip title={t("view logs")}>
                        <button
                          className="show-sign-in-logs-button-style"
                          onClick={(e) => handleShowLogs(e)}
                          style={{
                            color: "red",
                          }}
                        >
                          <AccessTime />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                ) : null}

                {/* {config?.canEdit ? ( */}
                <EditIconButton
                  onClick={(e) => handleEditEmployeeSchedule(config, e)}
                />
                {/* ) : null} */}
                {config?.canDelete ? (
                  deleteLoading ? (
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#ff6a6a",
                      }}
                    />
                  ) : (
                    <RemoveIconButton
                      onClick={(e) => handleDeleteEmployeeSchedule(config, e)}
                    />
                  )
                ) : null}
              </div>
            </HasPrivileges>
          </>
        )}
      </AccordionSummary>

      {/* Accordion Details */}
      <AccordionDetails className="p-0 pb-2">
        <Box
          sx={{
            gap: 1.25,
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          {/* Attendance Settings */}
          {config?.type?.toLowerCase() ===
          Constants.attendanceTypes.SHIFT_BASED ? (
            <>
              <Separator title="Attendance Settings" />
              <RowUnit
                label={t("leave and break profile")}
                txt={
                  config?.attendanceProfile?.permission_leaves_break_setting
                    ?.name
                }
              />
              <RowUnit label={t("current team")} txt={config?.workTeam?.name} />
              <RowUnit
                isBool
                label={t("allow multiple check-ins during shifts")}
                txt={config?.attendanceProfile?.allow_multiple_check_ins}
              />
              <RowUnit
                isBool
                label={t("allow employee to sign-in on their days off")}
                txt={config?.attendanceProfile?.allow_work_on_day_off}
              />
            </>
          ) : null}

          {/* Work Remotely Settings */}

          <Separator title="work remotely settings" />
          <RowUnit
            isBool
            label={t("allow employee to work remotely")}
            txt={config?.workRemotelySettings?.can_work_home}
          />
          <RowUnit
            isBool
            label={t("allow employee to request work remotely")}
            txt={config?.workRemotelySettings?.employee_can_request}
          />
          <RowUnit
            label={t("max. days per week")}
            txt={config?.workRemotelySettings?.max_homeDays_per_week}
          />
          {config?.type?.toLowerCase() !==
          Constants.attendanceTypes.SHIFT_BASED ? (
            <>
              <RowUnit
                isBool
                label={t(
                  "set default days where the employee can work remotely"
                )}
                txt={config?.workRemotelySettings?.flexible_home}
              />
              <RowUnit
                label={t("default days")}
                txt={config?.workRemotelySettings?.home_days}
              />
              <RowUnit
                isBool
                label={t("employee can request exchanging days")}
                txt={config?.workRemotelySettings?.can_ex_days}
              />
            </>
          ) : null}

          <Separator title="Day Off Request settings" />
          <RowUnit
            isBool
            label={t("Day off request limit")}
            txt={requestLimitConfig?.has_request_dayoff_limit}
          />
          {requestLimitConfig?.has_request_dayoff_limit ? (
            <>
              <RowUnit
                label={t("reset every")}
                txt={requestLimitConfig?.dayoff_period_type}
              />
              <RowUnit
                label={t("Max. Number of requests")}
                txt={requestLimitConfig?.dayoff_request_limits}
              />
            </>
          ) : null}

          <Separator title="Change shift Request settings" />
          <RowUnit
            isBool
            label={t("Change shift request limit")}
            txt={requestLimitConfig?.has_request_change_shift_limit}
          />
          {requestLimitConfig?.has_request_change_shift_limit ? (
            <>
              <RowUnit
                label={t("reset every")}
                txt={requestLimitConfig?.change_shift_period_type}
              />
              <RowUnit
                label={t("Max. Number of requests")}
                txt={requestLimitConfig?.change_shift_request_limits}
              />
            </>
          ) : null}

          <Separator title="additional shift Request settings" />
          <RowUnit
            isBool
            label={t("Additional shift request limit")}
            txt={requestLimitConfig?.has_request_additional_shift_limit}
          />
          {requestLimitConfig?.has_request_additional_shift_limit ? (
            <>
              <RowUnit
                label={t("reset every")}
                txt={requestLimitConfig?.additional_shift_period_type}
              />
              <RowUnit
                label={t("Max. Number of requests")}
                txt={requestLimitConfig?.additional_shift_request_limits}
              />
            </>
          ) : null}
        </Box>

        {moment(config?.start).isBefore() && showWorkGroupSection ? (
          <>
            <div className="align-items-baseline gap-20 mx-0 mt-2 mb-1 row">
              <h5>{t("work teams")}</h5>
              <HasPrivileges
                reqireMain={[Privilages.ADD_REMOVE_EMPLOYEES_FROM_TEAMS]}
                allowBP
              >
                <button
                  className="border-info btn-add btn-info rounded"
                  onClick={handleAddWorkTeam}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </HasPrivileges>
            </div>

            {configWorkTeams?.map((workTeam) => (
              <>
                <WorkTeamDetails
                  key={workTeam?.id}
                  newWorkTeam={uuidValidate(workTeam?.id)}
                  workTeam={workTeam}
                  options={workTeams}
                  employeeId={userId}
                  attendanceTypeConfigurationId={config?.id}
                  refetch={refetch}
                  handleDeleteNewWorkTeam={handleDeleteNewWorkTeam}
                  isFlex={
                    (config?.type.toLowerCase() ===
                    Constants.attendanceTypes.FLEXIBLE_HOURS) &&
                    false
                  }
                />
              </>
            ))}
          </>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default AttTypeConfig;

import React from "react";

import Privileges from "../../../Constants/Privilages";

import {
  TaxesList,
  CompaniesList,
  AdminUsersList,
  AnnouncementsList,
  AdminHolidaysList,
  DeletedSignInsList,
  ManualVerificationsList,
  FailedSignInsList,
} from "../../../Containers/AdminPanelLists";
import {
  faUser,
  faBuilding,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DangerousIcon from "@mui/icons-material/Dangerous";

const adminTabs = [
  {
    label: "Companies",
    icon: <FontAwesomeIcon icon={faBuilding} />,
    value: "companies",
    privileges: [Privileges.VIEW_COMPANIES, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <CompaniesList />,
  },
  {
    label: "Admin Users",
    icon: <FontAwesomeIcon icon={faUser} />,
    value: "admin-users",
    privileges: [Privileges.VIEW_ADMIN_USERS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <AdminUsersList />,
  },
  {
    label: "Holidays",
    icon: <FontAwesomeIcon icon={faPlaneDeparture} />,
    value: "holidays",
    privileges: [Privileges.VIEW_ADMIN_HOLIDAYS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <AdminHolidaysList />,
  },
  {
    label: "Taxes",
    icon: <FontAwesomeIcon icon={faFileLines} />,
    value: "taxes",
    privileges: [Privileges.VIEW_TAX_INFO, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <TaxesList />,
  },
  {
    label: "Announcements",
    icon: <FontAwesomeIcon icon={faFileLines} />,
    value: "announcements",
    privileges: [
      Privileges.VIEW_ADMIN_ANNOUNCEMENT,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <AnnouncementsList isAdminPanel />,
  },
  {
    label: "Manual Verification",
    icon: <FontAwesomeIcon icon={faUser} />,
    value: "manual-verification",
    privileges: [
      Privileges.ADMIN_MANUAL_VERIFY_EMPLOYEE,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <ManualVerificationsList />,
  },
  {
    label: "deleted sign ins",
    icon: <DeleteForeverIcon className="mx-2 routeBar-icon" />,
    value: "deleted-sign-ins",
    privileges: [Privileges.VIEW_COMPANY_LOGS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <DeletedSignInsList />,
  },
  {
    label: "failed sign ins",
    icon: <DangerousIcon className="mx-2 routeBar-icon" />,
    value: "failed-sign-ins",
    privileges: [
      Privileges.VIEW_FAILED_SIGN_IN_OUT_ATTEMPTS,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <FailedSignInsList />,
  },
];

export default adminTabs;

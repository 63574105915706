import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { onFormResetAction, showErrorToast } from "../../Store/Actions";
import HelperFns, { openSwalConfirm } from "../../Helpers/HelperFns";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import { InputForm, RadioboxForm } from "form-builder";
import { WorkDayTemplateForm } from "../../Components/FlexibleModals";
import {
  GET_WORK_DAY_TEMPLATE,
  GET_WORK_DAY_TEMPLATES,
} from "../../Graphql/query";
import Table from "rc-table";
import { Switch } from "@mui/material";
import {
  CHANGE_WORK_DAY_TEMPLATE_STATUS,
  DELETE_WORK_DAY_TEMPLATE,
} from "../../Graphql/mutation";
import { Spinner } from "reactstrap";
import { Tooltip } from "@mui/material";
import useDidUpdate from "../../Helpers/Hooks/useDidUpdate";
import moment from "moment";

const formName = "WorkDaysUpsertModal";

const rangesInitState = [{ id: null, from: "", to: "" }];

const normalizeFetch = ({
  id,
  name,
  work_hours_per_leave_day,
  restrict_work_hours,
  set_minimum_daily_hours,
  minimum_daily_hours,
  penalty_factor,
  deduct_from,
  allow_overtime,
  daily_workday_hours,
  calculate_overtime_after,
  allow_overtime_daily_limit,
  overtime_daily_limit,
  allow_maximum_daily_work_hours,
  maximum_daily_work_hours,
  daily_ranges = [],
  require_facial_recognition_with_clock_ins,
  require_facial_recognition_on_work_remotley,
  employee_can_clock_in_during_work_day_off_hours,
  treat_extra_overtime_hours_as,
}) => {
  return {
    id,
    name,
    hoursPerOneLeaveDay: HelperFns.timeToHours(work_hours_per_leave_day),
    setRestrictions: +restrict_work_hours,
    setMinDailyHrs: +set_minimum_daily_hours,
    minDailyHrs: HelperFns.timeToHours(minimum_daily_hours),
    calcPenalty: penalty_factor,
    deductFrom: deduct_from,
    isCalcOvertime: +allow_overtime,
    hoursPerDay: HelperFns.timeToHours(daily_workday_hours),
    overtimeAfter: HelperFns.timeToMinutes(calculate_overtime_after),
    setOvertimeDayLimit: +allow_overtime_daily_limit,
    overtimeDayLimit: HelperFns.timeToHours(overtime_daily_limit),
    setMaxHrsPerDay: +allow_maximum_daily_work_hours,
    maxHrsPerDay: HelperFns.timeToHours(maximum_daily_work_hours),
    ranges: daily_ranges?.map(({ id, from, to }, index) => ({
      id,
      from: moment(from),
      to: moment(to),
    })),
    applyFacialRecognition: require_facial_recognition_with_clock_ins,
    applyHomeFacialRecognition: require_facial_recognition_on_work_remotley,
    employeeCanClockinInOffHours:
      employee_can_clock_in_during_work_day_off_hours,
    treatExtraHoursAs: treat_extra_overtime_hours_as,
  };
};

const reducer = "flex";
const filtersFormName = "workHoursFilters";
const FiltersFormProps = { reducer, formName: filtersFormName };
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const WorkDayTemplates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [ranges, setRanges] = useState(rangesInitState);
  const [pagination, setPagination] = useState(paginationInitState);

  const [isOpen, setIsOpen] = useState(false);

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);

  // loading state start
  const [workDayTemplateIds, setWorkDayTemplateIds] = useState([]);

  const handleAddWorkDayTemplateToLoading = (id) => {
    setWorkDayTemplateIds((prev) => [...prev, id]);
  };

  const handleRemoveWorkDayTemplateFromLoading = (id) => {
    setWorkDayTemplateIds((prev) =>
      prev.filter((profileId) => profileId != id)
    );
  };
  // loading state end

  const [
    attemptGetAllWorkDayTemplates,
    {
      data: workDayTemplatesData,
      loading: workDayTemplatesLoading,
      refetch: refetchWorkDayTemplates,
    },
  ] = useLazyQuery(GET_WORK_DAY_TEMPLATES, {
    onCompleted: (data) => {
      setPagination({ ...(data?.FlexWorkDayTemplate?.paginatorInfo ?? {}) });
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const skipFirstRender = useDidUpdate(() => {
    attemptGetAllWorkDayTemplates({
      variables: {
        page: 1,
        input: {
          name: filters?.search,
          active: filters?.type == "Active" ? 1 : 0,
        },
      },
    });
  }, [filters?.search, filters?.type]);

  useEffect(() => {
    attemptGetAllWorkDayTemplates({
      variables: {
        page: pagination?.currentPage,
        input: {
          name: filters?.search,
          active: filters?.type == "Active" ? 1 : 0,
        },
      },
    });
  }, []);

  const [attemptGetWorkDayTemplate, { loading: workDayTemplateLoading }] =
    useLazyQuery(GET_WORK_DAY_TEMPLATE);

  const [
    attemptChangeWorkDayTemplateStatus,
    { loading: workDayTemplateStatusLoading },
  ] = useMutation(CHANGE_WORK_DAY_TEMPLATE_STATUS);

  const [
    attemptDeleteWorkDayTemplate,
    { loading: deleteWorkDayTemplateLoading },
  ] = useMutation(DELETE_WORK_DAY_TEMPLATE);

  const handleChangeWorkDayTemplateActivation = (workDayTemplateId) => {
    handleAddWorkDayTemplateToLoading(workDayTemplateId);
    attemptChangeWorkDayTemplateStatus({
      variables: { id: workDayTemplateId },
      onCompleted: (data) => {
        refetchWorkDayTemplates();
        handleRemoveWorkDayTemplateFromLoading(workDayTemplateId);
      },
      onError: (err) => {
        handleRemoveWorkDayTemplateFromLoading(workDayTemplateId);
        dispatch(
          showErrorToast(
            err?.graphQLErrors[0]?.extensions?.reason ||
              err?.graphQLErrors[0]?.message ||
              "Something went wrong"
          )
        );
      },
    });
  };

  const handleEditWorkDayTemplate = (workDayTemplateId) => {
    handleAddWorkDayTemplateToLoading(workDayTemplateId);
    attemptGetWorkDayTemplate({
      variables: { id: workDayTemplateId },
      onCompleted: (data) => {
        handleOpenModal();
        handleRemoveWorkDayTemplateFromLoading(workDayTemplateId);
        dispatch(
          onFormResetAction(formName, normalizeFetch(data?.flexWorkDayTemplate))
        );
        setRanges(
          data?.flexWorkDayTemplate?.flexWorkDayTemplateRanges
            ?.sort((a, b) => a?.order - b?.order)
            ?.map(({ id, from, to }) => ({
              id,
              from: moment(from, "HH:mm:ss"),
              to: moment(to, "HH:mm:ss"),
            }))
        );
      },
      onError: (err) => {
        handleRemoveWorkDayTemplateFromLoading(workDayTemplateId);
        dispatch(
          showErrorToast(
            err?.graphQLErrors[0]?.extensions?.reason ||
              err?.graphQLErrors[0]?.message ||
              "Something went wrong"
          )
        );
      },
    });
  };

  const handleDeleteWorkDayTemplate = (workDayTemplateId) => {
    handleAddWorkDayTemplateToLoading(workDayTemplateId);
    attemptDeleteWorkDayTemplate({
      variables: { id: workDayTemplateId },
      onCompleted: (data) => {
        if (data?.deleteFlexWorkDayTemplate?.status == "error") {
          dispatch(
            showErrorToast(
              data?.deleteFlexWorkDayTemplate?.message ?? "Something went wrong"
            )
          );
          return;
        }

        refetchWorkDayTemplates();
        handleRemoveWorkDayTemplateFromLoading(workDayTemplateId);
      },
      onError: (err) => {
        handleRemoveWorkDayTemplateFromLoading(workDayTemplateId);
        dispatch(
          showErrorToast(
            err?.graphQLErrors[0]?.extensions?.reason ||
              err?.graphQLErrors[0]?.message ||
              "Something went wrong"
          )
        );
      },
    });
  };

  // Constants
  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 250,
      render: (value) => (
        <Tooltip title={value}>
          <div className="text-ellipsis w-185">{value}</div>
        </Tooltip>
      ),
    },
    {
      title: t("time ranges"),
      dataIndex: "flexWorkDayTemplateRanges",
      key: "flexWorkDayTemplateRanges",
      align: "center",
      width: 500,
      render: (value) => {
        return (
          <div>
            {value?.map((range) => (
              <p className="mb-0">{`${moment(range?.from, "HH:mm:ss").format(
                "hh:mm A"
              )} - ${moment(range?.to, "HH:mm:ss").format("hh:mm A")}`}</p>
            ))}
          </div>
        );
      },
    },
    {
      title: t("work hours"),
      align: "center",
      children: [
        {
          title: t("Min. Daily Hrs"),
          dataIndex: "minimum_daily_hours",
          key: "minimum_daily_hours",
          width: 200,
          render: (value) => value ?? "-----",
        },
        {
          title: t("penalty"),
          dataIndex: "penalty_factor",
          key: "penalty_factor",
          width: 200,
          render: (value) => value ?? "-----",
        },
        {
          title: t("deduct from"),
          dataIndex: "deduct_from",
          key: "deduct_from",
          width: 200,
          render: (value) =>
            value
              ? value == "Leaves"
                ? t("annual leaves")
                : t("salary")
              : "-----",
        },
      ],
    },
    {
      title: t("overtime"),
      align: "center",
      children: [
        {
          title: t("normal hrs"),
          dataIndex: "daily_workday_hours",
          key: "daily_workday_hours",
          width: 200,
          render: (value) => value ?? "-----",
        },
        {
          title: t("limit"),
          dataIndex: "overtime_daily_limit",
          key: "overtime_daily_limit",
          width: 200,
          render: (value) => value ?? "-----",
        },
        {
          title: t("Max. Hrs"),
          dataIndex: "maximum_daily_work_hours",
          key: "maximum_daily_work_hours",
          width: 200,
          render: (value) => value ?? "-----",
        },
      ],
    },
    {
      title: "",
      // dataIndex: "maximum_work_hours",
      // key: "maximum_work_hours",
      width: 200,
      render: (workDayTemplate) => (
        <div className="d-flex align-items-center justify-content-center">
          <div className="monthly-profile-action-container">
            {workDayTemplateStatusLoading &&
            workDayTemplateIds?.includes(workDayTemplate?.id) ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#23aaeb" }}
              />
            ) : (
              <Switch
                checked={workDayTemplate?.is_active}
                onChange={() =>
                  handleChangeWorkDayTemplateActivation(workDayTemplate?.id)
                }
                color="primary"
              />
            )}
          </div>

          <div className="monthly-profile-action-container">
            {workDayTemplateLoading &&
            workDayTemplateIds?.includes(workDayTemplate?.id) ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#23aaeb" }}
              />
            ) : (
              <EditIconButton
                onClick={() => {
                  handleEditWorkDayTemplate(workDayTemplate?.id);
                }}
              />
            )}
          </div>

          <div className="monthly-profile-action-container">
            {deleteWorkDayTemplateLoading &&
            workDayTemplateIds?.includes(workDayTemplate?.id) ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#ff6a6a" }}
              />
            ) : (
              <RemoveIconButton
                onClick={() => {
                  handleDeleteConfirm(workDayTemplate?.id);
                }}
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  /* ↓ State Effects ↓ */

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(filtersFormName));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleDeleteConfirm = (workDayTemplateId) => {
    openSwalConfirm((isConfirmed) => {
      if (isConfirmed) {
        handleDeleteWorkDayTemplate(workDayTemplateId);
      }
    });
  };

  const handlePaginate = (page = 1) => {
    attemptGetAllWorkDayTemplates({
      variables: {
        page,
        input: {
          name: filters?.search,
          active: filters?.type == "Active" ? 1 : 0,
        },
      },
    });
  };

  return (
    <>
      {/* Filters */}
      <InputForm
        {...FiltersFormProps}
        name="search"
        placeholder="search"
        type="search"
        rootStyle="w-100 mt-2"
        containerStyle="w-100"
        icon="search"
      />
      <div className="d-flex justify-content-between align-items-center my-3">
        <RadioboxForm
          {...FiltersFormProps}
          name="type"
          options={[
            { label: "Active", value: "Active" },
            { label: "Inactive", value: "Inactive" },
            // { label: "All", value: "All" },
          ]}
          optionItemStyle=" "
          optionInputStyle=" "
          containerStyle="mb-0"
          optionsContainerStyle="d-flex gap-10"
        />
        <AddButton onClick={() => handleOpenModal()} />
      </div>

      {workDayTemplatesLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <Table
        columns={columns}
        data={workDayTemplatesData?.FlexWorkDayTemplate?.data ?? []}
        emptyText={<div className="text-center">{t("no data available")}</div>}
        footer={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
        useFixedHeader
        tableLayout
        style={{ width: "auto" }}
        className="monthly-profiles-table"
        // className="employee_list_datatabel_wrapper_style datatable_shadow_style"
        // prefixCls="employees-list"
        // scroll={{ x: 1500 }}
        // onRow={(record, index) => ({
        //   className: `row-${index}`,
        // })}
      />

      {isOpen ? (
        <WorkDayTemplateForm
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          refetchWorkDayTemplates={refetchWorkDayTemplates}
          ranges={ranges}
          setRanges={setRanges}
          rangesInitState={rangesInitState}
        />
      ) : null}
    </>
  );
};

export default WorkDayTemplates;

import gql from "graphql-tag";

export const DEDUCTION_POLICY_QUERY = gql`
  query getDeductioPolices($first: Int = 30, $page: Int = 1) {
    deduction_policies(first: $first, page: $page) {
      data {
        id
        name
        violations {
          amount
          type
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { connect, useDispatch, useSelector } from "react-redux";
import SalaryConfigForm from "../../Components/SalaryConfigForm";

import {
  dismissUpsertSalaryConfig,
  onFormResetAction,
  showErrorToast,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";

import { useParams } from "react-router-dom";
import moment from "moment";

import { useTranslation } from "react-i18next";
import HelperFns, { normalizeSalaryConfigInput } from "../../Helpers/HelperFns";
import { UPSERT_SALARY_CONFIG } from "../../Graphql/mutation";
import { useMutation } from "@apollo/client";

const formName = "salaryConfigForm";
const formServerValidation = "upsertSalaryConfigServerValidation";

import swal from "sweetalert";

const UpsertSalaryConfigModal = (props) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [attemptUpsertSalaryConfig, { loading }] = useMutation(
    UPSERT_SALARY_CONFIG,
    {
      onCompleted: () => {
        props?.refetch();
        handleDismissSalaryConfigModal();
        dispatch(onFormResetAction("timeRangeProfileSetting"));
      },
      onError: (errors) => {
        if (errors?.graphQLErrors?.[0]?.extensions?.validation) {
          dispatch(
            onFormResetAction(
              formServerValidation,
              HelperFns.parseValidatorErrors(
                errors?.graphQLErrors?.[0]?.extensions?.validation
              )
            )
          );
        } else if (errors?.[0]?.extensions?.reason) {
          dispatch(
            showErrorToast(
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            )
          );
        }
      },
    }
  );

  const userOffice = useSelector(
    (state) => state?.super?.selectedEmployeeProfile?.office
  );


  const userCountryId = useSelector(
    (state) => state?.super?.selectedEmployeeProfile?.office?.country?.id
  );

  const isFlexEmployee = useSelector(
    (state) =>
      state?.super?.selectedEmployeeProfile?.employee?.__typename ==
      "FlexEmployee" && false
  );

  const salaryConfigForm = useSelector((state) => state.super.salaryConfigForm);
  const timeRangeProfileSetting = useSelector(
    (state) => state?.super?.timeRangeProfileSetting
  );

  const salaryConfigStartAt = useSelector(
    (state) => state?.super?.salaryConfigForm?.start_at
  );

  const { t } = useTranslation();

  const { userId } = useParams();

  useEffect(() => {
    setIsSubmitting(false);
    return () => {
      setIsSubmitting(false);
    };
  }, [props.modalActions.isVissible]);

  const isModalVissible = props.modalActions.isVissible;
  const modalMessage = props.modalActions.modalMessage;

  const monthlyProfileClientValidations = useSelector(
    (state) => state?.flex?.flexibleClientValidation
  );

  const monthlyProfileFormdData = useSelector(
    (state) => state?.flex?.MonthlyProfileForm
  );

  const save = () => {
    let salaryConfigDate =
      salaryConfigForm?.payment_interval == "weekly"
        ? salaryConfigForm?.week_start
        : moment(salaryConfigForm?.start_at).format("YYYY-MM-DD");

    const { min_overtime_cutoff, max_overtime_limit, min_work_hours, ...rest } =
      timeRangeProfileSetting;

    let timeRangeProfileSettingInput = {
      ...rest,
      min_overtime_cutoff: HelperFns.hoursToTime(min_overtime_cutoff),
      max_overtime_limit: HelperFns.hoursToTime(max_overtime_limit),
      min_work_hours: HelperFns.hoursToTime(min_work_hours),
    };

    attemptUpsertSalaryConfig({
      variables: {
        args: {
          ...normalizeSalaryConfigInput(
            userId,
            props?.salaryConfigForm,
            salaryConfigDate,
            isFlexEmployee
              ? monthlyProfileFormdData
              : timeRangeProfileSettingInput,
            isFlexEmployee
          ),
        },
      },
    });
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    dispatch(onFormResetAction(formServerValidation));
    if (!props.salaryConfigFormValidation?.length) {
      if (isFlexEmployee && monthlyProfileClientValidations?.length) {
        return;
      }

      if (
        !props.modalActions.prevSalaryType &&
        props.salaryConfigForm.salary_management_type
      ) {
        swal({
          title: t("are you sure"),
          text: t("external_salary_note"),
          icon: "warning",
          className: "swal-warning-style",
          dangerMode: true,
          buttons: [t("Cancel"), t("OK")],
        }).then((willDelete) => {
          if (willDelete) {
            save();
          }
        });
        return;
      }
      save();
    }
    return;
  };

  const handleDismissSalaryConfigModal = () => {
    setIsSubmitting(false);
    dispatch(dismissUpsertSalaryConfig());
  };

  if (props?.modalActions?.loadingStatus) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={handleDismissSalaryConfigModal}
      modalTitle={t(modalMessage)}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={loading}
      size="lg"
      disableSubmitButton={!!!salaryConfigStartAt}
    >
      {isModalVissible ? (
        <SalaryConfigForm
          formName={formName}
          isFirst={false}
          formSubmitting={isSubmitting}
          formServerValidation={formServerValidation}
          countryId={userCountryId}
          userId={userId}
          office={userOffice}
          isSalaryConfigModal={true}
          isFlex={isFlexEmployee}
        />
      ) : null}
    </MainModal>
  );
};
const mapStateToProps = (state) => {
  return {
    salaryConfigFormValidation: state.super.salaryConfigFormValidation,
    salaryConfigForm: state.super.salaryConfigForm,
    serverValidation: state.super.upsertSalaryConfigServerValidation,
    modalActions: state.super.upsertSalaryConfigModalActions,
    companySalaryConfigPresets: state.super.companySalaryConfigPresets,
  };
};

export default connect(mapStateToProps, {})(UpsertSalaryConfigModal);

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import {
  updateValueAction,
  onFormResetAction,
  onInputResetAction,
} from "../../Store/Actions";
import {
  getInvoicesQuery,
  changePlanModalQuery,
  getGeneratedInvoicesQuery,
  getPlanAvailableDatesQuery,
} from "../../Graphql/query/PaymentTracking";
import {
  billingPeriodOptions,
  promotionTypeOptions,
} from "../../Constants/PaymentTracking";
import {
  getPlansOptions,
  getTiersOptions,
} from "../../Helpers/HelperFns/PaymentTracking";
import moment from "moment";
import Privileges from "../../Constants/Privilages";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { changeSubscriptionPlanMutation } from "../../Graphql/mutation/PaymentTracking";

import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Loader from "../Loader";
import Box from "@mui/material/Box";
import MainModal from "../MainModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { ChangePlanTierGeneratedInvoices } from "../PaymentTracking";

const reducer = "paymentTracking";
const formName = "changePlanModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const formatOption = (str) => ({ id: str, name: str });
const formatSaveData = (data) => {
  const applyToAll = data?.promotions.length > 1 && data?.applyPromotionAll;
  const json = JSON.stringify({
    from: data?.startFrom,
    plan_id: data?.plan,
    tier: data?.tier,
    promotions: data?.applyPromotion
      ? {
          applyToAll,
          subscriptionInvoices: applyToAll
            ? [data?.promotions?.[0]]
            : data?.promotions,
        }
      : {},
  });

  return {
    json,
    from: data?.from,
    company_id: +data?.company_id,
  };
};

const ChangePlanModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [promotions, setPromotions] = React.useState([]);
  const [currentPlan, setCurrentPlan] = React.useState(null);
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [generatedInvoices, setGeneratedInvoices] = React.useState([]);
  const [isSelectMonthYear, setIsSelectMonthYear] = React.useState(true);
  const [options, setOptions] = React.useState({
    tiers: [],
    plans: [],
    plansRef: [],
    availableDates: [],
  });

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [getInvoicesNum, { loading: getInvoicesNumLoading }] =
    useLazyQuery(getInvoicesQuery);
  const { loading } = useQuery(changePlanModalQuery, {
    onCompleted: ({ plans, invoicesNum = 0 }) => {
      // Options
      const plansRef = getPlansOptions(plans?.data, +data?.company_id);
      setOptions((prev) => ({
        ...prev,
        plansRef,
        invoicesNum,
        plans: plansRef, // don't exclude current plan from options until make a new query
      }));
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });
  const [getPlanAvailableDates, { loading: getPlanAvailableDatesLoading }] =
    useLazyQuery(getPlanAvailableDatesQuery);
  const [getGeneratedInvoices, { loading: getGeneratedInvoicesLoading }] =
    useLazyQuery(getGeneratedInvoicesQuery);
  const [changeSubscriptionPlan, { loading: upsertLoading }] = useMutation(
    changeSubscriptionPlanMutation
  );

  // Constants
  const selectedPlanCurrency = currentPlan?.currency?.name;
  const FormProps = {
    reducer,
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (formData?.billingPeriod && formData?.startFrom) {
      getInvoicesNum({
        variables: {
          from: data?.from,
          date: formData?.startFrom,
          companyId: +data?.company_id,
          period: formData?.billingPeriod,
        },
        onCompleted: ({ invoices }) => {
          generatePromotions(invoices?.length);
        },
        onError: (err) => {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        },
      });
    }
  }, [formData?.billingPeriod, formData?.startFrom]);

  /* ↓ Helpers ↓ */

  const generatePromotions = (num) => {
    setPromotions(
      Array.from(Array(num), (_, i) => i + 1).map(() => ({
        type: "Fixed",
        amount: "0",
      }))
    );
  };

  const handlePromotionTypeChange = (promotionIdx, type) => {
    setPromotions((prev) =>
      prev.map((p, i) => (promotionIdx === i ? { ...p, type } : p))
    );
  };

  const handlePromotionAmountChange = (promotionIdx, amount) => {
    setPromotions((prev) =>
      prev.map((p, i) => (promotionIdx === i ? { ...p, amount } : p))
    );
  };

  const handleGetPlanAvailableDates = (period) => {
    if (period) {
      getPlanAvailableDates({
        variables: {
          period,
          from: data?.from,
          companyId: +data?.company_id,
        },
        onCompleted: ({ availableDates }) => {
          setOptions((prev) => ({
            ...prev,
            availableDates: availableDates
              ? availableDates.map((date) => ({
                  id: date,
                  name: moment(date).format("MMMM, YYYY"),
                }))
              : [],
          }));
        },
        onError: (err) => {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        },
      });
    }
  };

  const handleGetCanaledInvoices = (date) => {
    if (date) {
      getGeneratedInvoices({
        variables: {
          date,
          from: data?.from,
          companyId: +data?.company_id,
        },
        onCompleted: ({ generatedInvoices }) => {
          setGeneratedInvoices(generatedInvoices);
        },
        onError: (err) => {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        },
      });
    }
  };

  const onSelectBillingPeriod = (_, val) => {
    handleGetPlanAvailableDates(val?.id);
    dispatch(
      onFormResetAction(formName, {
        ...formData,
        plan: null,
        tier: null,
        ratePerPeriod: null,
      })
    );
    setOptions((prev) => ({
      ...prev,
      plans: val
        ? prev?.plansRef.filter((p) => p?.billingPeriod === val?.id)
        : prev?.plansRef,
    }));
  };

  const onSelectStartFrom = (moment, option) => {
    handleGetCanaledInvoices(option?.id || moment.format("YYYY-MM-DD"));
  };

  const onSelectPlan = (_, plan) => {
    setCurrentPlan(plan);
    setOptions((prev) => ({
      ...prev,
      tiers: getTiersOptions(plan),
    }));
    dispatch(
      onFormResetAction(formName, {
        ...formData,
        tier: null,
        ratePerPeriod: "",
        contractDuration: plan.contractDuration,
      })
    );
  };

  const onSelectTier = (_, tier) => {
    dispatch(updateValueAction(formName, "ratePerPeriod", tier.ratePerPeriod));
  };

  const handleToggleIsSelectMonthYear = () => {
    setIsSelectMonthYear((prev) => !prev);
    dispatch(onInputResetAction(formName, "startFrom"));
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const isInvalidClientValidation = () => {
    if (formClientValidation?.length) return true;

    if (generatedInvoices?.length) {
      !HelperFns.checkPrivileges({
        privileges: [Privileges.SUPER_PRIVILEGE, Privileges.CANCEL_GENERATED_INVOICES],
      })
        ? setErrMsg("you should cancel all generated invoices first. contact an account manager")
        : setErrMsg(
            "you should cancel all generated invoices first"
          );
      return true;
    }

    if (formData.applyPromotion) {
      if (
        formData?.applyPromotionAll &&
        (!promotions?.[0]?.type || !promotions?.[0]?.amount)
      ) {
        setErrMsg("all promotions fields are required");
        return true;
      }

      if (
        !formData?.applyPromotionAll &&
        promotions?.some((prom) => !prom?.type || !prom?.amount)
      ) {
        setErrMsg("all promotions fields are required");
        return true;
      }
    }

    return false;
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (isInvalidClientValidation()) return;

    changeSubscriptionPlan({
      variables: formatSaveData({
        ...formData,
        promotions,
        from: data?.from,
        company_id: data?.company_id,
      }),
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      modalTitle="Change Plan"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={upsertLoading}
    >
      {loading ||
      getPlanAvailableDatesLoading ||
      getGeneratedInvoicesLoading ||
      getInvoicesNumLoading ? (
        <Loader fixed />
      ) : null}

      <BSelect
        isClearable
        {...FormProps}
        label="Billing period"
        name="billingPeriod"
        icon="calendar"
        rootStyle="w-50 d-inline-block pr-2"
        options={billingPeriodOptions}
        validateBy="textRequired"
        onInterceptInputOnChange={onSelectBillingPeriod}
        validationName="period"
      />
      <div className="position-relative d-inline-block w-50">
        <HasPrivileges
          reqireMain={[
            Privileges.APPLY_CHANGE_ON_PAYMENT_PLANS_ON_CUSTOM_DATES,
            Privileges.SUPER_PRIVILEGE,
          ]}
        >
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <button
              className="link-btn"
              onClick={handleToggleIsSelectMonthYear}
            >
              {t(isSelectMonthYear ? "Select Custom date" : "Select date")}
            </button>
          </Box>
        </HasPrivileges>

        {isSelectMonthYear ? (
          <BSelect
            {...FormProps}
            name="startFrom"
            label="Start From"
            placeholder="Select date"
            validateBy="textRequired"
            isDisabled={!options?.availableDates?.length}
            options={options?.availableDates || []}
            onInterceptInputOnChange={onSelectStartFrom}
            validationName="from"
          />
        ) : (
          <DateTimePickerForm
            {...FormProps}
            name="startFrom"
            label="Start From"
            format="yyyy-MM-DD"
            requestFormat="yyyy-MM-DD"
            validateBy="textRequired"
            labelStyle="mb-2 pt-1"
            containerStyle="w-100"
            hasIcon
            validationName="from"
            onIntercept={onSelectStartFrom}
          />
        )}
      </div>

      <BSelect
        {...FormProps}
        label="new plan"
        name="plan"
        icon="document"
        validateBy="textRequired"
        options={options?.plans}
        isLoading={loading}
        isDisabled={!formData?.billingPeriod}
        onInterceptInputOnChange={onSelectPlan}
        validationName="plan"
      />

      <BSelect
        {...FormProps}
        label="Tier"
        name="tier"
        icon="employees"
        placeholder="Select Tier"
        validateBy="textRequired"
        rootStyle="w-50 d-inline-block pr-2"
        isDisabled={!formData.plan || !currentPlan}
        options={formData.plan ? options?.tiers : []}
        onInterceptInputOnChange={onSelectTier}
        validationName="tier"
      />
      <InputForm
        {...FormProps}
        name="ratePerPeriod"
        label="Rate Per Period"
        labelStyle="mb-2 pt-1"
        containerStyle="w-100"
        rootStyle="w-50 d-inline-block"
        validateBy="textRequired"
        icon="calculator"
        disabled
      />

      {/* Promotions */}
      <div className="border px-2 py-3 mt-4">
        <CheckboxBooleanForm
          {...FormProps}
          name="applyPromotion"
          options={["Apply Promotion"]}
          disabled={
            !(currentPlan && formData?.billingPeriod && formData?.startFrom)
          }
          hasTooltip={
            !(currentPlan && formData?.billingPeriod && formData?.startFrom)
          }
          tooltipTitle="choose your plan, billing period and start date to apply promotions"
        />

        {formData?.applyPromotion && formData?.applyPromotionAll ? (
          <>
            <BSelect
              label="Promotion Type"
              icon="document"
              placeholder="Select Promotion Type"
              rootStyle="w-50 d-inline-block pr-2 mt-2"
              options={promotionTypeOptions}
              value={
                promotions?.[0]?.type
                  ? formatOption(promotions?.[0]?.type)
                  : null
              }
              onChange={(val) => handlePromotionTypeChange(0, val.id)}
            />
            <InputForm
              name="amount"
              label={
                promotions?.[0]?.type === "Percentage" ? "Percent" : "Amount"
              }
              labelStyle="mb-2 pt-1"
              containerStyle="w-100"
              inputStyle="w-100 text-left"
              inputContainerStyle="w-100"
              rootStyle="w-50 d-inline-block mt-2"
              placeholder={
                promotions?.[0]?.type === "Percentage"
                  ? "Enter percentage"
                  : "Enter amount"
              }
              hasSuffix
              suffixTitle={
                promotions?.[0]?.type === "Percentage"
                  ? "%"
                  : selectedPlanCurrency
              }
              value={promotions?.[0]?.amount}
              onChange={(e) => handlePromotionAmountChange(0, e.target.value)}
            />
          </>
        ) : null}
        {promotions.length > 1 ? (
          <CheckboxBooleanForm
            {...FormProps}
            name="applyPromotionAll"
            options={["Apply Promotion to all invoices"]}
            dependOn="applyPromotion"
            dependancyType="equal"
            dependancyValue={[1]}
          />
        ) : null}

        {formData?.applyPromotionAll ? null : (
          <div className="mt-3">
            {promotions?.map((prom, idx) => (
              <div key={idx} className="d-flex align-items-center gap-10 mb-1">
                <span style={{ minWidth: 70 }}>Invoice #{idx + 1}</span>
                <BSelect
                  icon="document"
                  placeholder="Select Promotion Type"
                  rootStyle="flex-1"
                  options={promotionTypeOptions}
                  value={prom?.type ? formatOption(prom?.type) : null}
                  onChange={(val) => handlePromotionTypeChange(idx, val.id)}
                />
                <InputForm
                  name="amount"
                  labelStyle="mb-2 pt-1"
                  containerStyle="w-100"
                  inputStyle="w-100 text-left"
                  inputContainerStyle="w-100"
                  rootStyle="flex-1"
                  placeholder={
                    prom?.type === "Percentage"
                      ? "Enter percentage"
                      : "Enter amount"
                  }
                  hasSuffix
                  suffixTitle={
                    prom?.type === "Percentage" ? "%" : selectedPlanCurrency
                  }
                  value={prom?.amount}
                  onChange={(e) =>
                    handlePromotionAmountChange(idx, e.target.value)
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <ChangePlanTierGeneratedInvoices
        invoices={generatedInvoices}
        refetch={() => handleGetCanaledInvoices(formData?.startFrom)}
      />

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default ChangePlanModal;

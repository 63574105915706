import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import MainModal from "../MainModal";
import { BSelect } from "form-builder";
import { toggleAttendanceActionModal } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { gql, useQuery } from "@apollo/client";
import AttendanceActionModal from "./AttendanceActionModal";
import FlexAttendanceActionModal from "./FlexAttendanceActionModal";
import { EmployeeTypes } from "../../Constants";

const GET_EMPLOYEES = gql`
  query getEmployees {
    users_by_role(
      first: 9999
      input: { page_flag: "employees_list", status: ["Active"] }
    ) {
      data {
        id
        name
        employee {
          ... on FlexEmployee {
            __typename
          }
          ... on Employee {
            __typename
          }
        }
      }
    }
  }
`;

const MainFlexRequestModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [clientEmployee, setClientEmployee] = useState(null);

  const { data: emps, loading: empsLLoading } = useQuery(GET_EMPLOYEES, {
    skip: !props?.hasEmpInput,
  });

  const isModalVissible = useSelector(
    (state) => state?.user?.attendanceModalActions?.isVissible
  );

  const isFlexRequest = useSelector(
    (state) => state?.user?.attendanceModalActions?.isFlex
  ) && false;

  const handleCloseModal = () => {
    dispatch(toggleAttendanceActionModal());
  };

  const isFlex = props?.hasEmpInput
    ? clientEmployee?.employee?.__typename === EmployeeTypes.FLEX
    : isFlexRequest;

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={handleCloseModal}
      modalTitle={t("new request")}
      hasModalFooter={false}
    >
      {props?.hasEmpInput ? (
        <BSelect
          onChange={(val) => setClientEmployee(val)}
          icon="user"
          labelStyle="mb-2"
          containerStyle="mb-2"
          label={t("employee")}
          value={clientEmployee}
          isLoading={empsLLoading}
          options={emps?.users_by_role?.data || []}
        />
      ) : null}

      {isFlex && false ? (
        <FlexAttendanceActionModal clientEmployee={clientEmployee} {...props} />
      ) : (
        <AttendanceActionModal clientEmployee={clientEmployee} {...props} />
      )}
    </MainModal>
  );
};

export default MainFlexRequestModal;

import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  onFormResetAction,
  setFormServerValidationAction,
  showErrorToast,
} from "../../Store/Actions";

import {
  InputForm,
  RadioboxForm,
  CheckboxBooleanForm,
  DateTimePickerForm,
} from "form-builder";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import { RemoveIconButton } from "../IconButtonWithTooltip";
import moment from "moment";
import { UPSERT_FLEX_WORK_DAY_TEMPLATE } from "../../Graphql/mutation";
import HelperFns from "../../Helpers/HelperFns";
import { treatExtraHoursAsOptions } from "../../Constants";

const reducer = "flex";
const formName = "WorkDaysUpsertModal";
const formNameValidation = "flexibleClientValidation";
const formServerValidation = "flexibleServerValidation";

const normalizeUpsert = (data, ranges) => {
  return {
    id: data?.id ?? null,
    name: data?.name,
    work_hours_per_leave_day: HelperFns.hoursToTime(data?.hoursPerOneLeaveDay),
    restrict_work_hours: !!data?.setRestrictions,

    ...(!!data?.setRestrictions
      ? {
          set_minimum_daily_hours: !!data?.setMinDailyHrs,
          minimum_daily_hours: HelperFns.hoursToTime(data?.minDailyHrs),
          penalty_factor: parseFloat(data?.calcPenalty),
          deduct_from: data?.deductFrom,
          allow_overtime: !!data?.isCalcOvertime,
          daily_workday_hours: HelperFns.hoursToTime(data?.hoursPerDay),
          calculate_overtime_after: HelperFns.minutesToTime(
            data?.overtimeAfter
          ),
          allow_overtime_daily_limit: !!data?.setOvertimeDayLimit,
          overtime_daily_limit: HelperFns.hoursToTime(data?.overtimeDayLimit),
          allow_maximum_daily_work_hours: !!data?.setMaxHrsPerDay,
          maximum_daily_work_hours: data?.setMaxHrsPerDay
            ? HelperFns.hoursToTime(data?.maxHrsPerDay)
            : null,
          ...(!!data?.isCalcOvertime &&
          !!data?.setOvertimeDayLimit &&
          !!data?.setMaxHrsPerDay
            ? {
                treat_extra_overtime_hours_as: data?.treatExtraHoursAs,
              }
            : {}),
        }
      : {}),
    daily_ranges: ranges?.map(({ id, from, to }, index) => ({
      id: id ?? null,
      from: moment(from).format("HH:mm:ss"),
      to: moment(to).format("HH:mm:ss"),
      order: index + 1,
    })),
    require_facial_recognition_with_clock_ins: !!data?.applyFacialRecognition,
    require_facial_recognition_on_work_remotley:
      !!data?.applyFacialRecognition && !!data?.applyHomeFacialRecognition
        ? true
        : false,
    employee_can_clock_in_during_work_day_off_hours:
      !!data?.employeeCanClockinInOffHours,
  };
};

const WorkDayTemplateForm = ({
  isOpen,
  handleCloseModal: onClose,
  refetchWorkDayTemplates,
  ranges,
  setRanges,
  rangesInitState,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [rangesErrors, setRangesErrors] = useState([]);

  useEffect(() => {
    ranges.forEach((range, index) => {
      if (moment(range?.from).isAfter(moment(range?.to), "seconds")) {
        setRangesErrors((prev) => [
          ...prev,
          {
            name: `range.${index}.to`,
            message: `this time must be after ${moment(range?.from).format(
              "HH:mm A"
            )}`,
          },
        ]);
      } else {
        setRangesErrors((prev) =>
          prev?.filter((rangeError) => rangeError?.name != `range.${index}.to`)
        );
      }

      if (
        index != 0 &&
        !moment(range?.from).isAfter(moment(ranges[index - 1]?.to), "seconds")
      ) {
        setRangesErrors((prev) => [
          ...prev,
          {
            name: `range.${index}.from`,
            message: `this time must be after ${moment(
              ranges[index - 1]?.to
            ).format("HH:mm A")}`,
          },
        ]);
      } else {
        setRangesErrors((prev) =>
          prev?.filter(
            (rangeError) => rangeError?.name != `range.${index}.from`
          )
        );
      }
    });

    return () => {
      setRangesErrors([]);
    };
  }, [ranges]);

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);

  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
    setFormSubmitting(false);
    setRanges(rangesInitState);
  };

  // Constants
  const FormProps = {
    reducer,
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  const testv = useSelector((state) => state?.flex?.[formServerValidation]);
  useEffect(() => {
    console.log(testv);
  }, [testv]);

  const [
    attemptUpsertFlexWorkDayTemplate,
    { loading: upsertWorkDayTemplateloading },
  ] = useMutation(UPSERT_FLEX_WORK_DAY_TEMPLATE, {
    variables: {
      input: normalizeUpsert(formData, ranges),
    },
    onCompleted: () => {
      handleCloseModal();
      refetchWorkDayTemplates();
    },
    onError: (err) => {
      if (err?.graphQLErrors?.[0]?.extensions?.validation) {
        dispatch(
          setFormServerValidationAction({
            serverValidationName: FormProps.formServerValidation,
            validations: err?.graphQLErrors?.[0]?.extensions?.validation,
          })
        );
        return;
      }
      dispatch(
        showErrorToast(
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            "Something went wrong"
        )
      );
    },
  });

  const handleAddRange = () => {
    setRanges((prev) => [...prev, rangesInitState]);
  };

  const handleRemoveRange = (idx) => {
    setRanges((prev) => prev.filter((_, i) => i !== idx));
  };

  const handleRangeChange = (name, val, idx) => {
    setRanges((prev) =>
      prev.map((p, i) => (i === idx ? { ...p, [name]: val } : p))
    );
  };

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (
      formClientValidation?.length ||
      !!ranges.find((range) => !!!range?.from || !!!range?.to) ||
      rangesErrors.length
    ) {
      return;
    }

    attemptUpsertFlexWorkDayTemplate();
  };

  return (
    <MainModal
      isOpen={isOpen}
      modalTitle="New Work Day Template"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={upsertWorkDayTemplateloading}
    >
      <InputForm
        {...FormProps}
        placeholder={t("name")}
        name="name"
        label="name"
        rootStyle="w-100"
        containerStyle="w-100"
        validateBy="textRequired"
      />

      {/* Ranges */}
      <div className="d-flex align-items-center gap-10 mt-3 mb-2">
        <strong>{t("Ranges")}</strong>
        <AddButton isIconOnly onClick={handleAddRange} />
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}>
        <span>{t("From")}</span>
        <span>{t("to")}</span>
        {ranges?.map((range, i) => (
          <React.Fragment key={i}>
            <div>
              <DateTimePickerForm
                {...FormProps}
                value={range?.from}
                onChange={(val) => handleRangeChange("from", val, i)}
                onSelect={(val) => handleRangeChange("from", val, i)}
                datePickerContainer="w-100"
                timeOnly
                validateBy="textRequired"
                validationName={"input.from"}
              />
              {formSubmitting &&
              !!rangesErrors?.find(
                (rangeError) => rangeError?.name == `range.${i}.from`
              ) ? (
                <div className="invalid-container-style">
                  <p className="validity-msg-style">
                    {
                      rangesErrors?.find(
                        (rangeError) => rangeError?.name == `range.${i}.from`
                      )?.message
                    }
                  </p>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center gap-10">
              <div className="w-100">
                <DateTimePickerForm
                  {...FormProps}
                  value={range?.to}
                  onChange={(val) => handleRangeChange("to", val, i)}
                  onSelect={(val) => handleRangeChange("to", val, i)}
                  datePickerContainer="w-100"
                  rootStyle="flex-1 w-100"
                  timeOnly
                  validateBy="textRequired"
                  validationName={"input.to"}
                />
                {formSubmitting &&
                !!rangesErrors?.find(
                  (rangeError) => rangeError?.name == `range.${i}.to`
                ) ? (
                  <div className="invalid-container-style">
                    <p className="validity-msg-style">
                      {
                        rangesErrors?.find(
                          (rangeError) => rangeError?.name == `range.${i}.to`
                        )?.message
                      }
                    </p>
                  </div>
                ) : null}
              </div>
              {ranges.length > 1 && i !== 0 ? (
                <RemoveIconButton onClick={() => handleRemoveRange(i)} />
              ) : null}
            </div>
          </React.Fragment>
        ))}
      </div>

      <InputForm
        {...FormProps}
        name="hoursPerOneLeaveDay"
        label="No. of Hours per one leave day"
        containerStyle="d-flex align-items-center justify-content-between mb-2"
        validateBy={"number_req_non_negative"}
        hasSuffix
        suffixTitle="hours"
        rootStyle="my-2"
        validationName={"input.work_hours_per_leave_day"}
      />

      <CheckboxBooleanForm
        {...FormProps}
        name="setRestrictions"
        containerStyle="my-3"
        options={["Set restrictions to the number of work Hrs"]}
      />

      {formData?.setRestrictions ? (
        <>
          <div className="options_container">
            <span className="options_container_title">{t("Work Hours")}</span>

            {formData?.setRestrictions ? <strong>{t("")}</strong> : null}
            <div
              className={"d-flex justify-content-between align-items-center"}
            >
              <CheckboxBooleanForm
                {...FormProps}
                name="setMinDailyHrs"
                containerStyle="my-3"
                options={["Set Minimum Daily Hrs"]}
                dependOn="setRestrictions"
                dependancyType="equal"
                dependancyValue={[1]}
                validationName={"input.minimum_daily_hours"}
              />
              <InputForm
                {...FormProps}
                name="minDailyHrs"
                labelStyle="mr-2"
                dependOn="setMinDailyHrs"
                dependancyType="equal"
                dependancyValue={[1]}
                validateBy={"number_req_non_negative"}
                hasSuffix
                suffixTitle="hours"
              />
            </div>
            <InputForm
              {...FormProps}
              name="calcPenalty"
              label="Calculate penalty by multiplying the missing Hrs by"
              containerStyle="d-flex align-items-center justify-content-between"
              stepInput
              dependOn="setMinDailyHrs"
              dependancyType="equal"
              dependancyValue={[1]}
              validateBy="textRequired"
              validationName={"input.penalty_factor"}
            />
            <RadioboxForm
              {...FormProps}
              name="deductFrom"
              label="Deduct Penalty From"
              options={[
                { label: "Annual Leaves", value: "Leaves" },
                { label: "Salary", value: "Salary" },
              ]}
              optionItemStyle=" "
              optionInputStyle=" "
              containerStyle="d-flex align-items-center justify-content-between mt-2 mb-3"
              optionsContainerStyle="d-flex gap-10"
              dependOn="setMinDailyHrs"
              dependancyType="equal"
              dependancyValue={[1]}
            />
          </div>

          <div className="options_container">
            <span className="options_container_title">{t("Overtime")}</span>

            <CheckboxBooleanForm
              {...FormProps}
              name="isCalcOvertime"
              containerStyle="my-3"
              options={["Calculate Overtime hours"]}
              dependOn="setRestrictions"
              dependancyType="equal"
              dependancyValue={[1]}
            />
            <InputForm
              {...FormProps}
              name="hoursPerDay"
              label="Normal Work hours Per day"
              containerStyle="d-flex align-items-center justify-content-between mb-2"
              dependOn="isCalcOvertime"
              dependancyType="equal"
              dependancyValue={[1]}
              validateBy={"number_req_non_negative"}
              hasSuffix
              suffixTitle="hours"
              validationName={"input.daily_workday_hours"}
            />
            <InputForm
              {...FormProps}
              name="overtimeAfter"
              label="Calculate Overtime after normal Hrs by"
              containerStyle="d-flex align-items-center justify-content-between"
              dependOn="isCalcOvertime"
              dependancyType="equal"
              dependancyValue={[1]}
              validateBy={"number_req_non_negative"}
              hasSuffix
              suffixTitle="minutes"
              validationName={"input.calculate_overtime_after"}
            />
            <div
              className={"d-flex justify-content-between align-items-center"}
            >
              <CheckboxBooleanForm
                {...FormProps}
                name="setOvertimeDayLimit"
                containerStyle="my-3"
                options={["Set overtime limit per day"]}
                dependOn="isCalcOvertime"
                dependancyType="equal"
                dependancyValue={[1]}
                validationName={"input.overtime_daily_limit"}
              />
              <InputForm
                {...FormProps}
                name="overtimeDayLimit"
                labelStyle="mr-2"
                dependOn="setOvertimeDayLimit"
                dependancyType="equal"
                dependancyValue={[1]}
                validateBy={"number_req_non_negative"}
                hasSuffix
                suffixTitle="hours"
              />
            </div>
            <div
              className={"d-flex justify-content-between align-items-center"}
            >
              <CheckboxBooleanForm
                {...FormProps}
                name="setMaxHrsPerDay"
                options={["Set maximum work hours per Day"]}
                dependOn="isCalcOvertime"
                dependancyType="equal"
                dependancyValue={[1]}
                validationName={"input.maximum_daily_work_hours"}
              />
              <InputForm
                {...FormProps}
                name="maxHrsPerDay"
                labelStyle="mr-2"
                dependOn="setMaxHrsPerDay"
                dependancyType="equal"
                dependancyValue={[1]}
                hasSuffix
                suffixTitle="hours"
                validateBy={"number_req_non_negative"}
              />
            </div>

            {formData?.setRestrictions &&
            formData?.isCalcOvertime &&
            formData?.setOvertimeDayLimit &&
            formData?.setMaxHrsPerDay ? (
              <RadioboxForm
                {...FormProps}
                name="treatExtraHoursAs"
                validateBy="textRequired"
                label="Treat the extra hours (more than the overtime limit) as"
                keepDefaultStyle
                options={treatExtraHoursAsOptions}
                // validationName={``}
                containerStyle="my-2"
                labelStyle="label-style font-weight-bold"
                optionItemStyle="mr-3"
                optionInputStyle=" "
                optionLabelStyle="optionLabelStyle"
              />
            ) : null}
          </div>
        </>
      ) : null}

      <div className="options_container">
        <span className="options_container_title">
          {t("facial recognition settings")}
        </span>

        <CheckboxBooleanForm
          {...FormProps}
          name="applyFacialRecognition"
          containerStyle="my-3"
          options={["Require Facial Recognition With clock in / out"]}
        />

        {formData?.applyFacialRecognition ? (
          <CheckboxBooleanForm
            {...FormProps}
            name="applyHomeFacialRecognition"
            containerStyle="my-3"
            options={[
              "Require Facial Recognition On Employees Working Remotely",
            ]}
          />
        ) : null}

        <CheckboxBooleanForm
          {...FormProps}
          name="employeeCanClockinInOffHours"
          containerStyle="my-3"
          options={["Employee can clockin in work hours off"]}
        />
      </div>
    </MainModal>
  );
};

export default WorkDayTemplateForm;

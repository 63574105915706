import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { syncFoodicsBranchesMutation } from "../../Graphql/mutation";
import { useTranslation } from "react-i18next";

import { showToast } from "../../Helpers/HelperFns";
import { paginatorFragment } from "../../Graphql/fragments";

import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import MainModal from "../../Components/MainModal";
import Pagination from "../../Components/Pagination";
import MultiMarkersMap from "../../Components/Maps/MultiMarkersMap";
import { PreviewIconButton } from "../../Components/IconButtonWithTooltip";
import { ColorButton } from "../../Components/Buttons";
import { Sync } from "@mui/icons-material";

const GET_BRANCHES = gql`
  query getBranches($first: Int, $page: Int) {
    branches: foodicsBranches(first: $first, page: $page) {
      data {
        id
        foodics_id
        name
        name_localized
        reference
        latitude
        longitude
        phone
        opening_from
        opening_to
        inventory_end_of_day_time
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${paginatorFragment}
`;
const modalInitState = { isOpen: false, data: null };
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const BranchesList = () => {
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState([]);
  const [modalState, setModalState] = React.useState(modalInitState);
  const [pagination, setPagination] = React.useState(paginationInitState);

  // Server State
  const { loading, refetch } = useQuery(GET_BRANCHES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination.perPage,
      page: pagination.currentPage,
    },
    onCompleted: ({ branches }) => {
      setData(branches?.data || []);
      setPagination(branches?.paginatorInfo);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = [
    { name: t("name"), selector: "name", grow: 2 },
    { name: t("reference"), selector: "reference" },
    { name: t("phone"), selector: "phone" },
    {
      name: t("location"),
      cell: (row) => (
        <PreviewIconButton
          label={t("view location")}
          onClick={() =>
            handleViewLocation(row?.name, row.latitude, row?.longitude)
          }
        />
      ),
    },
    { name: t("opening from"), selector: "opening_from" },
    { name: t("opening to"), selector: "opening_to" },
    {
      name: t("inventory end of day time"),
      selector: "inventory_end_of_day_time",
    },
  ];

  // Start of handle sync branches mutation function
  const [syncFoodicsBranches, { loading: syncBranchesIsLoading }] = useMutation(
    syncFoodicsBranchesMutation,
    {
      onCompleted: (res) => {
        if (res?.syncFoodicsBranches?.length > 0) {
          // refetch data list
          refetch();

          // success toaster
          showToast("success");
        } else {
          // error toaster
          showToast(
            "error",
            res?.graphQLErrors[0]?.extensions?.reason ||
              res?.graphQLErrors[0]?.message ||
              res?.message
          );
        }
      },
      onError: (err) => {
        console.log({ err });
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  const handleSyncBranches = () => {
    syncFoodicsBranches();
  };
  // End of handle sync branches mutation function

  /* ↓ Helpers ↓ */

  const handleViewLocation = (name, lat, lng) => {
    setModalState({
      isOpen: true,
      data: {
        markers: [{ name, label: name, position: [lat, lng] }],
      },
    });
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  return (
    <>
      {syncBranchesIsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      {/* sync btn */}
      <div className="d-flex justify-content-end my-4">
        <ColorButton
          color="#2764ac"
          onClick={handleSyncBranches}
          label={t("sync branches")}
          icon={<Sync fontSize="small" />}
          disabled={loading || syncBranchesIsLoading}
        />
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table mt-4"
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {modalState?.isOpen ? (
        <MainModal
          isOpen
          hasModalFooter={false}
          modalTitle={t("View Location")}
          toggle={() => setModalState(modalInitState)}
        >
          {modalState?.data?.markers?.length ? (
            <MultiMarkersMap markers={modalState?.data?.markers} />
          ) : null}
        </MainModal>
      ) : null}
    </>
  );
};

export default BranchesList;

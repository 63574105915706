import {
  ArrowDownward,
  ArrowUpward,
  ErrorOutline,
  GpsOff,
  InfoOutlined,
  LocationOn,
} from "@mui/icons-material";
import moment from "moment";
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  EditIconButton,
  InfoIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { AddButton } from "../../Components/Buttons";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DELETE_CLOCKIN } from "../../Graphql/mutation";
import { openSwalConfirm } from "../../Helpers/HelperFns";
import { useMutation } from "@apollo/client";
import { showErrorToast } from "../../Store/Actions";
import { useDispatch } from "react-redux";

const LEAVE_TYPES = {
  1: "normal leaves",
  6: "sick leaves",
  7: "unpaid leaves",
};

const ClockinCard = ({
  sign_in_order,
  clockIn,
  handleAddOrEditClockin = () => {},
  refetchTimeSheet,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [attemptDeleteClockin, { loading: deleteClockinLoading }] =
    useMutation(DELETE_CLOCKIN);

  const handleInfo = () => {};

  const handleEdit = () => {
    handleAddOrEditClockin(clockIn?.id);
  };

  const handleDelete = () => {
    openSwalConfirm((isConfirmed) => {
      if (isConfirmed) {
        attemptDeleteClockin({
          variables: {
            id: +clockIn?.id,
          },

          onCompleted: (data) => {
            if (
              data?.deleteClockIn?.__typename === "StatusResponse" &&
              data?.deleteClockIn?.status == "fail"
            ) {
              dispatch(
                showErrorToast(
                  data?.deleteClockIn?.message ?? "Something went wrong"
                )
              );
            }

            if (data?.deleteClockIn?.__typename === "Clockin") {
              refetchTimeSheet();
            }
          },
          onError: (error) => {
            dispatch(
              showErrorToast(
                error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
              )
            );
          },
        });
      }
    });
  };

  return (
    <div className="clockin-container">
      <p className="sign-in-order">{sign_in_order}</p>

      {!!clockIn?.leave_type ? (
        <div className="sign-out">
          {`${t("leave")} ${clockIn?.leave_hours} (${t(`${LEAVE_TYPES?.[clockIn?.leave_type] ?? "custom leaves"}`)})`}
        </div>
      ) : (
        <>
          <div className="sign-in">
            <div className="sign-in-time-container">
              <ArrowDownward className="sign_in_icon" />
              <p className="sign-in-time">
                {!!clockIn?.clock_in_time
                  ? moment(clockIn?.clock_in_time).format("hh:mm A")
                  : "--------"}
              </p>
            </div>

            <div className="sign-in-out-location-container">
              <LocationOn
                fontSize="small"
                className="sign-in-out-location-icon"
              />
              <p className="text-ellipsis sign-in-location">
                {clockIn?.clockInOffice?.name}
              </p>
            </div>
          </div>
          <div className="sign-out">
            <div className="sign-out-time-container">
              <ArrowUpward className="sign_out_icon" />
              <p className="sign-out-time">
                {!!clockIn?.clock_out_time
                  ? moment(clockIn?.clock_out_time).format("hh:mm A")
                  : "--------"}
              </p>
            </div>
            <div className="sign-in-out-location-container">
              <LocationOn
                fontSize="small"
                className="sign-in-out-location-icon"
              />
              <p className="text-ellipsis sign-out-location">
                {clockIn?.clockOutOffice?.name}
              </p>
            </div>
          </div>
          <p className="sign-in-out-interval">
            <AccessTimeIcon className="time-icon" />
            <p className="m-0">
              {/* {differenceInHours < 10 ? `0${differenceInHours}` : differenceInHours}{" "}
          :{" "}
          {differenceInMinutes < 10
            ? `0${differenceInMinutes}`
            : differenceInMinutes} */}
              {clockIn?.working_time}
            </p>
          </p>
          <p className="info-icon">
            {/* <Tooltip arrow placement="top" title={t("penalties")}>
          <InfoOutlined fontSize="small" color="inherit" />
        </Tooltip> */}
          </p>
          {/* <p className="checkins">2 check-ins</p> */}
        </>
      )}
      <div className="actions">
        {/* <InfoIconButton onClick={handleInfo} /> */}
        <EditIconButton onClick={handleEdit} />
        <RemoveIconButton
          disabled={deleteClockinLoading}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};

export default ClockinCard;

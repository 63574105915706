import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { LocationOffOutlined, Room } from "@mui/icons-material";
import {
  faCheckSquare,
  faPencilAlt,
  faSquare,
  faUserLock,
  faUser,
  faUsers,
  faBuilding,
  faCalendar,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import { RadioboxForm } from "form-builder";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Loader from "../../Components/Loader";
import { AddButton } from "../../Components/Buttons";
import { useQuery, gql } from "@apollo/client";
import { Button, Alert, AlertTitle } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChangeManagersModal from "./ChangeManagersModal";
import _ from "lodash";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import { Link } from "react-router-dom";
import WorkRemotelyModal from "./WorkRemotelyModal";
import PosDepModal from "./PosDepModal";
import BatchActionModal from "./BatchActionModal";
import DayOffModal from "./DayOffModal";
import Tooltip from "@mui/material/Tooltip";
import ChangeCheckInsModal from "./ChangeCheckInsModal";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Edit from "@mui/icons-material/Edit";
import VerifyActivate from "./VerifyActivate";
import AddResetBalanceModal from "./AddResetBalanceModal";
import EditBulkAttendance from "./EditBulkAttendance";
import AddIcon from "@mui/icons-material/Add";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import HolidayModal from "./HolidayModal";
import EditWorkTimePreview from "../../Components/AttProfileSections/EditWorkTimePreview";
import FiltersDrawer, { FilterCheckBox } from "../../Components/FiltersDrawer";
import ClearIcon from "@mui/icons-material/Clear";
import {
  onFormResetAction,
  updateValueAction,
  toggleAddGraceMinutesModal,
} from "../../Store/Actions";
import NewMultiSelect from "../../Components/FiltersDrawer/NewMultiSelect";
import FilterBadge from "../../Components/FilterBadge";
import { EmployeeTypes } from "../../Constants";
import AddGraceMinutesModal from "./AddGraceMinutesModal";
import AddBulkLeaveBreakProfile from "./AddBulkLeaveBreakProfile";
import RequestLimitConfig from "../../Components/AttendanceTypeModal/RequestLimitConfig";
import RequsetLimitConfigModal from "./RequsetLimitConfigModal";

const dataTableRef = `batchEmployeesActions`;

const EMPLOYESS_QUERY = gql`
  query getData {
    work_groups_menu: mixed_work_groups_i_manage {
      fixed {
        id
        name
      }
      flex {
        id
        name
      }
    }
    managers {
      id
      name
    }
    company_offices(first: 9999) {
      data {
        id
        name
      }
    }
    company_positions(first: 9999) {
      data {
        id
        name
      }
    }
    company_departments(first: 9999) {
      data {
        id
        name
      }
    }
    users_by_role(input: { status: ["Active"] }, page: 1) {
      data {
        id
        name
        phone
        emp_code
        employee {
          ... on Employee {
            __typename
            on_probation
            relevantAttendanceTypeConfiguration {
              type
              __typename
              workGroup {
                id
              }
            }
          }
          ... on FlexEmployee {
            __typename
            on_probation
            flexRelevantAttendanceTypeConfiguration {
              type
              __typename
              flexWorkGroup {
                id
              }
            }
          }
        }
        department {
          id
          name
        }
        position {
          id
          name
        }
        office {
          id
          name
        }
        manager {
          id
          name
        }
        copied_managers {
          id
          name
        }
      }
    }
  }
`;

const formatMixedOptions = (options = []) => {
  const flex = options?.flex || [],
    fixed = options?.fixed || [];
  const formatOptions = (opt, type) => ({
    ...opt,
    type,
    id: `${type} - ${opt.id}`,
    name: `${opt.name} - ${type}`,
  });

  return [
    ...flex.map((opt) => formatOptions(opt, "flex")),
    ...fixed.map((opt) => formatOptions(opt, "fixed")),
  ];
};

const modalInitState = {
  isOpen: false,
  isFlex: false,
};
const ViewEmployeeActions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [managersModal, setManagersModal] = useState({
    isOpen: false,
    isDirectManager: false,
  });
  const batchFilter = useSelector(
    (state) => state?.super?.batchEmployeesActions
  );
  const auth = useSelector((state) => state?.auth?.userProfile);
  const adminUserId = useSelector((state) => state?.auth?.userProfileRef);

  const isAddGraceMinutesModalOpen = useSelector(
    (state) => state?.super?.addGraceMinutesModal?.isOpen
  );

  const [applyFilters, setApplyFilters] = useState(false);
  const [allEmployeesData, setAllEmployeesData] = useState([]);
  const [changeDayOffSettings, setChangeDayOffSettings] =
    useState(modalInitState);
  const [changeHolidaySettings, setChangeHolidaySettings] =
    useState(modalInitState);
  const [workRemotelyModal, setWorkRemotelyModal] = useState({
    isOpen: false,
  });

  const [posDepModal, setPosDepModal] = useState({
    isOpen: false,
    isDepartment: false,
  });
  const [checkInsModal, setCheckInsModal] = useState(false);
  const [isAddBulkLeaveBreakProfile, setIsAddBulkLeaveBreakProfile] =
    useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsFilterDrawerOpen(open);
  };

  const onFilterReset = () => {
    dispatch(onFormResetAction(dataTableRef));
  };

  const onFilterSubmit = () => {
    setApplyFilters((prev) => !prev);
    setIsFilterDrawerOpen(false);
  };

  const columns = [
    {
      id: "action",
      cell: (row) =>
        selectedEmployees?.includes(row?.id) ? (
          <FontAwesomeIcon
            role="button"
            size="lg"
            color="#2764ac"
            onClick={() => handleRowSelection(row?.id)}
            icon={faCheckSquare}
          />
        ) : (
          <FontAwesomeIcon
            role="button"
            size="lg"
            color="#eee"
            onClick={() => handleRowSelection(row?.id)}
            icon={faSquare}
          />
        ),
      button: true,
      width: "40px",
    },
    {
      id: "name",
      name: t("name"),
      grow: 1.25,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.manager?.id}
          copiedManagers={row?.copied_managers?.map((cp) => cp?.id)}
          altChildren={<div className="col pl-0">{row?.name}</div>}
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.id}`}
            >
              {row?.name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      id: "office",
      name: t("office"),
      cell: (row) => row.office?.name,
      grow: 1,
    },
    {
      id: "department",
      name: t("department"),
      cell: (row) => row.department?.name,
      grow: 1,
    },
    {
      id: "Position",
      name: t("position"),
      cell: (row) => row.position?.name,
      grow: 1,
    },
    {
      id: "Direct Manager",
      name: t("direct manager"),
      cell: (row) => row?.manager?.name,
      grow: 1,
    },
    {
      id: "Copied-Managers",
      name: t("copied manager"),
      grow: 2.25,
      cell: (row) => row.copied_managers?.map((cp) => cp?.name)?.join(", "),
      allowOverFlow: true,
    },
    {
      id: "Attendance-Type",
      name: t("attendance type"),
      grow: 1,
      cell: (row) =>
        row.employee.__typename === "FlexEmployee"
          ? row.employee?.flexRelevantAttendanceTypeConfiguration?.type
          : t(row.employee?.relevantAttendanceTypeConfiguration?.type),
      allowOverFlow: true,
    },
  ];

  const [data, setData] = useState({});
  const { loading, error, refetch } = useQuery(EMPLOYESS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ work_groups_menu, ...data }) => {
      setData({
        ...data,
        work_groups_menu: formatMixedOptions(work_groups_menu),
        work_groups_ref: formatMixedOptions(work_groups_menu),
      });
    },
  });

  useEffect(() => {
    let filteredEmployees = _.filter(data?.users_by_role?.data, (employee) =>
      _.every(batchFilter, (value, key) => {
        if (
          value === null ||
          value === "" ||
          value == false ||
          value?.length === 0
        ) {
          return true; // Ignore filters with falsey values
        }

        // Perform filter by each filter key
        switch (key) {
          case "empType":
            if (value === "all") return true;
            return employee.employee.__typename === value;
          case "employees":
            return value?.includes(employee.id);
          case "offices":
            return value?.includes(employee.office?.id);
          // return employee.office?.id == value;
          case "departments":
            return value?.includes(employee.department?.id);
          // return employee.department?.id == value;
          case "positions":
            return value?.includes(employee.position?.id);
          // return employee.position?.id == value;
          case "filterByManager":
            let empMangers = [
              employee.manager?.id,
              ...employee?.copied_managers.map((cp) => cp?.id),
            ];
            return value?.some((id) => empMangers?.includes(id));
          // return employee.manager?.id == value || employee?.copied_managers.map((cp) => cp?.id)?.includes(value);
          case "showManagedEmployeesFilter":
            return (
              employee.manager?.id == auth?.id ||
              employee?.copied_managers?.map((cp) => cp?.id)?.includes(auth?.id)
            );
          case "directManaged":
            return employee.manager?.id == auth?.id;
          case "on_probation":
            return employee.employee?.on_probation;
          case "filterByWorkgroup":
            return value?.some(
              (val) =>
                (employee?.employee?.__typename === "FlexEmployee" &&
                  val ===
                    `flex - ${employee?.employee?.flexRelevantAttendanceTypeConfiguration?.flexWorkGroup?.id}`) ||
                (employee?.employee?.__typename === "Employee" &&
                  val ===
                    `fixed - ${employee?.employee?.relevantAttendanceTypeConfiguration?.workGroup?.id}`)
            );
          case "filterByAttType":
            return employee?.employee?.__typename === "FlexEmployee"
              ? employee.employee?.flexRelevantAttendanceTypeConfiguration
                  ?.type == value
              : employee.employee?.relevantAttendanceTypeConfiguration?.type ==
                  value;

          default:
            return true;
        }
      })
    );

    setAllEmployeesData(filteredEmployees);

    return () => {};
  }, [batchFilter, data?.users_by_role?.data, isFilterDrawerOpen]);

  const isOfficeBasedUsersSelected = () => {
    let officeBasedEmployee = data?.users_by_role?.data?.findIndex(
      (emp) =>
        selectedEmployees?.includes(emp?.id) &&
        emp.employee?.relevantAttendanceTypeConfiguration?.type ==
          "office based"
    );
    if (officeBasedEmployee > -1) {
      return true;
    } else {
      return false;
    }
  };

  const isFlexOnlyOrNoFlex = () => {
    let flexEmployee = data?.users_by_role?.data?.findIndex(
      (emp) =>
        selectedEmployees?.includes(emp?.id) &&
        emp.employee?.__typename === "FlexEmployee"
    );
    let nonFlexEmployee = data?.users_by_role?.data?.findIndex(
      (emp) =>
        selectedEmployees?.includes(emp?.id) &&
        emp.employee?.__typename === "Employee"
    );
    if (flexEmployee > -1 && nonFlexEmployee > -1) {
      return false;
    } else if (flexEmployee > -1) {
      return "FlexEmployee";
    } else {
      return "Employee";
    }
  };
  const isFixedEmployeesOnly = () => {
    let flexEmployee = data?.users_by_role?.data?.findIndex(
      (emp) =>
        selectedEmployees?.includes(emp?.id) &&
        emp.employee?.__typename === "FlexEmployee"
    );

    if (flexEmployee > -1) {
      return false;
    }
    return true;
  };
  //start Menu

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, type) => {
    if (type) {
      setBatchActions({
        type,
        isOpen: true,
      });
    }
    setAnchorEl(null);
  };
  //end Menu

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const handleRowSelection = (id, e) => {
    if (selectedEmployees?.includes(id)) {
      setSelectedEmployees((prevState) =>
        prevState?.filter((row) => row !== id)
      );
    } else {
      setSelectedEmployees((prevState) => [...prevState, id]);
    }
  };

  const resetSelection = () => {
    setSelectedEmployees([]);
  };

  const [batchActions, setBatchActions] = useState({
    isOpen: false,
    type: null,
  });

  const onDismiss = () => {
    setBatchActions({
      isOpen: false,
      type: null,
    });
  };

  const openManagersModal = (isDirectManager) => {
    setAnchorEl(null);
    setManagersModal({
      isOpen: true,
      isDirectManager,
    });
  };
  const CloseManagersModal = (isDirectManager) => {
    setManagersModal({
      isOpen: false,
      isDirectManager: false,
    });
  };

  const openWorkRemotelyModal = () => {
    setAnchorEl(null);
    setWorkRemotelyModal({
      isOpen: true,
    });
  };

  const closeWorkRemotelyModal = () => {
    setWorkRemotelyModal({
      isOpen: false,
    });
  };

  const [requestLimitConfigModal, setRequestLimitConfigModal] = useState({
    isOpen: false,
  });

  const openRequestLimitConfigModal = () => {
    setAnchorEl(null);
    setRequestLimitConfigModal({
      isOpen: true,
    });
  };

  const closeRequestLimitConfigModal = () => {
    setRequestLimitConfigModal({
      isOpen: false,
    });
  };

  const openPDModal = (isDepartment) => {
    setAnchorEl(null);
    setPosDepModal({
      isOpen: true,
      isDepartment: isDepartment,
    });
  };

  const closePDModal = () => {
    setPosDepModal({
      isOpen: false,
      isDepartment: false,
    });
  };

  const CloseDayOffModal = () => setChangeDayOffSettings(modalInitState);
  const closeHolidayModal = () => setChangeHolidaySettings(modalInitState);
  const openCheckInsModal = () => {
    setAnchorEl(null);
    setCheckInsModal(true);
  };
  const closeCheckInsModal = () => {
    setCheckInsModal(false);
  };

  const [verifyActivate, setVerifyActivate] = useState(false);
  const handleVerifyActivate = () => {
    setAnchorEl(null);
    setVerifyActivate(true);
  };

  const closeVerifyActivate = () => {
    setVerifyActivate(false);
  };

  const [addResetBalanceModal, setaddResetBalanceModal] = useState({
    isOpen: false,
    isAddBalance: null,
  });

  const openAddResetBalanceModal = (isAddBalance) => {
    setAnchorEl(null);
    setaddResetBalanceModal({ isOpen: true, isAddBalance });
  };
  const closeAddResetBalanceModal = () => {
    setaddResetBalanceModal({ isOpen: false, isAddBalance: null });
  };

  const [editBulkAttendanceModal, setEditBulkAttendanceModal] =
    useState(modalInitState);

  const openEditBulkAttendanceModal = (isFlex = false) => {
    setAnchorEl(null);
    setEditBulkAttendanceModal({
      isOpen: true,
      isFlex: isFlexOnlyOrNoFlex() === "FlexEmployee",
    });
  };
  const closeEditBulkAttendanceModal = () => {
    setEditBulkAttendanceModal(modalInitState);
  };

  // handle toggle add grace minutes modal function
  const handleToggleAddGraceMiuntesModal = () => {
    // dismiss the bulk actions modal
    setAnchorEl(null);

    dispatch(toggleAddGraceMinutesModal({ isOpen: true }));
  };

  const handleAddLeaveBreakProfileModal = () => {
    // dismiss the bulk actions modal
    setAnchorEl(null);

    setIsAddBulkLeaveBreakProfile(!isAddBulkLeaveBreakProfile);
  };

  const [clearOrCancel, setClearOrCancel] = useState(false);

  useEffect(() => {
    if (clearOrCancel) {
      setApplyFilters((prev) => !prev);
      setClearOrCancel(false);
    }
  }, [batchFilter]);

  const renderAppliedFilters = () => {
    const selectedEmp = data?.users_by_role?.data?.filter((emp) =>
      batchFilter?.employees?.includes(emp.id)
    );

    const selectedPositions = data?.company_positions?.data?.filter((pos) =>
      batchFilter?.positions?.includes(pos.id)
    );

    const selectedDepartments = data?.company_departments?.data?.filter((dep) =>
      batchFilter?.departments?.includes(dep?.id)
    );

    const selectedOffices = data?.company_offices?.data?.filter((off) =>
      batchFilter?.offices?.includes(off.id)
    );

    const selectedWG = data?.work_groups_menu?.filter((wg) =>
      batchFilter?.filterByWorkgroup?.includes(wg.id)
    );

    const selectedManagers = data?.managers?.filter((mg) =>
      batchFilter?.filterByManager?.includes(mg?.id)
    );

    const selectedAttType = batchFilter?.filterByAttType
      ? [batchFilter?.filterByAttType]?.map((at) => {
          if (at === "office based") {
            return { name: t("office based") };
          }
          return { name: t("shift based") };
        })
      : [];

    const managed = batchFilter?.showManagedEmployeesFilter ? [{}] : [];
    const directlyManaged = batchFilter?.directManaged ? [{}] : [];
    const probation = batchFilter?.on_probation ? [{}] : [];

    const cancel = (name, emptyVal) => {
      dispatch(updateValueAction(dataTableRef, name, emptyVal));
      setClearOrCancel(true);
    };

    return (
      <div className="d-flex flex-wrap gap-1">
        <FilterBadge
          label={t("employees")}
          selectedItems={selectedEmp}
          onCancelFilter={() => cancel("employees", [])}
        />
        <FilterBadge
          label={t("positions")}
          selectedItems={selectedPositions}
          onCancelFilter={() => cancel("positions", [])}
        />
        <FilterBadge
          label={t("departments")}
          selectedItems={selectedDepartments}
          onCancelFilter={() => cancel("departments", [])}
        />
        <FilterBadge
          label={t("offices")}
          selectedItems={selectedOffices}
          onCancelFilter={() => cancel("offices", [])}
        />
        <FilterBadge
          label={t("work groups")}
          selectedItems={selectedWG}
          onCancelFilter={() => cancel("filterByWorkgroup", [])}
        />
        <FilterBadge
          label={t("managers")}
          selectedItems={selectedManagers}
          onCancelFilter={() => cancel("filterByManager", [])}
        />
        <FilterBadge
          label={t("attendance type")}
          selectedItems={selectedAttType}
          onCancelFilter={() => cancel("filterByAttType", null)}
        />
        <FilterBadge
          label={t("managed")}
          selectedItems={managed}
          onCancelFilter={() => cancel("showManagedEmployeesFilter", false)}
        />
        <FilterBadge
          label={t("direct Managed")}
          selectedItems={directlyManaged}
          onCancelFilter={() => cancel("directManaged", false)}
        />
        <FilterBadge
          label={t("on probation")}
          selectedItems={probation}
          onCancelFilter={() => cancel("on_probation", false)}
        />
      </div>
    );
  };

  const appliedFiltersLength =
    (batchFilter?.employees?.length ?? 0) +
    (batchFilter?.positions?.length ?? 0) +
    (batchFilter?.offices?.length ?? 0) +
    (batchFilter?.departments?.length ?? 0) +
    (batchFilter?.filterByAttType ? 1 : 0) +
    (batchFilter?.filterByManager?.length ?? 0) +
    (batchFilter?.filterByWorkgroup?.length ?? 0) +
    (batchFilter?.directManaged ? 1 : 0) +
    (batchFilter?.showManagedEmployeesFilter ? 1 : 0) +
    (batchFilter?.on_probation ? 1 : 0);

  const handelClearFilters = () => {
    onFilterReset();
    setClearOrCancel(true);
  };

  return (
    <div className="hr-employee_content_wrapper_style">
      {loading ? (
        <div className="position-absolute w-100 d-flex justify-content-center ">
          <Loader />
        </div>
      ) : null}

      {/* Filters */}
      <div
        className={`d-flex w-100 align-items-center my-3 gap-1 ${
          appliedFiltersLength
            ? "justify-content-between"
            : "justify-content-end"
        }`}
      >
        {renderAppliedFilters()}

        <div className="text-right d-flex gap-1">
          {appliedFiltersLength ? (
            <div onClick={handelClearFilters} className="logs-btn clear-btn">
              <ClearIcon color="inherit" fontSize="small" />
              {t("clear")}
            </div>
          ) : null}

          <div
            onClick={toggleDrawer(true)}
            className={`logs-btn ${
              appliedFiltersLength ? "filter-btn-active" : "filter-btn-inactive"
            }`}
          >
            <FontAwesomeIcon icon={faSlidersH} />
            {t("filters")}
            {appliedFiltersLength ? (
              <div className="filter-count-container">
                {t(appliedFiltersLength)}
              </div>
            ) : null}
          </div>
          <HasPrivileges
            allowBP
            reqireMain={[
              Privilages.EDIT_USER_PRIVILEGE,
              Privilages.ADD_EDIT_ATTENDANCE_TYPE_CONFIGURATION,
              Privilages.BULK_EMPLOYEE_LEAVE_BREAK_PROFILE,
              Privilages.ADD_EDIT_DELETE_EMPLOYEE_GRACE_MINUTES,
              Privilages.VERIFY_PHONE_EMAIL,
              Privilages.ADD_DEDUCT_LEAVE_BALANCE,
              Privilages.ADD_EDIT_DELETE_EMPLOYEES,
            ]}
          >
            <AddButton
              label={t("Bulk Actions")}
              prefix={""}
              icon={<FontAwesomeIcon className="mx-2" icon={faPencilAlt} />}
              disabled={!selectedEmployees?.length}
              disabledLabel={t("select employees")}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="text-nowrap"
            />
          </HasPrivileges>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={(e) => handleClose(e, null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <HasPrivileges
              allowBP
              reqireMain={[Privilages.EDIT_USER_PRIVILEGE]}
            >
              <MenuItem onClick={(e, val) => handleClose(e, "ACCESS_LEVEL")}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <FontAwesomeIcon className="mx-2" icon={faUserLock} />
                  <span className="m-1">{t("Assign Access Level")}</span>
                </div>
              </MenuItem>
            </HasPrivileges>
            <HasPrivileges
              allowBP
              reqireMain={[Privilages.ADD_EDIT_ATTENDANCE_TYPE_CONFIGURATION]}
            >
              <>
                <MenuItem onClick={openRequestLimitConfigModal}>
                  <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                    <FontAwesomeIcon className="mx-2" icon={faUserLock} />
                    <span className="m-1">
                      {t("Change Requset Limit Config")}
                    </span>
                  </div>
                </MenuItem>
                {isOfficeBasedUsersSelected() ||
                isFlexOnlyOrNoFlex() === false ? (
                  <>
                    <Tooltip
                      title={
                        <span>
                          {t("employees must be shift based only or flexiable")}
                        </span>
                      }
                      arrow
                    >
                      <span>
                        <MenuItem disabled>
                          <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                            <FontAwesomeIcon
                              className="mx-2"
                              icon={faCalendar}
                            />
                            <span className="m-1">
                              {t("Change Day off settings")}
                            </span>
                          </div>
                        </MenuItem>
                      </span>
                    </Tooltip>

                    <Tooltip
                      title={<span>employees must be shift based only</span>}
                      arrow
                    >
                      <span>
                        <MenuItem disabled>
                          <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                            <FontAwesomeIcon
                              className="mx-2"
                              icon={faCalendar}
                            />
                            <span className="m-1">
                              {t("Change Holiday settings")}
                            </span>
                          </div>
                        </MenuItem>
                      </span>
                    </Tooltip>

                    <Tooltip
                      title={
                        <span>{t("employees must be shift based only")}</span>
                      }
                      arrow
                    >
                      <span>
                        <MenuItem disabled>
                          <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                            <Room className="mx-2" />
                            <span className="m-1">
                              {t("Change Check-ins settings")}
                            </span>
                          </div>
                        </MenuItem>
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setChangeDayOffSettings({
                          isOpen: true,
                          isFlex: isFlexOnlyOrNoFlex() === "FlexEmployee",
                        });
                      }}
                      disabled={
                        isOfficeBasedUsersSelected() ||
                        isFlexOnlyOrNoFlex() === false
                      }
                    >
                      <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                        <FontAwesomeIcon className="mx-2" icon={faCalendar} />
                        <span className="m-1">
                          {t("Change Day off settings")}
                        </span>
                      </div>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setChangeHolidaySettings({
                          isOpen: true,
                          isFlex: isFlexOnlyOrNoFlex() === "FlexEmployee",
                        });
                      }}
                      disabled={
                        isOfficeBasedUsersSelected() ||
                        isFlexOnlyOrNoFlex() === false
                      }
                    >
                      <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                        <FontAwesomeIcon className="mx-2" icon={faCalendar} />
                        <span className="m-1">
                          {t("Change Holiday settings")}
                        </span>
                      </div>
                    </MenuItem>

                    <MenuItem
                      onClick={openCheckInsModal}
                      disabled={isOfficeBasedUsersSelected()}
                    >
                      <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                        <Room className="mx-2" />
                        <span className="m-1">
                          {t("Change Check-ins settings")}
                        </span>
                      </div>
                    </MenuItem>
                  </>
                )}
              </>
            </HasPrivileges>

            <HasPrivileges
              allowBP
              reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
            >
              <MenuItem onClick={() => openPDModal(true)}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <FontAwesomeIcon className="mx-2" icon={faBuilding} />
                  <span className="m-1">{t("change department")}</span>
                </div>
              </MenuItem>

              <MenuItem onClick={() => openPDModal(false)}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <PersonOutlineIcon className="mx-2" />
                  <span className="m-1">{t("change position")}</span>
                </div>
              </MenuItem>

              <MenuItem onClick={openWorkRemotelyModal}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <LocationOffOutlined className="mx-2" />
                  <span className="m-1">
                    {t("Change The Remote work settings")}
                  </span>
                </div>
              </MenuItem>
              <MenuItem onClick={() => openManagersModal(true)}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <FontAwesomeIcon className="mx-2" icon={faUser} />
                  <span className="m-1">{t("Change Direct Manager")}</span>
                </div>
              </MenuItem>

              <MenuItem onClick={() => openManagersModal(false)}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <FontAwesomeIcon className="mx-2" icon={faUsers} />
                  <span className="m-1">{t("Change Copied managers")}</span>
                </div>
              </MenuItem>
            </HasPrivileges>

            <HasPrivileges
              allowBP
              reqireMain={[Privilages.ADD_DEDUCT_LEAVE_BALANCE]}
            >
              {isFlexOnlyOrNoFlex() ? (
                <>
                  <MenuItem onClick={() => openAddResetBalanceModal(true)}>
                    <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                      <AddIcon className="mx-2" />
                      <span className="m-1">{t("Add Balance")}</span>
                    </div>
                  </MenuItem>

                  <MenuItem onClick={() => openAddResetBalanceModal(false)}>
                    <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                      <RefreshOutlinedIcon className="mx-2" />
                      <span className="m-1">{t("reset balance")}</span>
                    </div>
                  </MenuItem>
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      <span>
                        {t(
                          "You cannot update the balance for both flex and non-flex employees at the same time."
                        )}
                      </span>
                    }
                    arrow
                  >
                    <span>
                      <MenuItem disabled>
                        <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                          <AddIcon className="mx-2" />
                          <span className="m-1">{t("Add Balance")}</span>
                        </div>
                      </MenuItem>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span>
                        {t(
                          "You cannot update the balance for both flex and non-flex employees at the same time."
                        )}
                      </span>
                    }
                    arrow
                  >
                    <span>
                      <MenuItem disabled>
                        <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                          <RefreshOutlinedIcon className="mx-2" />
                          <span className="m-1">{t("reset balance")}</span>
                        </div>
                      </MenuItem>
                    </span>
                  </Tooltip>
                </>
              )}
            </HasPrivileges>

            <HasPrivileges allowBP reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}>
              <MenuItem onClick={() => handleVerifyActivate()}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <HowToRegIcon className="mx-2" />
                  <span className="m-1">{t("Verify / Activate")}</span>
                </div>
              </MenuItem>
            </HasPrivileges>

            <HasPrivileges
              allowBP
              reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEE_GRACE_MINUTES]}
            >
              <MenuItem onClick={handleToggleAddGraceMiuntesModal}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <AddIcon className="mx-2" />
                  <span className="m-1">{t("add grace minutes")}</span>
                </div>
              </MenuItem>
            </HasPrivileges>
            <HasPrivileges
              allowBP
              reqireMain={[Privilages.BULK_EMPLOYEE_LEAVE_BREAK_PROFILE]}
            >
              <MenuItem onClick={handleAddLeaveBreakProfileModal}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <AddIcon className="mx-2" />
                  <span className="m-1">{t("add Leave Break Profile")}</span>
                </div>
              </MenuItem>
            </HasPrivileges>

            {!_.isEmpty(adminUserId) ? (
              <HasPrivileges allowBP>
                {isFlexOnlyOrNoFlex() === false ? (
                  <>
                    <Tooltip
                      title={
                        <span>
                          {t(
                            "employees must be fixed based only or flexiable only"
                          )}
                        </span>
                      }
                      arrow
                    >
                      <span>
                        <MenuItem disabled>
                          <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                            <Edit className="mx-2" />
                            <span className="m-1">{t("edit attendance")}</span>
                          </div>
                        </MenuItem>
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  <MenuItem onClick={openEditBulkAttendanceModal}>
                    <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                      <Edit className="mx-2" />
                      <span className="m-1">{t("edit attendance")}</span>
                    </div>
                  </MenuItem>
                )}
              </HasPrivileges>
            ) : null}
          </Menu>
        </div>
      </div>

      <Alert
        variant="standard"
        action={
          <>
            <Button
              color="info"
              size="medium"
              onClick={() =>
                setSelectedEmployees([
                  ...new Set([
                    ...selectedEmployees,
                    ...allEmployeesData?.map((emp) => emp?.id),
                  ]),
                ])
              }
            >
              {t("select all")}
            </Button>
            {selectedEmployees?.length ? (
              <Button
                color="info"
                size="medium"
                onClick={() => setSelectedEmployees([])}
              >
                {t("reset")}
              </Button>
            ) : null}
          </>
        }
      >
        <AlertTitle>
          {t("employees selected", { count: selectedEmployees?.length })}
        </AlertTitle>
      </Alert>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        title="employees"
        responsive
        noHeader
        columns={columns}
        data={allEmployeesData}
        pagination
        dense
        paginationPerPage={50}
        paginationRowsPerPageOptions={[50, 100, 150, 250, 400]}
        paginationComponentOptions={{
          rowsPerPageText: t("employees per page"),
          rangeSeparatorText: t("of"),
          selectAllRowsItem: true,
          selectAllRowsItemText: t("All"),
        }}
        direction={document.body?.dir?.toLowerCase()}
      />

      {/* Modals */}
      {batchActions?.isOpen ? (
        <BatchActionModal
          resetSelection={resetSelection}
          selectedEmployees={selectedEmployees}
          batchActions={batchActions}
          dismiss={onDismiss}
        />
      ) : null}
      {managersModal.isOpen && (
        <ChangeManagersModal
          resetSelection={resetSelection}
          refetch={refetch}
          managersModal={managersModal}
          selectedEmployees={selectedEmployees}
          CloseManagersModal={CloseManagersModal}
        />
      )}

      {workRemotelyModal && (
        <WorkRemotelyModal
          workRemotelyModal={workRemotelyModal}
          closeWorkRemotelyModal={closeWorkRemotelyModal}
          selectedEmployees={selectedEmployees}
          refetch={refetch}
          resetSelection={resetSelection}
        />
      )}
      {requestLimitConfigModal && (
        <RequsetLimitConfigModal
          requestLimitConfigModal={requestLimitConfigModal}
          closeRequestLimitConfigModal={closeRequestLimitConfigModal}
          selectedEmployees={selectedEmployees}
          resetSelection={resetSelection}
        />
      )}
      {posDepModal?.isOpen && (
        <PosDepModal
          posDepModal={posDepModal}
          closePDModal={closePDModal}
          resetSelection={resetSelection}
          selectedEmployees={selectedEmployees}
          refetch={refetch}
        />
      )}

      {changeDayOffSettings?.isOpen && (
        <DayOffModal
          changeDayOffSettings={changeDayOffSettings?.isOpen}
          isFlex={changeDayOffSettings?.isFlex}
          resetSelection={resetSelection}
          refetch={refetch}
          CloseDayOffModal={CloseDayOffModal}
          selectedEmployees={selectedEmployees}
        />
      )}

      {changeHolidaySettings?.isOpen && (
        <HolidayModal
          changeHolidaySettings={changeHolidaySettings?.isOpen}
          isFlex={changeHolidaySettings?.isFlex}
          resetSelection={resetSelection}
          refetch={refetch}
          closeHolidayModal={closeHolidayModal}
          selectedEmployees={selectedEmployees}
        />
      )}

      {checkInsModal && (
        <ChangeCheckInsModal
          isOpen={checkInsModal}
          resetSelection={resetSelection}
          refetch={refetch}
          close={closeCheckInsModal}
          selectedEmployees={selectedEmployees}
        />
      )}

      {verifyActivate && (
        <VerifyActivate
          isOpen={verifyActivate}
          resetSelection={resetSelection}
          refetch={refetch}
          close={closeVerifyActivate}
          selectedEmployees={selectedEmployees}
        />
      )}

      {addResetBalanceModal.isOpen && (
        <AddResetBalanceModal
          isOpen={addResetBalanceModal.isOpen}
          resetSelection={resetSelection}
          refetch={refetch}
          close={closeAddResetBalanceModal}
          selectedEmployees={selectedEmployees}
          isAddBalance={addResetBalanceModal.isAddBalance}
          isForFlexEmployees={isFlexOnlyOrNoFlex() === "FlexEmployee"}
        />
      )}

      {editBulkAttendanceModal?.isOpen && (
        <>
          <EditBulkAttendance
            dismiss={closeEditBulkAttendanceModal}
            isOpen={editBulkAttendanceModal?.isOpen}
            isFlex={editBulkAttendanceModal?.isFlex}
            resetSelection={resetSelection}
            refetch={refetch}
            selectedEmployees={selectedEmployees}
          />
        </>
      )}

      {/* Start of add grace minutes modal */}
      {isAddGraceMinutesModalOpen ? (
        <AddGraceMinutesModal
          resetSelection={resetSelection}
          refetch={refetch}
          selectedEmployees={selectedEmployees}
        />
      ) : null}
      {/* End of add grace minutes modal */}

      {/* Start of add Leave Break Profile modal */}
      {isAddBulkLeaveBreakProfile ? (
        <AddBulkLeaveBreakProfile
          isOpen={isAddBulkLeaveBreakProfile}
          dismiss={handleAddLeaveBreakProfileModal}
          resetSelection={resetSelection}
          refetch={refetch}
          selectedEmployees={selectedEmployees}
        />
      ) : null}
      {/* End of add Leave Break Profile modal */}

      <EditWorkTimePreview />

      <FiltersDrawer
        isFilterDrawerOpen={isFilterDrawerOpen}
        toggleDrawer={toggleDrawer}
        onFilterReset={onFilterReset}
        onFilterSubmit={onFilterSubmit}
        dataTableRef={dataTableRef}
        isLoading={loading}
        formData={batchFilter}
        employeesOptions={data?.users_by_role?.data}
        officesOptions={data?.company_offices?.data}
        positionsOptions={data?.company_positions?.data}
        departmentsOptions={data?.company_departments?.data}
        hideFromTo
      >
        <NewMultiSelect
          options={data?.work_groups_menu}
          value={batchFilter?.filterByWorkgroup}
          dataTableRef={dataTableRef}
          name="filterByWorkgroup"
          label={t("work group")}
          icon="people"
          placeHolder={t("select workgroup")}
          optionLabel="name"
          optionValue="id"
          isLoading={loading}
        />

        <NewMultiSelect
          options={data?.managers}
          isLoading={loading}
          value={batchFilter?.filterByManager}
          dataTableRef={dataTableRef}
          name="filterByManager"
          label={t("managers")}
          icon="person"
          placeHolder={t("select manager")}
          optionLabel="name"
          optionValue="id"
        />

        <NewMultiSelect
          options={[
            { name: t("office based"), id: "office based" },
            { name: t("shift based"), id: "shifts" },
            { name: "flexible hours", id: "flexible hours" },
          ]}
          isLoading={loading}
          value={batchFilter?.filterByAttType}
          dataTableRef={dataTableRef}
          name="filterByAttType"
          label={t("attendance type")}
          icon="person"
          placeHolder={t("select attendance type")}
          isSingle
          optionLabel="name"
          optionValue="id"
        />

        <RadioboxForm
          name="empType"
          formName={dataTableRef}
          validateBy="textRequired"
          label="Employee type"
          keepDefaultStyle
          options={[
            { value: "all", label: "All" },
            { value: "FlexEmployee", label: "flex" },
            { value: "Employee", label: "fixed" },
          ]}
          labelStyle="label-style font-weight-bold"
          optionItemStyle="mr-3"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
          skipLocalization
        />

        <div className="check-box-container-in-filters mx-2">
          <FilterCheckBox
            label={t("Only show employees I manage")}
            formName={dataTableRef}
            name="showManagedEmployeesFilter"
            value={batchFilter?.showManagedEmployeesFilter}
          />

          <FilterCheckBox
            label={t("Only show employees I manage directly")}
            formName={dataTableRef}
            name="directManaged"
            value={batchFilter?.directManaged}
          />

          <FilterCheckBox
            label={t("Only show employees on probation")}
            formName={dataTableRef}
            name="on_probation"
            value={batchFilter?.on_probation}
          />
        </div>
      </FiltersDrawer>
    </div>
  );
};

export default ViewEmployeeActions;

import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Loader";
import {
  GET_CLOCKIN_AND_LOCATIONS,
  GET_MY_TIME_SHEET,
  GET_TIME_SHEET_LIST,
} from "../../../Graphql/query";
import MyWorkDayTemplatesContainer from "./MyWorkDayTemplatesContainer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import generateConfig from "rc-picker/lib/generate/moment";
import DateRangeIcon from "@mui/icons-material/DateRange";
import en from "rc-picker/lib/locale/en_US";
import ar from "rc-picker/lib/locale/ar_EG";
import Picker from "rc-picker";
import { useDispatch, useSelector } from "react-redux";
import { TimeSheetUpsertModal } from "../../../Components/FlexibleModals";
import { onFormResetAction } from "../../../Store/Actions";
import { serializeFetchedClockin } from "../../../Helpers/HelperFns";
import EditClockinsContainerModal from "../../../Components/FlexibleModals/EditClockinsContainerModal";

const formName = "timeSheetUpsertModal";

const MyTimeSheet = ({ isMyProfile = true, ...props }) => {
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState({
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    return () => {
      setClockinsContainerId(null);
    };
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clockinsContainerId, setClockinsContainerId] = useState(null);
  const [clockInId, setClockInId] = useState(null);
  const [containerStatus, setContainerStatus] = useState(null);

  const isEditContainerModalOpen = useSelector(
    (state) => state?.flex?.editClockinsContainerModal?.isOpen
  );

  const { loading, refetch } = useQuery(GET_MY_TIME_SHEET, {
    variables: {
      input: {
        date_range: dateRange,
      },
    },
    onCompleted: (res) => {
      setData(res?.mytimeSheet?.data);
    },
    notifyOnNetworkStatusChange: true,
    skip: !isMyProfile,
  });

  const { loading: employeeLoading, refetch: refetchManagerList } = useQuery(
    GET_TIME_SHEET_LIST,
    {
      variables: {
        input: {
          date_range: dateRange,
          emp_id: props.id,
        },
      },
      onCompleted: (res) => {
        setData(res?.timeSheet?.data);
      },
      notifyOnNetworkStatusChange: true,
      skip: isMyProfile,
    }
  );

  const [fetchClockinAndLocations, { data: clockInAndLocationsQueryData }] =
    useLazyQuery(GET_CLOCKIN_AND_LOCATIONS);

  const handleOpenClockinModal = (
    containerId = null,
    clockInId = null,
    containerStatus = null
  ) => {
    fetchClockinAndLocations({
      variables: {
        clockInId: clockInId,
        includeClockin: !!clockInId,
      },
      onCompleted: (data) => {
        setClockinsContainerId(containerId);
        setContainerStatus(containerStatus);
        setClockInId(clockInId);
        setIsModalOpen(true);
        if (!!data?.clockin) {
          dispatch(
            onFormResetAction(formName, serializeFetchedClockin(data?.clockin))
          );
        }
      },
    });
  };

  const handelDateChange = (name, value) => {
    setDateRange((prev) => ({
      ...prev,
      [name]: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
    }));
  };

  const handleCloseModal = () => {
    setClockinsContainerId(null);
    setClockInId(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className="d-flex gap-5 w-100 align-items-center justify-content-end"
        style={{ paddingBottom: 10, gap: 15 }}
      >
        <div className={"d-flex align-items-center gap-5"}>
          <label className={"label-style-default validity-label-style"}>
            {t("from")}
          </label>
          <Picker
            name={"from"}
            allowClear={false}
            className={`date-picker-input-default validity-input-style `}
            dropdownClassName="def-date-picker-dropdown"
            onChange={(e, h) => handelDateChange("from", h)}
            use12Hours
            showSecond={false}
            locale={
              document.documentElement.lang?.includes("ar")
                ? { ...ar, locale: "ar-custome" }
                : en
            }
            value={dateRange.from ? moment(dateRange.from, "YYYY-MM-DD") : null}
            suffixIcon={<DateRangeIcon />}
            placeholder={t("select date")}
            generateConfig={{ ...generateConfig }}
            format={"DD/MM/YYYY"}
            disabled={loading || employeeLoading}
          />
        </div>
        <div className={"d-flex align-items-center gap-5"}>
          <label className={"label-style-default validity-label-style"}>
            {t("to")}
          </label>
          <Picker
            name={"to"}
            allowClear={false}
            className={`date-picker-input-default validity-input-style`}
            dropdownClassName="def-date-picker-dropdown"
            onChange={(e, h) => handelDateChange("to", h)}
            use12Hours
            showSecond={false}
            locale={
              document.documentElement.lang?.includes("ar")
                ? { ...ar, locale: "ar-custome" }
                : en
            }
            value={dateRange.to ? moment(dateRange.to, "YYYY-MM-DD") : null}
            suffixIcon={<DateRangeIcon />}
            placeholder={t("select date")}
            generateConfig={{ ...generateConfig }}
            format={"DD/MM/YYYY"}
          />
        </div>
      </div>
      {loading || employeeLoading ? <Loader /> : null}
      {data?.map((day) => (
        <div className="mb-3 border rounded">
          <MyWorkDayTemplatesContainer
            key={Math.floor(Math.random())}
            day={day}
            handleOpenClockinModal={handleOpenClockinModal}
            refetchTimeSheet={isMyProfile ? refetch : refetchManagerList}
            isMyProfile={isMyProfile}
            {...props}
          />
        </div>
      ))}

      {isModalOpen ? (
        <TimeSheetUpsertModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          clockinsContainerId={clockinsContainerId}
          containerStatus={containerStatus}
          clockInId={clockInId}
          workPlaces={clockInAndLocationsQueryData?.locations_menu?.data ?? []}
          workdayTemplates={
            clockInAndLocationsQueryData?.FlexWorkDayTemplate?.data ?? []
          }
          refetch={refetchManagerList}
        />
      ) : null}

      {isEditContainerModalOpen ? (
        <EditClockinsContainerModal
          refetch={isMyProfile ? refetch : refetchManagerList}
        />
      ) : null}
    </>
  );
};

export default MyTimeSheet;

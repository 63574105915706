import gql from "graphql-tag";

export const GET_ASSET_TYPES = gql`
  query getAssetTypes($page: Int, $input: AssetTypesInput) {
    assetTypes(first: 20, page: $page, input: $input) {
      data {
        id
        name
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_ASSET_TYPES_MENU = gql`
  query getAssetTypesMenu {
    assetTypes: assetTypesMenu(first: 9999, page: 1) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_SCRAPPED_ASSETS = gql`
  query getScrappedAssets($page: Int, $input: ScrappedItemsInput) {
    scrappedItems(first: 20, page: $page, input: $input) {
      data {
        id
        asset {
          name
          model
          assetType {
            name
          }
        }
        serial_number
        note
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_WAREHOUSES = gql`
  query getWarehouses($page: Int, $input: CompanyWarehousesInput) {
    companyWarehouses(first: 20, page: $page, input: $input) {
      data {
        id
        name
        address
        items_count
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

// handle fetch company assets query
export const fetchCompanyAssetsQuery = gql`
  query fetchCompanyAssets(
    $page: Int
    $first: Int!
    $input: CompanyAssetsInput
  ) {
    companyAssets(page: $page, first: $first, input: $input) {
      data {
        id
        name
        brand
        model
        type_id: asset_type_id
        assetType {
          id
          name
        }
        company_id
        description
        product_type
        in_storage_items_count
        assigned_items_count
        scrapped_items_count
        items {
          id
          serial_number
          assignOrder {
            employee {
              ... on Employee {
                user {
                  id
                  name
                }
              }
              ... on FlexEmployee {
                user {
                  id
                  name
                }
              }
            }
            items {
              id
            }
          }
          warehouse {
            id
            name
            items_count
          }
          status
          note
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
    assetTypes: assetTypesMenu(first: 99999, input: {}) {
      data {
        name
        id
      }
    }
    companyWarehouses: companyWarehousesMenu(first: 99999, input: {}) {
      data {
        name
        id
      }
    }
  }
`;

// handle fetch assets info query
export const fetchAssetInfoQuery = gql`
  query fetchAssetInfo(
    $assetId: ID
    $warehouseId: ID!
    $firstFetch: Boolean!
    $employeeId: ID
    $fetchAssignedItems: Boolean!
  ) {
    company_users: users_by_role(input: { status: ["Active"] }) {
      data {
        id
        name
        emp_code
      }
    }
    fetchAsset(id: $assetId) @skip(if: $firstFetch) {
      name
      id
      product_type
      warehouses {
        id
        name
      }
      items_according_to_warhouse(warehouseId: $warehouseId)
        @skip(if: $firstFetch) {
        id
        asset_id
        serial_number
        status
        warehouse_id
      }
    }
    companyAssets(first: 99999, input: { filter: {itemsScope: "in_storage"} }) {
      data {
        name
        id
      }
    }
    companyWarehouses: companyWarehousesMenu(first: 9999, input: {}) {
      data {
        id
        name
      }
    }
    employeeAssignItems(first: 999, employeeId: $employeeId)
      @include(if: $fetchAssignedItems) {
      data {
        id
        serial_number
        asset {
          name
        }
      }
    }
  }
`;

// handle fetch asset items query
export const fetchAssetItemsQuery = gql`
  query fetchAssetItems($assetId: ID) {
    fetchAsset(id: $assetId) {
      id
      name
      brand
      model
      type_id: asset_type_id
      assetType {
        id
        name
      }
      company_id
      description
      product_type
      in_storage_items_count
      assigned_items_count
      scrapped_items_count
      items {
        id
        serial_number
        assignOrder {
          employee {
            ... on Employee {
              user {
                id
                name
              }
            }
            ... on FlexEmployee {
              user {
                id
                name
              }
            }
          }
          items {
            id
            serial_number
          }
        }
        warehouse {
          id
          name
          items {
            id
          }
        }
        status
        note
      }
    }
  }
`;

export const GET_WAREHOUSES_MENU = gql`
  query getWarehousesMenu {
    companyWarehouses: companyWarehousesMenu(first: 9999, page: 1) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_WAREHOUSE_DETAILS = gql`
  query getWarehouseDetails($id: ID) {
    fetchWarehouse(id: $id) {
      id
      name
      address
      company_id
      items {
        id
        serial_number
        asset {
          id
          name
          model
          assetType {
            name
          }
          product_type
        }
      }
      items_count
      assets_count
    }
  }
`;

export const GET_EMPLOYEE_ASSIGNED_ASSETS = gql`
  query getEmployeeAssignedAssets($page: Int, $employeeId: ID) {
    employeeAssignItems(first: 20, page: $page, employeeId: $employeeId) {
      data {
        id
        serial_number
        note
        asset {
          name
          brand
          model
          assetType {
            name
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_MY_ASSIGNED_ASSETS = gql`
  query getMyAssignedAssets($page: Int) {
    viewMyAssignItems(first: 20, page: $page) {
      data {
        id
        serial_number
        note
        asset {
          name
          brand
          model
          assetType {
            name
          }
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_EMPLOYEE_ASSETS_REQUESTS = gql`
  query getEmployeeAssetsRequests($page: Int, $employeeId: ID!) {
    employeeAssetRequests(first: 20, page: $page, employee_id: $employeeId) {
      data {
        id
        description
        status
        assetType {
          id
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_MY_ASSET_REQUESTS = gql`
  query getMyAssetRequests($page: Int) {
    viewMyAssetRequests(first: 20, page: $page) {
      data {
        id
        description
        status
        assetType {
          id
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_ASSETS_MENU = gql`
  query getAssetsMenu {
    companyAssets(first: 9999) {
      data {
        id
        assetType {
          name
        }
      }
    }
  }
`;

export const GET_ASSET_ITEMS_ACCORDING_TO_WAREHOUSE = gql`
  query getAssetItemsAccordingToWarehouse($assetId: ID, $warehouseId: ID!) {
    fetchAsset(id: $assetId) {
      items_according_to_warhouse(warehouseId: $warehouseId) {
        id
        serial_number
      }
    }
  }
`;

export const GET_COMPANY_ASSETS_REQUESTS = gql`
  query getCompanyAssetsRequests(
    $page: Int
    $input: companyAssetRequestsInput
  ) {
    companyAssetRequests(first: 20, page: $page, input: $input) {
      data {
        id
        description
        status
        employee {
          ... on Employee {
            user {
              name
            }
          }
          ... on FlexEmployee {
            user {
              name
            }
          }
        }
        assetType {
          id
          name
        }
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
`;

export const GET_EXCHANGE_EMPLOYEES_MENU = gql`
  query getExchangeEmployeesMenu {
    users_by_role(input: { status: ["Active"] }) {
      data {
        id
        name
        emp_code
      }
    }
  }
`;

export const GET_ACCEPT_ASSET_REQUEST_DATA = gql`
  query getAcceptAssetRequestData($input: CompanyAssetsInput) {
    companyAssets(first: 9999, page: 1, input: $input) {
      data {
        id
        name
        brand
        model
        product_type
      }
    }
  }
`;

export const GET_ASSET_WAREHOUSES = gql`
  query getAssetsWarehouses($assetId: ID) {
    fetchAsset(id: $assetId) {
      warehouses {
        id
        name
      }
    }
  }
`;

import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  showToast,
  openSwalConfirm,
  normalizeFetchedSalaryConfigInput,
  normalizeFetchedTimeRangeProfileInput,
} from "../../Helpers/HelperFns";
import _ from "lodash";
import Store from "../../Store";
import Privileges from "../../Constants/Privilages";
import { salaryConfigurationPresetsQuery } from "../../Graphql/query";
import { initSalaryConfig, onFormResetAction } from "../../Store/Actions";
import { removeSalaryConfigurationPresetsMutation } from "../../Graphql/mutation";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { RadioboxForm } from "form-builder";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import { AddButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { UpsertSalaryConfigPresetsModal } from "../../Components/SettingsModals";

const modalInitState = { isOpen: false, data: null };

const SalaryConfigPresetsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [filters, setFilters] = React.useState({ type: "both" });
  const [modalState, setModalState] = React.useState(modalInitState);

  // Reducer State
  const { office, offices } = useSelector((state) => state?.auth?.userProfile);

  // Server State
  const {
    data,
    loading: listLoading,
    refetch: refetchList,
  } = useQuery(salaryConfigurationPresetsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        type: filters?.type,
      },
    },
  });
  const [removePreset, { loading: removeLoading }] = useMutation(
    removeSalaryConfigurationPresetsMutation
  );

  // Constants
  const columns = [
    { name: t("name"), selector: "name" },
    { name: t("currency"), selector: (row) => row?.details?.currency.name },
    { name: t("payment"), selector: "payment" },
    {
      cell: (row) => (
        <HasPrivileges
          allowBP
          reqireMain={[Privileges.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
        >
          <EditIconButton onClick={() => handleEdit(row)} />
          <RemoveIconButton onClick={() => handleRemove(row?.id)} />
        </HasPrivileges>
      ),
    },
  ];

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleRadioChange = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleEdit = (preset) => {
    handleOpenModal({ isEdit: true, id: preset?.id });
    dispatch(
      onFormResetAction("salaryConfigForm", {
        ..._.cloneDeep({
          ...normalizeFetchedSalaryConfigInput({
            id: preset?.id,
            from: null,
            type: "Internal",
            payment: preset?.payment,
            details: preset?.details ?? {},
          }),
        }),
        name: preset?.name,
      })
    );
    dispatch(
      onFormResetAction("timeRangeProfileSetting", {
        ..._.cloneDeep({
          ...normalizeFetchedTimeRangeProfileInput({
            ...preset?.fixedSettings,
          }),
        }),
      })
    );
  };

  const handleRemove = (id) => {
    openSwalConfirm((isConfirmed) => {
      isConfirmed &&
        removePreset({
          variables: { id },
          onCompleted: () => {
            refetchList();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ||
                err?.graphQLErrors[0]?.message ||
                err?.message
            );
          },
        });
    });
  };

  const handleOpenModal = (data) => {
    const headQuarter = offices?.find((off) => off?.head_quarter);
    const country_id = office?.country?.id || headQuarter?.country?.id;
    setModalState({
      isOpen: true,
      data: { isEdit: false, countryId: country_id, ...data },
    });

    const salaryConfigForm = Store.getState(
      (state) => state?.super?.salaryConfigForm
    );
    dispatch(
      initSalaryConfig(headQuarter?.id, true, salaryConfigForm, country_id)
    );
  };

  const handleCloseModal = () => {
    setModalState(modalInitState);
  };

  return (
    <>
      {removeLoading ? <Loader fixed /> : null}

      {/* Filters & Actions */}
      <div className="d-flex justify-content-between mt-3">
        <RadioboxForm
          options={[
            { label: "all", value: "both" },
            { label: "fixed", value: "fixed" },
            { label: "flexible", value: "flex" },
          ]}
          name="type"
          containerStyle="my-2 my-lg-0"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle flex-nowrap"
          optionItemStyle="optionItemStyle payroll_option_items"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
          interceptChange={handleRadioChange}
          value={filters?.type}
        />
        <HasPrivileges
          allowBP
          reqireMain={[Privileges.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
        >
          <AddButton onClick={handleOpenModal} />
        </HasPrivileges>
      </div>

      {/* List */}
      <DataTable
        noHeader
        columns={columns}
        data={data?.presets || []}
        className="cards_table mt-4"
        progressPending={listLoading}
        progressComponent={<Loader inner />}
      />

      {/* Modals */}
      {modalState?.isOpen ? (
        <UpsertSalaryConfigPresetsModal
          data={modalState?.data}
          refetchList={refetchList}
          onClose={handleCloseModal}
        />
      ) : null}
    </>
  );
};

export default SalaryConfigPresetsList;

export const Config = {
  // //Production Env
  // API_URL: "http://mawared.pro",

  // Testing Env
  // API_URL: "https://mawared-testing.arabiclocalizer.info",
  // Client_Secret:"HEKC4vcjsUCHKm2eZlCL0tPvL6QovpYQF8llinq7",
  // Client_Id:4,
  //Local Env
  // API_URL: "http://mawared.local",
  // Client_Secret: "pFk1D9sfYawCJJuZEsvvEzDO2WhU7GfPnDZUmG18",
  // Client_Id: 4,
  googleMapKey: import.meta.env.REACT_APP_GOOGLE_MAP_API_URL,
  // API_URL: "https://back-testing5.mawared-hr.com",
  API_URL: import.meta.env.REACT_APP_API_URL,
  DEMO_URL: import.meta.env.REACT_APP_DEMO_URL,
  ASSIGNMENTS_COMPANIES_IDS: ["1", "12"],
};

import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  onFormResetAction,
  onInputResetAction,
  showErrorToast,
  toggleEditClockinsContainer,
} from "../../Store/Actions";

import MainModal from "../MainModal";
import { RadioboxForm, BSelect } from "form-builder";
import {
  GET_EDIT_CONTAINER_INPUTS_DATA,
  GET_FLEX_WORK_DAY_TEMPLATES_MENU,
} from "../../Graphql/query";
import { EDIT_CLOCKINS_CONTAINER } from "../../Graphql/mutation";
import {
  Compensation,
  Requests,
  WorkOnDaysOff,
  WorkPlaces,
} from "../SharedFormSections/DayOffException";
import WorkOnHolidays from "../SharedFormSections/WorkOnHolidays";
import {
  serializeUpsertDayOffException,
  serializeUpsertHolidayException,
} from "../../Helpers/HelperFns";

const reducer = "flex";
const formName = "editClcokinsContainerForm";
const formNameValidation = "editClockinContainerFormClientValidation";
const formServerValidation = "editClockinContainerFormServerValidation";

const serializeupsert = (formData) => {
  const { allow_work_on_day_off, ...data } = formData ?? {};

  return {
    clockin_container_id: data?.containerId,
    status: data?.status,
    ...(data?.status == "default"
      ? {
          work_day_template_id:
            data?.originalStatus == "default"
              ? data?.workdayTemplateId
              : data?.originalStatus == "dayOff"
                ? data?.normal_work_timing_id
                : data?.normal_work_timing_id,
          ...(data?.originalStatus == "dayOff"
            ? {
                assignment_details_input: {
                  ...serializeUpsertDayOffException({
                    ...data,
                    allow_work_on_day_off: true,
                  })?.day_off_exception_config,
                  cost_center_id: null,
                },
              }
            : data?.originalStatus == "holiday"
              ? {
                  assignment_details_input: {
                    ...serializeUpsertHolidayException({
                      ...data,
                      allow_work_on_holiday: true,
                    })?.holiday_exception_config,
                    cost_center_id: null,
                  },
                }
              : {}),
        }
      : {}),
  };
};

const EditClockinsContainerModal = ({ refetch = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isOpen = useSelector(
    (state) => state?.[reducer]?.editClockinsContainerModal?.isOpen
  );

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);

  console.log("formData", formData);
  const clientValidations = useSelector(
    (state) => state?.[reducer]?.editClockinContainerFormClientValidation
  );

  const FormProps = {
    reducer,
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  // Server State

  const [submit, { loading }] = useMutation(EDIT_CLOCKINS_CONTAINER, {
    onCompleted: (data) => {
      if (data?.editClockInContainer?.__typename == "GeneralException") {
        dispatch(
          showErrorToast(
            data?.editClockInContainer?.message ?? "Something went wrong"
          )
        );
      } else {
        refetch();
        handleCloseModal();
      }
    },
    onError: (error) => {
      dispatch(
        showErrorToast(
          error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
        )
      );
    },
  });

  const { data: inputsData, loading: inputsLoading } = useQuery(
    GET_EDIT_CONTAINER_INPUTS_DATA
  );

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    dispatch(
      toggleEditClockinsContainer({
        modal: {
          isOpen: false,
        },
        form: {
          containerId: null,
          status: "default",
          workdayTemplateId: null,
        },
      })
    );
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!!!clientValidations?.length) {
      submit({
        variables: {
          input: serializeupsert(formData),
        },
      });
    }
  };

  return (
    <MainModal
      isOpen={isOpen}
      modalTitle={"edit container"}
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
      disableSubmitButton={loading}
    >
      <RadioboxForm
        {...FormProps}
        name="status"
        label="status"
        labelStyle="mb-2"
        options={[
          {
            label: "default",
            value: "default",
          },
          {
            label: "day off",
            value: "dayOff",
          },
          {
            label: "holiday",
            value: "holiday",
          },
        ]}
        optionInputStyle=" "
        optionItemStyle="px-0"
        optionsContainerStyle="d-flex gap-10"
      />

      {formData?.status == "default" ? (
        formData?.originalStatus == "default" ? (
          <BSelect
            {...FormProps}
            name="workdayTemplateId"
            validateBy="textRequired"
            validationName="input.work_day_template_id"
            placeholder={t("select work day template")}
            options={inputsData?.FlexWorkDayTemplate?.data}
            label="work day template"
            labelStyle="mt-3 mb-2"
            containerStyle="d-flex align-items-start flex-column"
            inputContainerStyle="w-100"
            icon="calendar"
            rootStyle="flex-1"
            skipLocalization
          />
        ) : formData?.originalStatus == "dayOff" ? (
          <>
            <WorkOnDaysOff
              formProps={FormProps}
              isFlex
              flexWorkDayTemplateOptions={
                inputsData?.FlexWorkDayTemplate?.data ?? []
              }
              isEditContainer
            />

            {formData?.allow_work_on_day_off ? (
              <>
                <div>
                  {/* Work Places */}
                  <WorkPlaces
                    formProps={FormProps}
                    isFlex
                    locationsOptions={inputsData?.locations_menu?.data}
                  />

                  {!formData?.treat_as_normal ? (
                    <>
                      {/* Compensation */}
                      <Compensation
                        formProps={FormProps}
                        compensation_type={formData?.compensation_type}
                        apply_compensation={formData?.apply_compensation}
                        isFlex
                      />

                      {/* Requests */}
                      <Requests formProps={FormProps} />
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ) : (
          <WorkOnHolidays
            formProps={FormProps}
            isFlex
            flexWorkDayTemplateOptions={
              inputsData?.FlexWorkDayTemplate?.data ?? []
            }
          />
        )
      ) : null}
    </MainModal>
  );
};

export default EditClockinsContainerModal;

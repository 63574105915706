import React, { useState } from "react";
import ClockinCard from "./ClockinCard";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { AddButton } from "../../Components/Buttons";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { toggleEditClockinsContainer } from "../../Store/Actions";
import { normalizedFetchedContainerForEditing } from "../../Helpers/HelperFns";
import { useDispatch, useSelector } from "react-redux";
import { EditIconButton } from "../../Components/IconButtonWithTooltip";
import EditClockinsContainerModal from "../../Components/FlexibleModals/EditClockinsContainerModal";

const ClockinsContainer = ({
  id,
  handleOpenClockinModal,
  container,
  refetchTimeSheet,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isClockinsOpened, setIsClockinsOpened] = useState(true);

  const handleToggleClockins = () => {
    setIsClockinsOpened((prev) => !prev);
  };

  const handleAddOrEditClockin = (clockInId = null) => {
    handleOpenClockinModal(id, clockInId, container?.status);
  };

  const workdayTemplateName = container?.flexWorkDayTemplate?.name;
  const penalties = container?.flexPenalties;
  const clockins = container?.clockins;
  const totalClockIns =
    container?.calculatedExtraDataPerContainer?.totalClockIns;
  const totalHours = container?.calculatedExtraDataPerContainer?.totalHours;

  const actualTotalHours =
    container?.calculatedExtraDataPerContainer?.actualTotalHours;
  const totalOverTime =
    container?.calculatedExtraDataPerContainer?.totalOverTime;
  const actualTotalOverTime =
    container?.calculatedExtraDataPerContainer?.actualTotalOverTime;
  const totalLeave = container?.calculatedExtraDataPerContainer?.totalLeave;

  const isContainerDayoffOrHoliday =
    container?.status?.toLowerCase() == "day off" ||
    container?.status?.toLowerCase() == "holiday";

  const handleEditContainer = (container) => {
    dispatch(
      toggleEditClockinsContainer({
        modal: {
          isOpen: true,
        },
        form: normalizedFetchedContainerForEditing(container),
      })
    );
  };

  return (
    <div>
      <div onClick={handleToggleClockins} className="clockins-container">
        {isContainerDayoffOrHoliday ? (
          <>
            {/* <p className="clockins-container-date">
              <div className="new-entry-button">
                <AddButton
                  label="new entry"
                  className="text-nowrap"
                  onClick={() => handleAddOrEditClockin(null)}
                />
              </div>
            </p> */}

            <p className="font-weight-bold clockins-container-total-sign-ins ml-auto">
              {t(container?.status)}
            </p>
          </>
        ) : (
          <>
            <div className="clockins-container-workday-template-name">
              {workdayTemplateName}
              {!!penalties?.length ? (
                <Tooltip arrow placement="top" title={t("penalties")}>
                  <InfoOutlined fontSize="small" color="inherit" />
                </Tooltip>
              ) : null}
            </div>
            <p className="clockins-container-date">
              <div className="new-entry-button">
                <AddButton
                  label="new entry"
                  onClick={() => handleAddOrEditClockin(null)}
                />
              </div>
            </p>

            <div className="clockins-container-total-sign-ins d-flex align-items-center">
              <p className="mr-2">total sign-ins</p>
              <p>{totalClockIns}</p>
            </div>

            <div className="clockins-container-total-hours d-flex align-items-center">
              <p className="mr-2">total hours</p>
              <p>
                {totalHours}({actualTotalHours})
              </p>
            </div>

            <div className="clockins-container-overtime d-flex align-items-center">
              <p className="mr-2">overtime</p>
              <p>
                {totalOverTime}({actualTotalOverTime})
              </p>
            </div>

            <div className="clockins-container-leave d-flex align-items-center">
              <p className="mr-2">leave</p>
              <p>{totalLeave}</p>
            </div>
          </>
        )}
        <EditIconButton
          className="clockins-container-edit"
          onClick={() => handleEditContainer(container)}
        />
      </div>

      {isClockinsOpened
        ? clockins?.map((clockIn, index) => (
            <ClockinCard
              key={clockIn?.id}
              sign_in_order={t("sign-in-index", {
                index: index + 1,
                suffix:
                  index + 1 == 1
                    ? "st"
                    : index + 1 == 2
                      ? "nd"
                      : index + 1 == 3
                        ? "rd"
                        : "th",
              })}
              clockIn={clockIn}
              handleAddOrEditClockin={handleAddOrEditClockin}
              hasPenalties={!!penalties?.length}
              refetchTimeSheet={refetchTimeSheet}
            />
          ))
        : null}
    </div>
  );
};

export default ClockinsContainer;

import React, { useEffect } from "react";
import { RadioboxForm } from "form-builder";
import MainModal from "../../Components/MainModal";
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { managersQuery } from "../../Graphql/query";
import { useTranslation } from "react-i18next";
import { addManagersMutation } from "../../Graphql/mutation";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import swal from "sweetalert";
import Select from "react-select";
import { Alert, AlertTitle } from "@mui/material";

const initState = {
  manager_id: null,
  copied_managers_ids: [],
  replace_managers: false,
};

const ChangeManagersModal = (props) => {
  const { t } = useTranslation();
  const [input, setInput] = useState(initState);

  //  get managers query
  const { loading, data: managersData } = useQuery(managersQuery, {
    fetchPolicy: "network-only",
  });

  // add Managers Mutation
  const [
    addManagersToEmployees,
    { loading: addManagersLoading, addManagersError },
  ] = useMutation(addManagersMutation, {
    variables: {
      employees_ids: props.selectedEmployees,
    },
    onError: (err) => {
      console.log({ err });
      if (err?.graphQLErrors[0]?.extensions?.validation) {
        let validation = err?.graphQLErrors[0]?.extensions?.validation;
        setValid((prev) => ({
          isValid: false,
          msg: Object.values(validation).toString(),
        }));
      } else
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason || err.message
        );
    },
    onCompleted: (res) => {
      if (
        res?.addDirectManagerToEmployees?.status === "success" ||
        res?.addCopiedManagersToEmployees?.status === "success"
      ) {
        props.resetSelection();
        props.refetch();
        props.CloseManagersModal();
        setValid({
          isValid: true,
          msg: "",
        });
      }
      if (props.managersModal.isDirectManager) {
        showToast(
          res?.addDirectManagerToEmployees?.status,
          res?.addDirectManagerToEmployees.message
        );
      } else {
        showToast(
          res?.addCopiedManagersToEmployees?.status,
          res?.addCopiedManagersToEmployees.message
        );
      }
    },
  });

  // validate direct manager
  const [valid, setValid] = useState({
    isValid: true,
    msg: "",
  });
  const validate = () => {
    if (props.managersModal.isDirectManager) {
      setValid((prev) => ({
        msg: "Employees must have a Direct Manager",
        isValid: Boolean(input.manager_id),
      }));
      return Boolean(input.manager_id);
    }
    let isValid = !(input.copied_managers_ids?.length === 0);
    setValid((prev) => ({ msg: "Select at least one manager", isValid }));
    return isValid;
  };

  useEffect(() => {
    return () => {
      setInput(initState);
    };
  }, [props.managersModal.isOpen]);

  const handleSave = () => {
    if (!validate()) return;
    HelperFns.checkPassword(
      "defaut_warning_messsage",
      "",
      "Confirm, Edit the employee manager",
      "",
      () => submit()
    );
  };

  const submit = () => {
    addManagersToEmployees({
      variables: {
        isDirectManager: props.managersModal.isDirectManager,
        copied_managers_ids: input.copied_managers_ids,
        replace_managers: input.replace_managers,
        manager_id: input.manager_id,
      },
    });
  };

  const handleCancle = () => {
    // props.resetSelection()
    props.CloseManagersModal();
  };

  const handleRadioChange = (e) => {
    setInput((prevState) => {
      return { ...prevState, replace_managers: !prevState.replace_managers };
    });
  };

  const handleSelect = (val) => {
    if (props?.managersModal.isDirectManager) {
      setInput((prev) => ({
        ...prev,
        manager_id: val ? val?.id : null,
      }));
    } else {
      setInput((prev) => ({
        ...prev,
        copied_managers_ids: val && val?.length ? val?.map((el) => el.id) : [],
      }));
    }
  };

  const selectValue = props?.managersModal.isDirectManager
    ? managersData?.company_users.data?.find(
        (opt) => opt?.id === input.manager_id
      ) || null
    : managersData?.company_users.data?.filter((opt) =>
        input.copied_managers_ids?.includes(opt?.id)
      ) || [];

  return (
    <MainModal
      isOpen={props.managersModal.isOpen}
      size="lg"
      modalTitle={t("Bulk Actions")}
      btnSubmitLoading={addManagersLoading}
      btnOnClick={handleSave}
      toggle={handleCancle}
      className="p-5 "
    >
      <div className="p-2">
        <Alert severity="info">
          <AlertTitle>
            {props.managersModal.isDirectManager
              ? t("changing the direct manager")
              : t("changing the copied managers")}
          </AlertTitle>
          <strong>
            {t("Employees will be effected", {
              count: props.selectedEmployees?.length,
            })}
          </strong>
        </Alert>
      </div>

      <div
        className={"p-2 " + (valid.isValid ? "" : "invalid-container-style")}
      >
        <label className={"select-def-label mb-2 validity-label-style"}>
          {props.managersModal.isDirectManager
            ? t("direct manager")
            : t("copied managers")}
        </label>
        <form action="">
          <Select
            name="some_name"
            className={
              "select-def-input-containe b-select-style flex-grow-1 px-0"
            }
            classNamePrefix={"b-select-style"}
            value={selectValue}
            isMulti={!props?.managersModal.isDirectManager}
            onChange={handleSelect}
            getOptionLabel={(opt) => opt?.name}
            getOptionValue={(opt) => opt?.id}
            isLoading={loading}
            isClearable={true}
            isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
            isSearchable
            placeholder={t("select Manager")}
            options={managersData?.company_users.data || []}
          />
        </form>
        <div className="validity-msg-style text-left">{t(valid.msg)}</div>
      </div>

      {props.managersModal.isDirectManager ? null : (
        <RadioboxForm
          value={input.replace_managers}
          labelStyle="mb-3"
          validateContainerStyle={""}
          optionInputStyle="text-black-100"
          containerStyle="mb-0"
          optionItemStyle="d-inline-block mx-2"
          optionsContainerStyle="d-flex flex-column  gap-10"
          options={[
            {
              label: t("Replace the assigned copied managers"),
              value: true,
              optProps: { onChange: handleRadioChange },
            },
            {
              label: t("Add the assigned copied managers"),
              value: false,
              optProps: { onChange: handleRadioChange },
            },
          ]}
        />
      )}
    </MainModal>
  );
};

export default ChangeManagersModal;

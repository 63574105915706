import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import HelperFns from "../Helpers/HelperFns";
import Privilages from "../Constants/Privilages";
import { noSignInOutTimeStatuses } from "../Constants/Requests";

import { ColorButton } from "./Buttons";
import { LocationOn } from "@mui/icons-material";
import { AccessTime } from "@mui/icons-material";

const RenderMultipleSignIns = ({
  emp = {},
  signIn,
  index,
  empList,
  signInTime,
  showLocations = true,
  showActiveSignInColor = true,
  openSignOnBehalfModal,
  isFlex,
}) => {
  const { t } = useTranslation();

  const canSignOnBehalf =
    window.location.pathname !== "/employee-profile" &&
    HelperFns.checkPrivileges({
      allowBP: true,
      privileges: [Privilages.SIGN_IN_OUT_ON_BEHALF_OF_EMPLOYEES],
    }) &&
    !(isFlex && false);
  const handleOpenSignOnBehalfModal = (isIn) => {
    openSignOnBehalfModal && openSignOnBehalfModal({ ...signIn, ...emp, isIn });
  };

  const checkIfSignInIsActive = (signIn) => {
    return signIn === signInTime && showActiveSignInColor;
  };

  if (signIn?.status === "absent" && !signIn?.sign_in_time) {
    return (
      <p className="emp-multiple-sign-ins-absent-status">{t(signIn?.status)}</p>
    );
  } else {
    return (
      <div className="mutliple-sign-in-container">
        <>
          <p
            className={
              signIn?.status?.toLowerCase() === "absent"
                ? "emp-multiple-sign-ins-absent-status"
                : checkIfSignInIsActive(signIn?.sign_in_time)
                  ? "sign-in-title sign-in-title-active"
                  : "sign-in-title"
            }
          >
            {t("emp-multi-sign-in-index", {
              index: index + 1,
              suffix:
                index === 0
                  ? "st"
                  : index === 1
                    ? "nd"
                    : index === 2
                      ? "rd"
                      : "th",
              order:
                index === 0
                  ? t("1st")
                  : index === 1
                    ? t("2nd")
                    : index === 2
                      ? t("3rd")
                      : null,
            })}
            {signIn?.status ? ` - ${t(signIn?.status?.toLowerCase())}` : null}
          </p>
          {!noSignInOutTimeStatuses.includes(signIn?.status?.toLowerCase()) ? (
            <>
              {empList && canSignOnBehalf && !signIn?.sign_in_time ? (
                <ColorButton
                  label="sign in"
                  color="#238f27"
                  variant="outlined"
                  sx={{ height: 27, mb: 1 }}
                  onClick={() => handleOpenSignOnBehalfModal(true)}
                />
              ) : (
                <>
                  <div className="d-flex align-items-center justify-content-between m-0">
                    <p
                      className={
                        checkIfSignInIsActive(signIn?.sign_in_time)
                          ? "multiple-sign-signs-text multiple-sign-signs-text-active"
                          : "multiple-sign-signs-text"
                      }
                    >
                      {signIn.sign_in_time
                        ? moment(signIn.sign_in_time).format("hh:mm:ss a")
                        : "--:--:--"}
                    </p>
                    <p
                      className={
                        checkIfSignInIsActive(signIn?.sign_in_time)
                          ? "multiple-sign-signs-text multiple-sign-signs-text-active"
                          : "multiple-sign-signs-text"
                      }
                    >
                      {signIn.sign_out_time ? (
                        moment(signIn.sign_out_time).format("hh:mm:ss a")
                      ) : empList && canSignOnBehalf ? (
                        <ColorButton
                          label="sign out"
                          color="#ff6a6a"
                          variant="outlined"
                          sx={{ height: 27 }}
                          onClick={() => handleOpenSignOnBehalfModal(false)}
                        />
                      ) : (
                        "--:--:--"
                      )}
                    </p>
                  </div>
                  {showLocations ? (
                    <div className="d-flex align-items-center justify-content-between m-0">
                      <p className="multiple-sign-signs-text">
                        <LocationOn className="sign-ins-tooltip-icons" />
                        {signIn.sign_in_location_name
                          ? signIn.sign_in_location_name
                          : "........"}
                      </p>
                      {canSignOnBehalf && !signIn?.sign_out_time ? null : (
                        <p className="multiple-sign-signs-text">
                          <LocationOn className="sign-ins-tooltip-icons" />
                          {signIn.sign_out_location_name
                            ? signIn.sign_out_location_name
                            : "........"}
                        </p>
                      )}
                    </div>
                  ) : null}
                </>
              )}

              <p>
                <AccessTime className="employee-card-work-timing-icon" />
                {signIn?.work_timing_name}
              </p>
            </>
          ) : null}
        </>
      </div>
    );
  }
};

export default RenderMultipleSignIns;
